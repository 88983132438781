import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import FadeLoader from 'react-spinners/FadeLoader';
import { useSelector } from 'react-redux';

import { authSelector } from '../../module/authSlice';

import Token from '../auth/Token';
import Header from '../common/Header';
import NavBar from '../common/NavBar';
import Footer from '../common/Footer';

const contentLoading = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <FadeLoader color={'#41a1ea'} loading={true} size={20} />
  </div>
);

const Manager = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const status = useSelector(authSelector.status);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === '/') {
      document.title = 'ELIGA';
    } else if (pathname.indexOf('/dashboard') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.dashboard');
    } else if (pathname.indexOf('/device') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.device');
    } else if (pathname.indexOf('/storage') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.storage');
    } else if (pathname.indexOf('/playlist') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.playlist');
    } else if (pathname.indexOf('/schedule') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.schedule');
    } else if (pathname.indexOf('/channel') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.channel');
    } else if (pathname.indexOf('/report') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.report');
    } else if (pathname.indexOf('/system/setting') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.setting');
    } else if (pathname.indexOf('/system/member') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.member');
    } else if (pathname.indexOf('/system/profile') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.profile');
    } else if (pathname.indexOf('/notice') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.notice');
    } else if (pathname.indexOf('/release') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.release');
    } else if (pathname.indexOf('/qna') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.qna');
    } else if (pathname.indexOf('/guide') === 0) {
      document.title = 'ELIGA - ' + t('manager.common.title.guide');
    } else {
      document.title = 'ELIGA';
    }
  }, [t, location]);

  const onMenuToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  if (!status.isLogin) {
    return <Token />;
  }

  return (
    <Container $isMobile={isMobile}>
      {isMenuOpen && <NavBar />}
      <Content $isMobile={isMobile} $isMenuOpen={isMenuOpen}>
        <Header onMenuToggle={onMenuToggle} />
        <Suspense fallback={contentLoading()}>
          <Outlet />
        </Suspense>
        <Footer />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
`;

const Content = styled.div`
  flex: 1;
  margin-top: ${props => (props.$isMobile ? '4rem' : '0')};
  margin-left: ${props => (props.$isMobile || !props.$isMenuOpen ? '0' : '12.5rem')};
  display: flex;
  flex-direction: column;
`;

export default Manager;
