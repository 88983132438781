import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { accountSelector, accountAction } from '../../module/accountSlice';
import { placeSelector, placeAction } from '../../module/placeSlice';

import { FaSpinner as LoadingIcon } from 'react-icons/fa';
import { FiChevronDown as DownArrowIcon } from 'react-icons/fi';
import { MdPlace as PlaceIcon } from 'react-icons/md';

import ClickOutside from '../../components/ClickOutside';
import useCheckEditingMode from '../../components/useCheckEditingMode';

const PlaceBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accountInfo = useSelector(accountSelector.accountInfo);
  const status = useSelector(accountSelector.status);
  const placeInfo = useSelector(placeSelector.placeInfo);
  const placeList = useSelector(placeSelector.placeList);

  const { editingCheck, LayerContentsWarnModal, isEditingChecking } = useCheckEditingMode();

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (!accountInfo.accountId) {
      return;
    }

    dispatch(
      placeAction.placeInfo({
        placeId: accountInfo.viewPlaceId || accountInfo.placeId,
      }),
    );
    dispatch(placeAction.placeList({ accountId: accountInfo.accountId }));
  }, [dispatch, accountInfo]);

  useEffect(() => {
    if (!accountInfo.viewPlaceId && !accountInfo.placeId) {
      return;
    }

    if (placeList.filter(place => place.placeId === accountInfo.viewPlaceId).length > 0) {
      dispatch(
        placeAction.placeInfo({
          placeId: accountInfo.viewPlaceId,
        }),
      );
    } else if (placeList.filter(place => place.placeId === accountInfo.placeId).length > 0) {
      dispatch(
        placeAction.placeInfo({
          placeId: accountInfo.placeId,
        }),
      );
    } else if (placeList.length > 0) {
      dispatch(
        placeAction.placeInfo({
          placeId: placeList[0].placeId,
        }),
      );
    }
  }, [dispatch, accountInfo, placeList]);

  useEffect(() => {
    if (status.actionResult === 'PLACE_CHANGE_OK') {
      dispatch(accountAction.actionResultClear());
      navigate('/dashboard');
    }
  }, [dispatch, status, navigate]);

  const onPlaceChange = useCallback(
    placeId => {
      dispatch(
        accountAction.placeChange({
          placeId: placeId,
          accountId: accountInfo.accountId,
        }),
      );
      setIsShow(false);
    },
    [dispatch, accountInfo],
  );

  if (status.isLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      <ClickOutside onClickOutside={() => !isEditingChecking && setIsShow(false)}>
        <Container>
          <SelectBtn onClick={() => setIsShow(!isShow)}>
            <SelectTitle>{placeInfo.placeNm}</SelectTitle>
            <DownArrowIcon />
          </SelectBtn>
          <OptionWrapper style={isShow ? { display: 'block' } : { display: 'none' }}>
            <OptionTitleWrapper>
              <OptionTitle>
                <PlaceIcon />
                {placeInfo.placeNm}
              </OptionTitle>
              <OptionTitleDesc>{t('manager.common.placeBox.selectNow')}</OptionTitleDesc>
            </OptionTitleWrapper>
            <OptionList>
              {placeList.map(place => (
                <OptionItem
                  key={place.placeId}
                  onClick={() =>
                    editingCheck(() => {
                      onPlaceChange(place.placeId);
                      setIsShow(false);
                    })
                  }
                >
                  <PlaceIcon />
                  {place.placeNm}
                </OptionItem>
              ))}
            </OptionList>
          </OptionWrapper>
        </Container>
      </ClickOutside>

      <LayerContentsWarnModal />
    </>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const SelectBtn = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  outline: 0;

  & > svg {
    margin-left: 0.375rem;
  }
`;

const SelectTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #666666;
`;

const OptionWrapper = styled.div`
  width: 16.875rem;
  position: absolute;
  display: flex;
  left: 0.625rem;
  top: 1.875rem;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.25);
  border-radius: 0.375rem;
`;

const OptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
`;

const OptionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #1875bc;
  line-height: 1.375rem;

  & > svg {
    margin-right: 0.375rem;
  }
`;

const OptionTitleDesc = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 300;
  color: #000000;
  line-height: 0.625rem;
  margin-top: 0.125rem;
`;

const OptionList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const OptionItem = styled.li`
  height: 2.8125rem;
  padding: 0.625rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.875rem;
  font-size: 1rem;
  font-weight: 400;
  color: #999999;
  line-height: 1.375rem;
  border-top: 0.0625rem solid rgba(60, 60, 67, 0.36);
  cursor: pointer;

  & > svg {
    margin-right: 0.375rem;
  }

  &:hover {
    background: #dddddd;
  }
`;

export default React.memo(PlaceBox);
