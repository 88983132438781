import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { getWorldWeather } from '../../../lib/playerApi';
import { previewToken } from '../../../lib/playlistApi';
import { roundOff } from '../../../utils/commonUtils';
import { calculateRatio } from '../../../utils/cssUtils';

import { ReactComponent as ClearSkyDay } from '../../../assets/images/preview/weather/clear-sky-day.svg';
import { ReactComponent as ClearSkyNight } from '../../../assets/images/preview/weather/clear-sky-night.svg';
import { ReactComponent as FewCloudsDay } from '../../../assets/images/preview/weather/few-clouds-day.svg';
import { ReactComponent as FewCloudsNight } from '../../../assets/images/preview/weather/few-clouds-night.svg';
import { ReactComponent as ScatteredCloudsDay } from '../../../assets/images/preview/weather/scattered-clouds-day.svg';
import { ReactComponent as ScatteredCloudsNight } from '../../../assets/images/preview/weather/scattered-clouds-night.svg';
import { ReactComponent as BrokenClouds } from '../../../assets/images/preview/weather/broken-clouds.svg';
import { ReactComponent as Rain } from '../../../assets/images/preview/weather/rain.svg';
import { ReactComponent as ShowerRain } from '../../../assets/images/preview/weather/shower-rain.svg';
import { ReactComponent as Thunderstorm } from '../../../assets/images/preview/weather/thunderstorm.svg';
import { ReactComponent as Snow } from '../../../assets/images/preview/weather/snow.svg';
import { ReactComponent as Mist } from '../../../assets/images/preview/weather/mist.svg';

const WorldWeather = ({ accountInfo, contentsData, layerContents, layerProps }) => {
  const [loading, setLoading] = useState(true);
  const [weather, setWeather] = useState({});
  const [ratio, setRatio] = useState(1);

  const { data, error } = useQuery(
    ['world-weather', contentsData.city],
    async () => {
      const deviceToken = await previewToken(
        accountInfo.accountId,
        accountInfo.placeId,
        'preview',
        layerContents.playlistId,
      );
      const weatherId = contentsData.city;
      const data = await getWorldWeather({
        deviceToken: deviceToken,
        weatherId: weatherId,
      });
      return data;
    },
    {
      refetchInterval: 60 * 60 * 1000,
    },
  );

  useEffect(() => {
    if (!data || !data.weatherData || !data.airData) {
      return;
    } else {
      setLoading(false);
    }

    if (data) {
      const weatherData = data.weatherData || data.weatherData;
      const airData = data.airData || data.airData;
      const newWeather = {
        status: weatherData.description,
        iconCode: weatherData.icon,
        temp: weatherData.temp_day,
        minTemp: weatherData.temp_min,
        maxTemp: weatherData.temp_max,
        pm10: airData.pm10,
        pm2_5: airData.pm2_5,
        o3: airData.o3 / 214, // 1 ppm O3 = 214 µg/m³
      };
      setWeather(newWeather);
    }
  }, [data]);

  useEffect(() => {
    setRatio(calculateRatio(183, 130, layerProps.width, layerProps.height, 'contain'));
  }, [contentsData, layerProps]);

  const getWeatherIcon = useCallback(iconCode => {
    switch (iconCode) {
      case '01d':
        return <ClearSkyDay />;
      case '01n':
        return <ClearSkyNight />;
      case '02d':
        return <FewCloudsDay />;
      case '02n':
        return <FewCloudsNight />;
      case '03d':
        return <ScatteredCloudsDay />;
      case '03n':
        return <ScatteredCloudsNight />;
      case '04d':
      case '04n':
        return <BrokenClouds />;
      case '09d':
      case '09n':
        return <ShowerRain />;
      case '10d':
      case '10n':
        return <Rain />;
      case '11d':
      case '11n':
        return <Thunderstorm />;
      case '13d':
      case '13n':
        return <Snow />;
      case '50d':
      case '50n':
        return <Mist />;
      default:
        return null;
    }
  }, []);

  const getAirConditionColor = useCallback((type, value) => {
    const criteria = {
      pm10: [30, 50, 100],
      pm2_5: [15, 25, 50],
      o3: [0.03, 0.09, 0.15],
    };
    if (value <= criteria[type][0]) return '#4FAAFF';
    else if (value <= criteria[type][1]) return '#4DC76F';
    else if (value <= criteria[type][2]) return 'orange';
    else return 'red';
  }, []);

  return (
    <>
      {loading ? (
        <WarningBox>날씨 데이터를 불러오는 중 입니다.</WarningBox>
      ) : error ? (
        <WarningBox>날씨 데이터를 가져오지 못하였습니다.</WarningBox>
      ) : (
        <WeatherBox
          width={183}
          height={130}
          ratio={ratio}
          fontColor={`rgba(${layerProps.fontColor?.r},${layerProps.fontColor?.g},${layerProps.fontColor?.b},${layerProps.fontColor?.a})`}
        >
          {contentsData.theme !== 'TEMP' && <IconBox>{getWeatherIcon(weather.iconCode)}</IconBox>}
          <TempBox>
            <p className="current-temp">
              {contentsData.unit === 'FS' ? `${roundOff(weather.temp * 1.8 + 32)}℉` : `${roundOff(weather.temp)}℃`}
            </p>
            <p className="min-max-temp">
              <span className="min-temp">
                {contentsData.unit === 'FS'
                  ? `${roundOff(weather.minTemp * 1.8 + 32)}℉`
                  : `${roundOff(weather.minTemp)}℃`}
              </span>
              <span className="max-temp">
                {contentsData.unit === 'FS'
                  ? `${roundOff(weather.maxTemp * 1.8 + 32)}℉`
                  : `${roundOff(weather.maxTemp)}℃`}
              </span>
            </p>
            {contentsData.theme === 'CLOUD_TEMP_AIR' && (
              <>
                <p className="current-air">
                  <span
                    style={{
                      color: getAirConditionColor('pm10', weather.pm10),
                    }}
                  >
                    {roundOff(weather.pm10)}㎍/㎥
                  </span>
                </p>
                <p className="current-air">
                  <span
                    style={{
                      color: getAirConditionColor('o3', weather.o3),
                    }}
                  >
                    {roundOff(weather.o3, 3)}ppm
                  </span>
                </p>
              </>
            )}
          </TempBox>
        </WeatherBox>
      )}
    </>
  );
};

const WarningBox = styled.div`
  width: auto;
  height: auto;
  margin: 25px;
  overflow: hidden;
  font-size: 20px;
  color: #333333;
  display: flex;
  align-items: center;
`;

const WeatherBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  transform-origin: top left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: ${props => `scale(${props.ratio})`} translate(-50%, -50%);
  transform-origin: top left;
  color: ${props => props.fontColor};
`;

const IconBox = styled.div`
  width: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
  }
  span {
    font-size: 10px;
    margin-bottom: 5px;
    letter-spacing: -1px;
  }
`;

const TempBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  gap: 5px;
  .current-temp {
    font-size: 50px;
    font-weight: 700;
  }
  .min-max-temp {
    font-size: 20px;
    .min-temp {
      color: #4faaff;
      &::after {
        content: '│';
      }
    }
    .max-temp {
      color: red;
    }
  }
  .current-air {
    font-size: 14px;
  }
`;

export default React.memo(WorldWeather);
