import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { endpoints as endpointContentsApi } from '../../../../rtk/contentsApi';
import { endpoints as endpointPlaceApi } from '../../../../rtk/placeApi';
import { editorSelector } from '../../../../module/editorSlice';

const StorageInfoBar = () => {
  const { t } = useTranslation();
  const placeId = useSelector(editorSelector.placeId);
  const { data: placeInfo } = endpointPlaceApi.placeDetail.useQueryState({ placeId });
  const { data: placeFileInfo } = endpointContentsApi.contentsFileDetail.useQuery({ placeId });

  const [storageInfo, setStorageInfo] = useState({
    totalFileSize: 0,
    maxStorageSize: 0,
    storageUnit: '',
  });

  useEffect(() => {
    if (placeFileInfo) {
      setStorageInfo(storageInfo => ({
        totalFileSize:
          placeInfo.storageMax / 1000 < 1
            ? Math.round((placeFileInfo.totalFileSize / (1024 * 1024)) * 10) / 10
            : Math.round((placeFileInfo.totalFileSize / (1024 * 1024 * 1024)) * 1000) / 1000,
        maxStorageSize: placeInfo.storageMax / 1000 < 1 ? placeInfo.storageMax : placeInfo.storageMax / 1000,
        storageUnit: placeInfo.storageMax / 1000 < 1 ? `MB` : `GB`,
      }));
    }
  }, [placeFileInfo, placeInfo.storageMax]);

  return (
    <Container>
      <StorageUsage>
        <StorageGauge
          value={storageInfo.maxStorageSize !== 0 && (storageInfo.totalFileSize / storageInfo.maxStorageSize) * 100}
          max="100"
        />
        <StorageText>
          {t('manager.editor.layerArea.component.storage.remain')}
          &nbsp;
          {Math.round((storageInfo.maxStorageSize - storageInfo.totalFileSize) * 1000) / 1000}
          {storageInfo.storageUnit}
        </StorageText>
        <StorageText>
          {storageInfo.totalFileSize}
          {storageInfo.storageUnit}
          &nbsp;/&nbsp;
          {storageInfo.maxStorageSize}
          {storageInfo.storageUnit}
        </StorageText>
      </StorageUsage>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StorageUsage = styled.div`
  display: flex;
`;

const StorageText = styled.div`
  display: flex;
  color: #666666;
  margin-left: 5px;
`;

const StorageGauge = styled.progress`
  height: 12px;
  width: 150px;
  padding-top: 6px;
  border: 0;
  display: flex;

  &::-webkit-progress-bar {
    background-color: #c4c4c4;
  }

  &::-webkit-progress-value {
    background-color: #666666 !important;
  }
`;

export default React.memo(StorageInfoBar);
