import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { editorSelector } from '../../module/editorSlice';
import { endpoints as endpointPlaceApi } from '../../rtk/placeApi';
import { endpoints as endpointPlaylistApi, playlistApi, usePlaylistUpdateMutation } from '../../rtk/playlistApi';

import { VscTriangleUp as TriangleIcon } from 'react-icons/vsc';
import { ReactComponent as DownIcon } from '../../assets/images/common/icon-chevron-down.svg';

import ClickOutside from '../../components/ClickOutside';
import SelectInput from '../../newComponents/SelectInput';
import PlaylistRemoveModal from './modal/PlaylistRemoveModal';

const Playlist = () => {
  const dispatch = useDispatch();

  const [updatePlaylist] = usePlaylistUpdateMutation();

  const playlistId = useSelector(editorSelector.playlistId);
  const placeId = useSelector(editorSelector.placeId);

  const { data: placeInfo } = endpointPlaceApi.placeDetail.useQueryState({ placeId });
  const { data: playlistInfo } = endpointPlaylistApi.playlistDetail.useQueryState({ playlistId });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPlaylistRemoveModalOpen, setIsPlaylistRemoveModalOpen] = useState(false);
  const [isPlaylistNmInputFocus, setIsPlaylistNmInputFocus] = useState(false);

  const handlePlaylistUpdate = useCallback(
    updateInfo => {
      const patchResult = dispatch(
        playlistApi.util.updateQueryData('playlistDetail', { playlistId }, draft => {
          for (const key in updateInfo) {
            draft[key] = updateInfo[key];
          }
        }),
      );
      updatePlaylist({ playlistId, updateInfo }).then(({ data }) => {
        const resultFlag = data.resultFlag;
        if (!resultFlag) {
          patchResult.undo();
        }
      });
    },
    [dispatch, updatePlaylist, playlistId],
  );

  return (
    <Wrap>
      {playlistInfo && (
        <>
          {isPlaylistNmInputFocus ? (
            <PlaylistNmInputBox
              playlistNm={playlistInfo.playlistNm}
              handlePlaylistUpdate={handlePlaylistUpdate}
              setIsPlaylistNmInputFocus={setIsPlaylistNmInputFocus}
            />
          ) : (
            <>
              <PlaceNm>{placeInfo?.placeNm} /&nbsp;</PlaceNm>
              <PlaylistNm onClick={() => setIsPlaylistNmInputFocus(true)}>{playlistInfo?.playlistNm}</PlaylistNm>
              <ClickOutside
                style={{ position: 'relative', height: '100%' }}
                onClickOutside={() => setIsMenuOpen(false)}
              >
                <Down onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <DownIcon width={13} />
                </Down>
                <Menu
                  playlistInfo={playlistInfo}
                  isMenuOpen={isMenuOpen}
                  handlePlaylistUpdate={handlePlaylistUpdate}
                  setIsPlaylistNmInputFocus={setIsPlaylistNmInputFocus}
                  setIsPlaylistRemoveModalOpen={setIsPlaylistRemoveModalOpen}
                />
              </ClickOutside>
            </>
          )}
          <PlaylistRemoveModal
            isOpen={isPlaylistRemoveModalOpen}
            closeModal={() => setIsPlaylistRemoveModalOpen(false)}
            playlistIdList={[playlistInfo?.playlistId]}
          />
        </>
      )}
    </Wrap>
  );
};

const PlaylistNmInputBox = ({ playlistNm, handlePlaylistUpdate, setIsPlaylistNmInputFocus }) => {
  const playlistNmInputRef = useRef(null);

  const [playlistNmValue, setPlaylistNmValue] = useState(playlistNm);

  useEffect(() => {
    playlistNmInputRef.current.focus();
  }, []);

  return (
    <PlaylistNmInput
      ref={playlistNmInputRef}
      value={playlistNmValue}
      onChange={e => setPlaylistNmValue(e.target.value)}
      onFocus={e => e.target.select()}
      onKeyUp={e => e.keyCode === 13 && e.target.blur()}
      onBlur={e => {
        if (e.target.value) {
          handlePlaylistUpdate({ playlistNm: e.target.value });
        }
        setIsPlaylistNmInputFocus(false);
      }}
    />
  );
};

const Menu = ({
  playlistInfo,
  isMenuOpen,
  handlePlaylistUpdate,
  setIsPlaylistRemoveModalOpen,
  setIsPlaylistNmInputFocus,
}) => {
  const { t } = useTranslation();

  const [resolutionValue, setResolutionValue] = useState({
    horizonResolution: playlistInfo.horizonResolution,
    verticalResolution: playlistInfo.verticalResolution,
  });

  const handleChangeResolution = useCallback(e => {
    const { name, value } = e.target;

    const _pattern1 = /[^0-9]/g;
    if (_pattern1.test(value) || value.substr(0, 1) === '0') {
      // 숫자가 아니거나 7680보다 높거나 맨앞숫자가 0일 경우
      return;
    }

    setResolutionValue(resolutionValue => ({
      ...resolutionValue,
      [name]: value,
    }));
  }, []);

  const handleBlurResolution = useCallback(
    e => {
      let { name, value } = e.target;

      const maxResolution = 7680;
      if (Number(value) > maxResolution) {
        // 최댓값보다 큰값 입력시 7680으로 자동변경
        value = '7680';

        setResolutionValue(resolutionValue => ({
          ...resolutionValue,
          [name]: value,
        }));
      }

      if (value === '' && name === 'horizonResolution') {
        value = '1920';

        setResolutionValue(resolutionValue => ({
          ...resolutionValue,
          [name]: value,
        }));
      } else if (value === '' && name === 'verticalResolution') {
        value = '1080';

        setResolutionValue(resolutionValue => ({
          ...resolutionValue,
          [name]: value,
        }));
      }

      handlePlaylistUpdate({ [name]: value });
    },
    [handlePlaylistUpdate],
  );

  return (
    <ContainerWrap isMenuOpen={isMenuOpen}>
      <Container>
        <Triangle>
          <TriangleIcon size={17} color="#2a2a2a" />
        </Triangle>
        <Box>
          <Row>
            <span>{t('manager.editor.modal.playlist.resolution')}</span>
          </Row>
          <Row>
            <Input
              style={{ textAlign: 'center' }}
              value={resolutionValue.horizonResolution}
              onChange={e => handleChangeResolution(e)}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.keyCode === 13 && e.target.blur()}
              onBlur={e => handleBlurResolution(e)}
              name="horizonResolution"
            />
            x
            <Input
              style={{ textAlign: 'center' }}
              value={resolutionValue.verticalResolution}
              onChange={e => handleChangeResolution(e)}
              onFocus={e => e.target.select()}
              onKeyUp={e => e.keyCode === 13 && e.target.blur()}
              onBlur={e => handleBlurResolution(e)}
              name="verticalResolution"
            />
            px
          </Row>
          <Row>
            <span>{t('manager.editor.modal.playlist.animation.title')}</span>
          </Row>
          <Row>
            <SelectInput
              selectedValue={playlistInfo.changeAnimation}
              optionList={[
                { value: 'none', title: t('manager.editor.modal.playlist.animation.none') },
                { value: 'changeOpacity', title: t('manager.editor.modal.playlist.animation.changeOpacity') },
                { value: 'scaleUp', title: t('manager.editor.modal.playlist.animation.scaleUp') },
                { value: 'leftToRight', title: t('manager.editor.modal.playlist.animation.leftToRight') },
                { value: 'rightToLeft', title: t('manager.editor.modal.playlist.animation.rightToLeft') },
                { value: 'bottomToTop', title: t('manager.editor.modal.playlist.animation.bottomToTop') },
                { value: 'topToBottom', title: t('manager.editor.modal.playlist.animation.topToBottom') },
              ]}
              onSelectChange={value => handlePlaylistUpdate({ changeAnimation: value })}
              height="30px"
            />
          </Row>
        </Box>
        <Box>
          <Row hover onClick={() => setIsPlaylistNmInputFocus(true)}>
            <span>{t('manager.editor.modal.playlist.rename')}</span>
          </Row>
          <Row hover onClick={() => setIsPlaylistRemoveModalOpen(true)}>
            <span>{t('manager.editor.modal.playlist.delete')}</span>
          </Row>
        </Box>
      </Container>
    </ContainerWrap>
  );
};

const PlaceNm = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  max-width: 10vw;
  opacity: 1;
  transition: max-width 0.32s cubic-bezier(0, 0, 0.49, 0.99);
  transition-property: max-width, opacity;
`;

const PlaylistNm = styled.div`
  max-width: 15vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
`;

const Down = styled.div`
  position: relative;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-bottom: 1px;
    & path {
      fill: rgba(255, 255, 255, 0.8);
    }
  }
`;

const Wrap = styled.div`
  min-width: 200px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

const ContainerWrap = styled.div`
  position: absolute;
  top: 60px;
  right: -140px;
  width: 300px;
  z-index: ${({ isMenuOpen }) => (isMenuOpen ? 100 : -1)};
  background-color: #2a2a2a;
  border-radius: 2px;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.22);
  visibility: ${({ isMenuOpen }) => (isMenuOpen ? 'initial' : 'hidden')};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Triangle = styled.div`
  position: absolute;
  top: -11px;
  right: 50%;
  transform: translateX(50%);
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: inherit;
  padding: 10px 0;

  & + & {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 13px 6px 22px;
  gap: 10px;

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        background: #41a1ea;
      }
    `}
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 30px;
  border-radius: 2px;
  border: 2px solid #41a1ea;
  background: inherit;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  padding: 7px;

  &:focus {
    outline: none;
  }
`;

const PlaylistNmInput = styled.input`
  background: inherit;
  border: none;
  width: 217px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;

  &::selection {
    background: #41a1ea;
  }

  &:focus {
    outline: none;
  }
`;

export default React.memo(Playlist);
