import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Selecto from 'react-selecto';
import throttle from 'lodash/throttle';

import { editorAction, editorSelector } from '../../../module/editorSlice';
import { playlistApi } from '../../../rtk/playlistApi';
import { endpoints as endpointsTouchLayerApi, useTouchLayerUpdateMutation } from '../../../rtk/touchLayerApi';

import Layer from './TouchModalLayer';
import MoveableManager from '../MoveableManager';

import Loading from '../../../newComponents/Loading';

import gridlineImg from '../../../assets/images/layer/gridlineImg.png';
import { EDITOR_GRID_SIZE } from '../constants/editor';

const TouchModalLayerList = ({ moveableRef, frameAngle }) => {
  const dispatch = useDispatch();
  const viewportRef = useRef(null);
  const selectoRef = useRef(null);

  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.touchFrameId);
  const selectedTouchLayerList = useSelector(editorSelector.selectedTouchLayerList);

  const { gridMode } = useSelector(editorSelector.canvasInfo);

  const {
    data: { horizonResolution, verticalResolution },
  } = playlistApi.endpoints.playlistDetail.useQueryState({ playlistId });

  const { currentData: layerList, isError } = endpointsTouchLayerApi.touchLayerList.useQueryState({ frameId });

  const [updateTouchLayerMutation] = useTouchLayerUpdateMutation();

  const [layerTargetList, setLayerTargetList] = useState([]);

  useEffect(() => {
    const elementList = [];
    for (const selectedLayer of selectedTouchLayerList) {
      const layerElement = document.getElementById(selectedLayer.id);
      if (layerElement) {
        elementList.push(layerElement);
      }
    }
    selectoRef.current.setSelectedTargets(elementList);
    setLayerTargetList(elementList);
  }, [selectedTouchLayerList]);

  const [ratio, setRatio] = useState(1);
  const [playerAreaInfo, setPlayerAreaInfo] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const verticalGuidelines = useMemo(() => {
    const horizon = Number(horizonResolution);
    const layerGuideList = layerList?.map(layer => [layer.x, layer.width + layer.x]).flat() || [];
    const array = [0, ...layerGuideList, horizon];

    if (gridMode) {
      for (let i = 0; i < horizon / EDITOR_GRID_SIZE; i++) {
        const point = i * EDITOR_GRID_SIZE;
        array.push(point);
      }
    }

    return array;
  }, [horizonResolution, layerList, gridMode]);

  const horizontalGuidelines = useMemo(() => {
    const vertical = Number(verticalResolution);
    const layerGuideList = layerList?.map(layer => [layer.y, layer.height + layer.y]).flat() || [];
    const array = [0, ...layerGuideList, vertical];

    if (gridMode) {
      for (let i = 0; i < vertical / EDITOR_GRID_SIZE; i++) {
        const point = i * EDITOR_GRID_SIZE;
        array.push(point);
      }
    }
    return array;
  }, [verticalResolution, layerList, gridMode]);

  const handleResize = useMemo(
    () =>
      throttle(() => {
        const canvasWidth = viewportRef.current.offsetWidth;
        const canvasHeight = viewportRef.current.offsetHeight;

        const horizon = Number(horizonResolution || 1);
        const vertical = Number(verticalResolution || 1);

        const scaleX = canvasWidth / (frameAngle === 0 || frameAngle === 180 ? horizon : vertical);
        const scaleY = canvasHeight / (frameAngle === 0 || frameAngle === 180 ? vertical : horizon);

        const ratio = scaleX < scaleY ? scaleX : scaleY;

        const ratioHorizon = Math.round(ratio * horizon);
        const ratioVertical = Math.round(ratio * vertical);
        setPlayerAreaInfo({
          x: Math.round((canvasWidth - ratioHorizon) / 2),
          y: Math.round((canvasHeight - ratioVertical) / 2),
          width: horizon,
          height: vertical,
        });

        setRatio(ratio);
      }, 300),
    [frameAngle, horizonResolution, verticalResolution],
  );

  useEffect(() => {
    if (viewportRef.current) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleUpdateLayerDB = useCallback(
    (updateList, id) => {
      const layerUpdateList = [];
      for (const update of updateList) {
        const { updateInfo, id } = update;
        layerUpdateList.push({ layerId: id, updateInfo });
      }

      layerUpdateList.length > 0 && updateTouchLayerMutation({ updateList: layerUpdateList });
    },
    [updateTouchLayerMutation],
  );

  const handleUpdateLayerState = throttle(
    useMemo(
      () => updateInfo => {
        dispatch(editorAction.updateSelectedTouchLayerInfo(updateInfo));
      },
      [dispatch],
    ),
    60,
  );

  const handleSelectLayer = useCallback(
    ({ currentTarget, isDragStart, selected, inputEvent, rect, stop }) => {
      const selectedLayerList = [];
      if (selected.length > 0) {
        const layerElement = selected[selected.length - 1];
        const id = layerElement.id;
        const layer = layerList.find(layer => layer.layerId === id);
        if (layer) {
          const { x, y, width, height } = layer;
          selectedLayerList.push({ type: 'LAYER', id, x, y, width, height });
        }
      }

      dispatch(editorAction.setState({ key: 'selectedTouchLayerList', value: selectedLayerList }));

      if (isDragStart) {
        inputEvent.preventDefault();
      }
    },
    [dispatch, layerList],
  );

  return (
    <Container id="touch-player-area">
      <Viewport ref={viewportRef} className="touch-viewer" id="touch-viewer" rotate={frameAngle}>
        {!layerList && !isError ? (
          <Loading />
        ) : isError ? (
          <></>
        ) : (
          <>
            <PlayerArea
              className="touch-player-area"
              width={playerAreaInfo.width || 1}
              height={playerAreaInfo.height || 1}
              gridMode={gridMode}
              x={playerAreaInfo.x}
              y={playerAreaInfo.y}
              ratio={ratio}
              rotate={frameAngle}
            >
              {layerList?.map((layer, index) => (
                <Layer
                  key={layer.layerId}
                  layerId={layer.layerId}
                  backgroundColor={layer.backgroundColor}
                  x={layer.x}
                  y={layer.y}
                  width={layer.width}
                  height={layer.height}
                  layerNm={layer.layerNm}
                  rotate={frameAngle}
                />
              ))}
              <MoveableManager
                moveableRef={moveableRef}
                frameAngle={frameAngle}
                lockMode={false}
                isAbleShow={false}
                targets={layerTargetList}
                verticalGuidelines={verticalGuidelines}
                horizontalGuidelines={horizontalGuidelines}
                updateLayerDB={handleUpdateLayerDB}
                updateLayerState={handleUpdateLayerState}
              />
            </PlayerArea>
          </>
        )}
      </Viewport>
      <Selecto
        ref={selectoRef}
        dragContainer=".touch-viewer"
        selectableTargets={['.touch-player-area .layer']}
        selectByClick={true}
        hitRate={0}
        preventDefault
        selectFromInside={false}
        onDragStart={e => {
          const moveable = moveableRef.current;
          const target = e.inputEvent.target;
          if (moveable.isMoveableElement(target) || layerTargetList.some(t => t === target || t.contains(target))) {
            e.stop();
          }
        }}
        onSelectEnd={e => handleSelectLayer(e)}
      />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #ccc;
`;

const Viewport = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ rotate }) => css`
    transform: ${`rotate(${rotate}deg)`};

    & .moveable-ne,
    .moveable-sw {
      cursor: ${rotate === 90 || rotate === 270 ? 'nwse-resize' : 'nesw-resize'} !important;
    }

    & .moveable-se,
    .moveable-nw {
      cursor: ${rotate === 90 || rotate === 270 ? 'nesw-resize' : 'nwse-resize'} !important;
    }

    & .moveable-n,
    .moveable-s {
      cursor: ${rotate === 90 || rotate === 270 ? 'e-resize' : 'n-resize'} !important;
    }

    & .moveable-w,
    .moveable-e {
      cursor: ${rotate === 90 || rotate === 270 ? 'n-resize' : 'e-resize'} !important;
    }
  `}
`;

const PlayerArea = styled.div`
  position: absolute;
  transform: ${({ x, y, ratio }) => `translate(${x}px, ${y}px) scale(${ratio})`};
  ${({ width, height, gridMode }) =>
    css`
      width: ${width}px;
      height: ${height}px;
      background-image: ${gridMode ? `url(${gridlineImg})` : 'initial'};
      background-repeat: ${gridMode ? 'repeat' : 'initial'};
    `}
  background-color: black;
  transform-origin: 0 0;
`;

export default React.memo(TouchModalLayerList);
