import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { useFrameUpdateMutation } from '../../rtk/frameApi';
import { FrameSetModal } from './modal/FrameModal';
import Loading from '../../newComponents/Loading';

import { FiEye as HideIcon, FiEyeOff as ActiveHideIcon } from 'react-icons/fi';
import { TiStarOutline as PrimaryIcon, TiStarFullOutline as ActivePrimaryIcon } from 'react-icons/ti';
import { MdClose as RemoveIcon, MdMoreVert as SetIcon } from 'react-icons/md';
import { ReactComponent as LockIcon } from '../../assets/images/frame/icon-lock.svg';

const Frame = ({
  frameInfo = [],
  index,
  className,
  openRemoveFrameModal,
  openHideFrameModal,
  frameLinkList,
  handleSetOption,
  isLoading = false,
  setIsEdit,
  isEdit,
  thumbnail,
}) => {
  const { t } = useTranslation();

  const [frameUpdate] = useFrameUpdateMutation();
  const [isHover, setIsHover] = useState(false);
  const { frameId = '', primaryYn = '', hideYn = '', autoNextYn = 'Y', frameDuration, thumbPath = '' } = frameInfo;

  const hourRef = useRef(null);
  const minRef = useRef(null);
  const secRef = useRef(null);

  const [frameDurationObj, setFrameDurationObj] = useState({
    hour: frameDuration?.hour,
    minute: frameDuration?.minute,
    second: frameDuration?.second,
  });

  const initModalInfo = useRef({
    modalInfo: {
      isOpen: false,
      offset: {
        left: '',
        top: '',
      },
    },
  });

  const [modalInfo, setModalInfo] = useState(initModalInfo.current.modalInfo);

  /*팝업과 관련된 함수*/
  const handleOpenMenu = useCallback((e, frameId) => {
    const { innerHeight: height } = window;

    let top = e.nativeEvent.pageY + 8;
    let left = e.nativeEvent.pageX + 20;

    const isOveflow = top + 431;

    if (isOveflow > height) {
      top = height - 431;
    }

    setModalInfo(modalInfo => ({
      ...modalInfo,
      isOpen: true,
      isOverFlow: isOveflow > height,
      offset: {
        left: left,
        top: top - 111,
      },
    }));
  }, []);

  //프레임 옵션 모달 닫기 이벤트
  const closeSetModal = useCallback((frameId, frameSetInfo) => {
    setModalInfo(modalInfo => ({
      ...modalInfo,
      ...initModalInfo.current.modalInfo,
    }));
  }, []);

  // 재생시간 valid 확인 함수
  const fnCheckValid = useCallback(value => {
    const regex = /^[0-9]{0,2}$/;
    return regex.test(value);
  }, []);

  // 프레임 시간 변경 이벤트
  const onChangeDuration = useCallback(
    (index, key, value) => {
      if (fnCheckValid(value)) {
        setFrameDurationObj({ ...frameDurationObj, [key]: value });
      }
    },
    [fnCheckValid, frameDurationObj],
  );

  // 한자리 0붙이기함수
  const fnZeroAdd = useCallback(value => {
    if ((value < 10 && typeof value === 'number') || (value.length === 1 && typeof value === 'string')) {
      value = '0' + value;
    }
    return String(value);
  }, []);

  // 프레임 재생 시간 자리올림 이벤트
  const onBlurDuration = useCallback(
    (index, key, value) => {
      if (autoNextYn === 'N') {
        return false;
      }
      let { hour, minute, second } = frameDurationObj;
      value = Number(value);
      if (key === 'hour') {
        hour = value;
      } else if (key === 'minute') {
        if (value > 59) {
          hour = Number(hour) + 1;
          minute = value % 60;
        } else {
          minute = value;
        }
      } else {
        if (value > 59) {
          if (Number(minute) + 1 > 59) {
            hour = Number(hour + 1);
            minute = 0;
            second = value % 60;
          } else {
            minute = Number(minute) + 1;
            second = value % 60;
          }
        } else {
          second = value;
        }
      }

      let frameDuration = {
        hour: fnZeroAdd(hour),
        minute: fnZeroAdd(minute),
        second: fnZeroAdd(second),
      };

      frameUpdate({ frameId, frameInfo: { frameDuration } });
    },
    [autoNextYn, frameDurationObj, fnZeroAdd, frameUpdate, frameId],
  );

  // 프레임 시간 엔터 이벤트
  const onEnterDuration = useCallback((typeRef, e) => {
    if (e.key === 'Enter') {
      typeRef.current.blur();
    }
  }, []);

  // 프레임 숨기기는 시간 포커스 안되도록 구현
  const onFocusDuration = useCallback((typeRef, isLock) => {
    if (isLock) {
      typeRef.current.blur();
    }
  }, []);

  useEffect(() => {
    if (frameDuration) {
      setFrameDurationObj({
        hour: frameDuration?.hour,
        minute: frameDuration?.minute,
        second: frameDuration?.second,
      });
    }
  }, [frameDuration]);

  return (
    <Container
      className={className}
      hideYn={hideYn}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Wrap>
        <ThumbNail>
          <Title>{frameInfo.frameNm}</Title>
          {isLoading && (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
          <img
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            onError={e => (e.target.style.display = 'none')}
            onLoad={e => (e.target.style.display = 'block')}
            src={thumbnail?.url || `${process.env.REACT_APP_INTERACTION_CDN_URL}${thumbPath}`}
            alt=""
          />
        </ThumbNail>
        <FrameInfo>
          <Time>
            <FrameDurationDiv isLock={autoNextYn === 'N'}>
              {isEdit?.length > 1 ? (
                <>
                  <FrameDurationInput
                    type="text"
                    ref={hourRef}
                    value={frameDurationObj.hour}
                    onChange={e => onChangeDuration(index, 'hour', e.target.value)}
                    onBlur={e => onBlurDuration(index, 'hour', e.target.value)}
                    onKeyPress={e => {
                      onEnterDuration(hourRef, e);
                      if (e.key === 'Enter') {
                        setIsEdit('');
                      }
                    }}
                    onFocus={e => onFocusDuration(hourRef, autoNextYn === 'N')}
                    style={{ marginLeft: '1px' }}
                    readOnly={autoNextYn === 'N'}
                  />
                  <span>:</span>
                  <FrameDurationInput
                    type="text"
                    ref={minRef}
                    value={frameDurationObj.minute}
                    onChange={e => onChangeDuration(index, 'minute', e.target.value)}
                    onBlur={e => onBlurDuration(index, 'minute', e.target.value)}
                    onKeyPress={e => {
                      onEnterDuration(minRef, e);
                      if (e.key === 'Enter') {
                        setIsEdit('');
                      }
                    }}
                    onFocus={e => onFocusDuration(minRef, autoNextYn === 'N')}
                    readOnly={autoNextYn === 'N'}
                  />
                  <span>:</span>
                  <FrameDurationInput
                    type="text"
                    ref={secRef}
                    value={frameDurationObj.second}
                    onChange={e => onChangeDuration(index, 'second', e.target.value)}
                    onBlur={e => onBlurDuration(index, 'second', e.target.value)}
                    onKeyPress={e => {
                      onEnterDuration(secRef, e);
                      if (e.key === 'Enter') {
                        setIsEdit('');
                      }
                    }}
                    onFocus={e => onFocusDuration(secRef, autoNextYn === 'N')}
                    readOnly={autoNextYn === 'N'}
                  />
                </>
              ) : (
                <FrameTitle
                  onClick={e => {
                    if (autoNextYn === 'N') {
                      e.preventDefault();
                      return false;
                    } else {
                      setIsEdit(frameId);
                    }
                  }}
                >
                  {frameDurationObj.hour}:{frameDurationObj.minute}:{frameDurationObj.second}
                  {autoNextYn === 'N' && <LockIcon style={{ marginLeft: '5px' }} />}
                </FrameTitle>
              )}
            </FrameDurationDiv>
          </Time>
          <FrameOption>
            <Button
              onClick={e => handleSetOption(e, 'hide', frameInfo)}
              data-tooltip-id={'frameCard-' + index}
              data-tooltip-content={t('manager.editor.frameArea.tool.hide')}
            >
              {hideYn === 'Y' ? (
                <ActiveHideIcon size={15} />
              ) : (
                <HideIcon size={15} color={primaryYn === 'Y' ? 'rgb(175 179 183)' : 'black'} />
              )}
            </Button>
            <Button
              onClick={e => handleSetOption(e, 'primary', frameInfo)}
              data-tooltip-id={'frameCard-' + index}
              data-tooltip-content={t('manager.editor.frameArea.tool.primaryYn')}
            >
              {primaryYn === 'Y' ? (
                <ActivePrimaryIcon size={16} color="#f7c139" />
              ) : (
                <PrimaryIcon size={16} color={hideYn === 'Y' ? 'rgb(175,179,183)' : 'black'} />
              )}
            </Button>
            <Button
              data-key="menu"
              onClick={e => handleOpenMenu(e, frameInfo.frameId)}
              data-tooltip-id={'frameCard-' + index}
              data-tooltip-content={t('manager.editor.frameArea.tool.setting')}
            >
              <SetIcon size={16} />
            </Button>
          </FrameOption>
        </FrameInfo>
      </Wrap>
      {frameInfo.primaryYn === 'Y' ? (
        <Tag isHover={isHover}>
          {isHover ? Number(frameInfo.frameOrder) + 1 : t('manager.editor.frameArea.primary')}
        </Tag>
      ) : (
        <>{isHover && <Tag isHover={isHover}>{Number(frameInfo.frameOrder) + 1 || ' '}</Tag>}</>
      )}
      {!isLoading && (
        <Cancel onClick={e => openRemoveFrameModal(e)}>
          <RemoveIcon />
        </Cancel>
      )}
      <Tooltip id={'frameCard-' + index} place="top" type="dark" effect="solid" multiline={true} />
      {modalInfo.isOpen && (
        <FrameSetModal
          openHideFrameModal={handleSetOption}
          setFrameDurationObj={setFrameDurationObj}
          closeModal={closeSetModal}
          frameInfo={frameInfo}
          modalInfo={modalInfo}
          frameLinkList={frameLinkList}
        />
      )}
    </Container>
  );
};

const Title = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: white;
  background-color: rgb(29 28 28 / 26%);
`;

const FrameTitle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 300;
  font-size: 14px;

  &:hover {
    transition: 0.4s all;
    background: rgb(173 231 255 / 20%);
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Cancel = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  margin: auto;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2491df;
  border-radius: 50%;
  box-shadow: 2px -2px 3px 3px #c4c4c452;
  background-color: white;

  &:hover {
    color: red;
  }
`;

const Tag = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 18%;
  height: 19%;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0px 0px 74px 0px / 8px 148px 78px 0px;
  box-shadow: 1px 0px 3px #85858578;
`;

const Button = styled.button`
  width: 20px;
  height: 20px;
  background: none;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 4.2/3;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px -3px 11px 3px #9a9a9a40;
  background-color: ${props => (props.hideYn === 'N' ? ' white' : 'darkgray')};

  cursor: pointer;
  overflow: hidden;

  /* display */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .order {
    display: none;
  }

  &:hover {
    outline: 1px solid #2491df;

    .order {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 2s;
    }
  }

  &.active {
    background-color: #e3f0fa;
    outline: 1px solid #2491df;

    & input {
      background-color: #e3f0fa;
      color: #2a91df;
    }

    & svg {
      .lock {
        fill: #2a91df;
      }
    }
  }
`;

const Wrap = styled.div`
  width: 90%;
  height: 90%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ThumbNail = styled.div`
  width: 100%;
  height: 80%;
  border-radius: inherit;
  font-size: 0.8em;
  background-color: rgb(128 128 128 / 41%);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FrameInfo = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Time = styled.div`
  flex: 4;
  text-align: left;
`;

const FrameOption = styled.div`
  display: flex;
  flex-direction: row;
  text-align: right;
  height: 100%;
  width: 100%;
  justify-content: inherit;
  align-items: center;
  flex: 2;
`;

const FrameDurationInput = styled.input`
  width: 15px;
  padding: 0;
  border: none;
  text-align: center;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 1px solid #2a91df;
  }
`;

const FrameDurationDiv = styled.div`
  display: flex;
  align-items: center;
  width: 64px;

  ${({ isLock }) =>
    isLock &&
    css`
      border: none;
      background-color: #c4c4c4;
      & input {
        background-color: #c4c4c4 !important;
        color: #666666;
      }
    `}
`;

export default React.memo(Frame);
