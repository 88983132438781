import React, { useRef, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';

import { INIT_NAVI_INFO } from '../constants/contentApp';

import RadioCheckBox from '../../../newComponents/RadioCheckbox';
import SelectInput from '../../../newComponents/SelectInput';

const NaviApp = ({
  selectLayerContentsInfo = INIT_NAVI_INFO,
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
}) => {
  const { t } = useTranslation();
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_NAVI_INFO);

  const [isCountOpen, setIsCountOpen] = useState(true);
  const [isTypeOpen, setIsTypeOpen] = useState(true);
  const [isShapeOpen, setIsShapeOpen] = useState(true);
  const [isOptionOpen, setIsOptionOpen] = useState(true);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const selctBoxOptionList = useRef([
    { value: 'home', title: t('manager.editor.layerArea.contents.navi.setting.home') },
    { value: 'prev', title: t('manager.editor.layerArea.contents.navi.setting.prev') },
    { value: 'next', title: t('manager.editor.layerArea.contents.navi.setting.next') },
  ]);

  const handleChangeCounter = useCallback(
    type => {
      let changeFormData = [...formData.contentsData.naviList];
      if (type === 'plus' && formData.contentsData.naviList.length < 3) {
        changeFormData.push({ id: 'home', text: t('manager.editor.layerArea.contents.navi.setting.home') });
        handleChangeObject('contentsData', 'naviList', changeFormData);
      } else if (type === 'minus' && formData.contentsData.naviList.length > 1) {
        changeFormData.pop();
        handleChangeObject('contentsData', 'naviList', changeFormData);
      }
    },
    [t, formData.contentsData.naviList, handleChangeObject],
  );

  const handleChangeNaviOption = useCallback(
    (value, index) => {
      let changeFormData = [...formData.contentsData.naviList];
      const findItem = selctBoxOptionList.current?.find(option => (option.value === value ? option : ''));
      changeFormData[index] = { id: value, text: findItem.title };
      handleChangeObject('contentsData', 'naviList', changeFormData);
    },
    [formData.contentsData.naviList, handleChangeObject],
  );

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };

      if (!layerContentsInfo.contentsData.type) {
        layerContentsInfo.contentsData.type = 'col';
      }
      if (!layerContentsInfo.contentsData.naviList) {
        layerContentsInfo.contentsData.naviList = [
          { id: 'home', text: t('manager.editor.layerArea.contents.navi.setting.home') },
        ];
      }
      if (!layerContentsInfo.contentsData.buttonRadius) {
        layerContentsInfo.contentsData.buttonRadius = 'left';
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_NAVI_INFO);
    }
  }, [t, selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.navi.title')} />
      <AppContentWrap>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox
              title={t('manager.editor.layerArea.contents.navi.count')}
              isOpen={isCountOpen}
              changeOpen={setIsCountOpen}
              line
            >
              <CounterWrapper>
                <WhiteButton onClick={() => handleChangeCounter('minus')}>-</WhiteButton>
                <Input value={formData.contentsData.naviList.length || 0} readOnly></Input>
                <WhiteButton onClick={() => handleChangeCounter('plus')}>+</WhiteButton>
              </CounterWrapper>
            </ContentBox>
            <ContentBox
              title={t('manager.editor.layerArea.contents.navi.typeOption.title')}
              isOpen={isTypeOpen}
              changeOpen={setIsTypeOpen}
              line
            >
              <RadioCheckBoxArea>
                <RadioCheckBoxWrapper>
                  <RadioCheckBox
                    name="layerContentsNaviButton"
                    id="layerContentsNaviButton-0"
                    checked={formData.contentsData.type === 'col'}
                    onChange={() => handleChangeObject('contentsData', 'type', 'col')}
                    label={t('manager.editor.layerArea.contents.navi.typeOption.col')}
                  />
                </RadioCheckBoxWrapper>
              </RadioCheckBoxArea>
            </ContentBox>
            <ContentBox
              title={t('manager.editor.layerArea.contents.navi.shapeOption.title')}
              isOpen={isShapeOpen}
              changeOpen={setIsShapeOpen}
              line
            >
              <RadioCheckBoxArea>
                <RadioCheckBoxWrapper>
                  <RadioCheckBox
                    name="buttonRadius"
                    id="buttonRadiusId-0"
                    checked={formData.contentsData.buttonRadius === 'left'}
                    onChange={() => handleChangeObject('contentsData', 'buttonRadius', 'left')}
                    label={t('manager.editor.layerArea.contents.navi.shapeOption.left')}
                  />
                </RadioCheckBoxWrapper>

                <RadioCheckBoxWrapper>
                  <RadioCheckBox
                    name="buttonRadius"
                    id="buttonRadiusId-1"
                    checked={formData.contentsData.buttonRadius === 'right'}
                    onChange={() => handleChangeObject('contentsData', 'buttonRadius', 'right')}
                    label={t('manager.editor.layerArea.contents.navi.shapeOption.right')}
                  />
                </RadioCheckBoxWrapper>

                <RadioCheckBoxWrapper>
                  <RadioCheckBox
                    name="buttonRadius"
                    id="buttonRadiusId-2"
                    checked={formData.contentsData.buttonRadius === 'all'}
                    onChange={() => handleChangeObject('contentsData', 'buttonRadius', 'all')}
                    label={t('manager.editor.layerArea.contents.navi.shapeOption.all')}
                  />
                </RadioCheckBoxWrapper>
              </RadioCheckBoxArea>
            </ContentBox>
            <ContentBox
              title={t('manager.editor.layerArea.contents.navi.setting.title')}
              isOpen={isOptionOpen}
              changeOpen={setIsOptionOpen}
            >
              {formData.contentsData.naviList &&
                formData.contentsData.naviList.map((navi, index) => (
                  <Box key={`naviOption-${index}`}>
                    <LeftArea>
                      <Title>Button 0{index + 1}</Title>
                    </LeftArea>
                    <RightArea>
                      <SelectInput
                        selectedValue={navi.id}
                        optionList={selctBoxOptionList.current}
                        onSelectChange={value => handleChangeNaviOption(value, index)}
                      />
                    </RightArea>
                  </Box>
                ))}
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea formData={formData} cancelApp={handleCancelLayerContents} selectedLayerType={selectedLayerType} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WhiteButton = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #dddddd;
  background: #ffffff;
  border-radius: 6px;
  color: #999999;
  font-size: 24px;

  ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(221, 221, 221, 0);
    `};
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 17px 6px 12px;
  border: 1px solid ${({ isError }) => (isError ? '#f05b5b' : '#dedede')};
  border-radius: 8px;
  font-size: 13px;
  color: #333333;
  border: 1px solid ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};
  text-align: center;
  margin: 0 8px;

  &:focus {
    outline: none;
  }
`;

const RadioCheckBoxArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  flex-wrap: wrap;
  font-size: 14px;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;

const RadioCheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
`;

const RightArea = styled.div`
  flex: 3;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;
  font-size: 14px;
`;

export default React.memo(NaviApp);
