import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { endpoints as endPointsFrameApi } from '../../rtk/frameApi';
import { useBaseLayerUpdateMutation, useLayerRemoveListMutation, useLayerUpdateListMutation } from '../../rtk/layerApi';
import { useOverlayRemoveListMutation, useOverlayUpdateListMutation } from '../../rtk/overlayApi';
import { editorSelector } from '../../module/editorSlice';
import { hexToRgb, rgbToHex } from '../../utils/color';

import CheckBox from '../../newComponents/CheckBox';
import SelectInput from '../../newComponents/SelectInput';
import ClickOutside from '../../components/ClickOutside';
import Button from '../../newComponents/Button';

import { ReactComponent as VolumeOnIcon } from '../../assets/images/layer/icon-volume-on.svg';
import { ReactComponent as VolumeOffIcon } from '../../assets/images/layer/icon-volume-off.svg';
import transparencyBg from '../../assets/images/background-color-none.svg';
import { ReactComponent as FrameLockIcon } from '../../assets/images/layer/icon-frame-lock.svg';

const LayerProperty = ({ moveableRef, historyRef, selectedLayerInfo }) => {
  const { t } = useTranslation();

  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.frameId);

  const { lockMode } = useSelector(editorSelector.canvasInfo);

  const [layerUpdateListMutation] = useLayerUpdateListMutation();
  const [layerRemoveListMutation] = useLayerRemoveListMutation();
  const [updateBaseLayer] = useBaseLayerUpdateMutation();

  const [overlayUpdateListMutation] = useOverlayUpdateListMutation();
  const [overlayRemoveListMutation] = useOverlayRemoveListMutation();

  const handleUpdateLayerDB = useCallback(
    (updateInfo, flag) => {
      if (flag === 'baseYn') {
        updateBaseLayer({ layerId: selectedLayerInfo.id, frameId });
      } else {
        switch (flag) {
          case 'width':
            moveableRef.current.request('resizable', { offsetWidth: updateInfo.width, isInstant: true });
            break;
          case 'height':
            moveableRef.current.request('resizable', { offsetHeight: updateInfo.height, isInstant: true });
            break;
          case 'x':
            moveableRef.current.request('draggable', { x: updateInfo.x, isInstant: true });
            break;
          case 'y':
            moveableRef.current.request('draggable', { y: updateInfo.y, isInstant: true });
            break;
          default:
            if (selectedLayerInfo.type === 'LAYER') {
              layerUpdateListMutation({ updateList: [{ layerId: selectedLayerInfo.id, updateInfo }] });
            } else if (selectedLayerInfo.type === 'OVERLAY') {
              overlayUpdateListMutation({ updateList: [{ overlayId: selectedLayerInfo.id, updateInfo }] });
            }
            break;
        }
      }
    },
    [
      updateBaseLayer,
      selectedLayerInfo.id,
      selectedLayerInfo.type,
      frameId,
      moveableRef,
      layerUpdateListMutation,
      overlayUpdateListMutation,
    ],
  );

  const handleRemoveLayerDB = useCallback(() => {
    if (selectedLayerInfo.baseYn === 'Y') {
      toast.error(t('manager.editor.toast.baseLayerNotRemove'));
      return;
    }
    if (selectedLayerInfo.type === 'LAYER') {
      layerRemoveListMutation({ removeList: [{ layerId: selectedLayerInfo.id }] }).then(({ data }) => {
        if (data.resultFlag) {
          historyRef.current.addHistory({
            type: 'REMOVE-LAYER',
            props: {
              infos: [selectedLayerInfo],
              prevSelectedLayerList: [selectedLayerInfo],
              nextSelectedLayerList: [],
            },
          });
        }
      });
    } else if (selectedLayerInfo.type === 'OVERLAY') {
      overlayRemoveListMutation({ removeList: [{ overlayId: selectedLayerInfo.id }] }).then(({ data }) => {
        if (data.resultFlag) {
          historyRef.current.addHistory({
            type: 'REMOVE-LAYER',
            props: {
              infos: [selectedLayerInfo],
              prevSelectedLayerList: [selectedLayerInfo],
              nextSelectedLayerList: [],
            },
          });
        }
      });
    }
  }, [t, selectedLayerInfo, layerRemoveListMutation, historyRef, overlayRemoveListMutation]);

  const handleNotifyLockMode = useCallback(() => {
    let highlightTimer = null;

    toast.error(t('manager.editor.toast.unlockForBegin'));

    const lockIconBtnEl = document.getElementsByClassName('lock-icon-btn')[0];

    lockIconBtnEl.classList.add('highlight');

    highlightTimer = setTimeout(() => {
      lockIconBtnEl.classList.remove('highlight');
      clearTimeout(highlightTimer);
    }, 3000);
  }, [t]);

  return (
    <Component>
      {lockMode && (
        <DisableBox onClick={() => handleNotifyLockMode()} style={lockMode ? { display: 'flex' } : { display: 'none' }}>
          <FrameLockIcon width="10em" height="10em" />
        </DisableBox>
      )}
      <Box>
        <Row>
          <Input title="X" value={selectedLayerInfo.x} name="x" handleUpdateLayerDB={handleUpdateLayerDB} />
          <Input title="Y" value={selectedLayerInfo.y} name="y" handleUpdateLayerDB={handleUpdateLayerDB} />
        </Row>
        <Row>
          <Input title="W" value={selectedLayerInfo.width} name="width" handleUpdateLayerDB={handleUpdateLayerDB} />
          <Input title="H" value={selectedLayerInfo.height} name="height" handleUpdateLayerDB={handleUpdateLayerDB} />
        </Row>
      </Box>
      <Box>
        <Row>
          {selectedLayerInfo.type === 'LAYER' && (
            <CheckBoxCol
              title={t('manager.editor.layerArea.property.baseYn')}
              checked={selectedLayerInfo.baseYn === 'Y'}
              handleUpdateLayerDB={handleUpdateLayerDB}
              name="baseYn"
            />
          )}
          <CheckBoxCol
            title={t('manager.editor.layerArea.property.muteYn')}
            checked={selectedLayerInfo.muteYn === 'Y'}
            handleUpdateLayerDB={handleUpdateLayerDB}
            name="muteYn"
          />
        </Row>
        <Row>
          {selectedLayerInfo.type === 'LAYER' && (
            <Input
              title={t('manager.editor.layerArea.property.name')}
              value={selectedLayerInfo.layerNm || ''}
              name="layerNm"
              handleUpdateLayerDB={handleUpdateLayerDB}
            />
          )}
          {selectedLayerInfo.type === 'OVERLAY' && (
            <Input
              title={t('manager.editor.layerArea.property.name')}
              value={selectedLayerInfo.overlayNm || ''}
              name="overlayNm"
              handleUpdateLayerDB={handleUpdateLayerDB}
            />
          )}
        </Row>
        <Row>
          <BackgroundColor
            title={t('manager.editor.layerArea.property.backgroudColor')}
            value={selectedLayerInfo.backgroundColor}
            name="backgroundColor"
            handleUpdateLayerDB={handleUpdateLayerDB}
          />
        </Row>
        <Row>
          <BackgroundColor
            title={t('manager.editor.layerArea.property.fontColor')}
            value={selectedLayerInfo.fontColor || { r: 0, g: 0, b: 0, a: 1 }}
            name="fontColor"
            handleUpdateLayerDB={handleUpdateLayerDB}
          />
        </Row>
        {selectedLayerInfo.type === 'LAYER' && (
          <Row>
            <FrameLink
              title={t('manager.editor.layerArea.property.frameLink')}
              playlistId={playlistId}
              frameLinkId={selectedLayerInfo.frameLinkId}
              handleUpdateLayerDB={handleUpdateLayerDB}
            />
          </Row>
        )}
        <Movement
          title={t('manager.editor.layerArea.property.sensor')}
          movementTime={selectedLayerInfo.movementTime}
          handleUpdateLayerDB={handleUpdateLayerDB}
          name="movementTime"
        />
      </Box>
      <Box>
        <Row>
          <Button style={{ marginLeft: 'auto' }} color="red" outline onClick={handleRemoveLayerDB}>
            {t('manager.editor.layerArea.property.remove')}
          </Button>
        </Row>
      </Box>
    </Component>
  );
};

const Input = React.memo(({ title, value, handleUpdateLayerDB, name }) => {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    inputRef.current.value = value;
  }, [value]);

  const handleBlurInput = (e, name) => {
    if (name === 'x' || name === 'y') {
      let targetValue = Number(e.target.value);

      if (targetValue < 0) {
        e.target.value = 0;
        targetValue = 0;
      }

      if (value !== targetValue) {
        handleUpdateLayerDB({ [name]: targetValue }, name);
      }
    } else if (name === 'width' || name === 'height') {
      let targetValue = Number(e.target.value);

      if (targetValue < 20) {
        e.target.value = 20;
        targetValue = 20;
      }

      if (value !== targetValue) {
        handleUpdateLayerDB({ [name]: targetValue }, name);
      }
    } else {
      if (value !== e.target.value) {
        handleUpdateLayerDB({ [name]: e.target.value }, name);
      }
    }
  };

  return (
    <InputLabel isFocus={isFocus}>
      <LabelSpan>{title}</LabelSpan>
      <input
        ref={inputRef}
        name={name}
        placeholder={name === 'layerNm' || name === 'overlayNm' ? t('manager.editor.layerArea.property.inputName') : ''}
        onInput={e =>
          name !== 'layerNm' && name !== 'overlayNm'
            ? (e.target.value = e.target.value.replace(/[^0-9]/g, ''))
            : e.target.value
        }
        onFocus={e => {
          setIsFocus(true);
          e.target.select();
        }}
        onBlur={e => {
          setIsFocus(false);
          handleBlurInput(e, name);
        }}
        onKeyUp={e => e.keyCode === 13 && e.target.blur()}
      />
    </InputLabel>
  );
});

const CheckBoxCol = React.memo(({ title, checked, handleUpdateLayerDB, name, muteYn }) => {
  return (
    <CheckLabel
      onClick={e => {
        e.preventDefault();
        !(name === 'baseYn' && checked) && handleUpdateLayerDB({ [name]: checked ? 'N' : 'Y' }, name);
      }}
    >
      <LabelSpan>{title}</LabelSpan>
      {name === 'muteYn' ? checked ? <VolumeOffIcon /> : <VolumeOnIcon /> : <CheckBox checked={checked} />}
    </CheckLabel>
  );
});

const BackgroundColor = React.memo(({ title, value, name, handleUpdateLayerDB }) => {
  const [isFocus, setIsFocus] = useState(false);
  const [hexCode, setHexCode] = useState('FFFFFF');
  const [alpha, setAlpha] = useState('0%');

  const [isOpen, setIsOpen] = useState(false);
  const [_value, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    const hex = rgbToHex(_value);
    setHexCode(hex);
    setAlpha(Number(_value.a) * 100 + '%');
  }, [_value]);

  const handleChangeHex = value => {
    if (value.length === 0) {
      setHexCode(value);
    } else {
      var pattern = /^([a-f0-9]{1,6})$/i;
      var regex = new RegExp(pattern);

      if (regex.test(value)) {
        setHexCode(value);
      }
    }
  };

  const handleBlurHex = targetValue => {
    var pattern = /^([a-f0-9]{6}|[a-f0-9]{3})$/i;
    var regex = new RegExp(pattern);

    if (regex.test(targetValue)) {
      const { r, g, b } = hexToRgb(targetValue);
      const value = { r, g, b, a: Number(alpha.replace('%', '')) / 100 };
      handleUpdateLayerDB({ [name]: value });
    } else {
      setHexCode(rgbToHex(value));
    }
    setIsFocus(false);
  };

  const handleFocusAlpha = e => {
    e.target.select();
    setAlpha(alpha.replace('%', ''));
    setIsFocus(true);
  };

  const handleChangeAlpha = value => {
    if (value.length === 0) {
      setAlpha(value);
    } else {
      var pattern = /^[0-9]{1}$|^[1-9]{1}[0-9]{1}$|^[1]{1}[0]{1}[0]{1}$/;
      var regex = new RegExp(pattern);

      if (regex.test(value)) {
        setAlpha(value);
      }
    }
  };

  const handleBlurAlpha = value => {
    var pattern = /^[0-9]{1}$|^[1-9]{1}[0-9]{1}$|^[1]{1}[0]{1}[0]{1}$/;
    var regex = new RegExp(pattern);

    if (regex.test(value)) {
      handleUpdateLayerDB({ [name]: { ..._value, a: Number(value) / 100 } });
    } else {
      setAlpha(Number(value.a) * 100 + '%');
    }
    setIsFocus(false);
  };

  return (
    <BackgroundBox isFocus={isFocus}>
      <LabelSpan>{title}</LabelSpan>
      <ClickOutside
        style={{ display: 'flex', alignItems: 'center', width: 'auto', position: 'relative', gap: '5px' }}
        onClickOutside={() => {
          if (isOpen) {
            const { r, g, b } = hexToRgb(hexCode);
            const value = { r, g, b, a: Number(alpha.replace('%', '')) / 100 };
            handleUpdateLayerDB({ [name]: value });
          }
          setIsOpen(false);
          setIsFocus(false);
        }}
      >
        <BackgroundColorModal isOpen={isOpen}>
          <SketchPicker
            color={_value}
            onChange={color => {
              setValue(color.rgb);
            }}
          />
        </BackgroundColorModal>
        <Color
          value={_value}
          onClick={() => {
            setIsFocus(true);
            setIsOpen(true);
          }}
        >
          <Alpha opacity={1 - _value.a} />
        </Color>
        <input
          className="background-input"
          style={{ width: '60px', textAlign: 'center' }}
          onFocus={e => {
            e.target.select();
            setIsFocus(true);
          }}
          onChange={e => handleChangeHex(e.target.value)}
          onBlur={e => handleBlurHex(e.target.value)}
          value={hexCode}
        />
        <input
          className="background-input input-left"
          style={{ width: '50px' }}
          onFocus={e => handleFocusAlpha(e)}
          onBlur={e => handleBlurAlpha(e.target.value)}
          onChange={e => handleChangeAlpha(e.target.value)}
          value={alpha}
        />
      </ClickOutside>
    </BackgroundBox>
  );
});
BackgroundColor.defaultProps = {
  value: { r: 255, g: 255, b: 255, a: 0 },
};

const FrameLink = React.memo(({ title, playlistId, frameLinkId, handleUpdateLayerDB }) => {
  const { t } = useTranslation();
  const [frameIdOptionList, setFrameIdOptionList] = useState([]);
  const [isFocus, setIsFocus] = useState(false);
  const { data: frameList } = endPointsFrameApi.frameList.useQueryState({ playlistId });

  useEffect(() => {
    if (frameList?.length > 0) {
      setFrameIdOptionList(frameIdOptionList => [
        {
          title: t('manager.editor.layerArea.property.linkFrameNone'),
          value: 'none',
        },
        ...frameList.map(frame => ({
          title: `${Number(frame.frameOrder) + 1}) ${frame.frameNm ? frame.frameNm : ''}`,
          value: frame.frameId,
        })),
      ]);
    }
  }, [t, frameList]);

  return (
    <SelectBox isFocus={isFocus} onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)}>
      <LabelSpan>{title}</LabelSpan>
      <SelectInput
        selectedValue={frameLinkId}
        optionList={frameIdOptionList}
        onSelectChange={value => handleUpdateLayerDB({ frameLinkId: value })}
        height="28px"
      />
    </SelectBox>
  );
});

const Movement = React.memo(({ title, movementTime, handleUpdateLayerDB }) => {
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);

  return (
    <SelectBox isFocus={isFocus} onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)}>
      <LabelSpan>{title}</LabelSpan>
      <SelectInput
        selectedValue={movementTime || '0'}
        optionList={[
          { value: '0', title: t('manager.editor.layerArea.property.timeNone') },
          { value: '3', title: '3' },
          { value: '5', title: '5' },
          { value: '7', title: '7' },
          { value: '10', title: '10' },
          { value: '15', title: '15' },
          { value: '20', title: '20' },
          { value: '25', title: '25' },
          { value: '30', title: '30' },
        ]}
        onSelectChange={value => handleUpdateLayerDB({ movementTime: value })}
        height="28px"
      />
    </SelectBox>
  );
});

const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  z-index: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  gap: 10px;

  & + & {
    border-top: 1px solid #dddddd;
  }
`;

const InputLabel = styled.label`
  user-select: none;
  display: flex;
  align-items: center;
  border: 1px solid ${({ isFocus }) => (isFocus ? '#41a1ea' : 'transparent')};
  width: 100%;
  height: 28px;
  border-radius: 2px;
  position: relative;
  color: #333;
  cursor: text;

  &:hover {
    border-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
  }

  & > input {
    display: flex;
    align-items: center;
    color: #666;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Noto Sans KR';

    width: 100%;
    padding: 0 0 0 7px;
    line-height: 10px;
    border: 1px solid transparent;
    margin-bottom: 2px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #dedede;
    }
  }
`;

const LabelSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  width: 32px;
  height: 100%;
  color: #b3b3b3;
  font-size: 12px;
  text-align: center;
`;

const BackgroundBox = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  position: relative;
  height: 28px;
  border: 1px solid ${({ isFocus }) => (isFocus ? '#41a1ea' : 'transparent')};

  & .input-left {
    border-left-color: ${({ isFocus }) => isFocus && '#41a1ea !important'};
  }

  &:hover {
    border-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
    .input-left {
      border-left-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
    }
  }

  & .background-input {
    display: flex;
    align-items: center;
    color: #666;
    font-weight: 500;
    font-size: 12px;
    font-family: 'Noto Sans KR';

    width: 100%;
    padding: 0 0 2px 7px;
    line-height: 10px;
    border: 1px solid transparent;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #dedede;
    }
  }
`;

const SelectBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  border: 1px solid ${({ isFocus }) => (isFocus ? '#41a1ea' : 'transparent')};

  &:hover {
    border-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
  }
`;

const Color = styled.div`
  position: relative;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  border-radius: 1px;
  margin-left: 7px;
  overflow: hidden;
  background: ${({ value }) => `rgb(${value.r}, ${value.g}, ${value.b})`};

  &:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    box-shadow: inset 0 0 0 1px #0000001a;
    transform-origin: center center;
    transform: scale(0.5);
  }
`;

const Alpha = styled.div`
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  background-image: url(${transparencyBg});
  opacity: ${({ opacity }) => opacity};
`;

const BackgroundColorModal = styled.div`
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  top: 25px;
  left: -32px;
  z-index: 30;
`;

const CheckLabel = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  gap: 7px;
  border: 1px solid ${({ isFocus }) => (isFocus ? '#41a1ea' : 'transparent')};
  width: 100%;
  height: 28px;
  border-radius: 2px;
  position: relative;
  color: #333;

  &:hover {
    border-color: ${({ isFocus }) => (isFocus ? '#41a1ea' : '#dddddd')};
  }
`;

const DisableBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #333333;
  opacity: 0.4;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  & .lock-icon {
    fill: rgb(185 185 185);
  }
`;

export default LayerProperty;
