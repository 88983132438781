import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { editorAction, editorSelector } from '../../module/editorSlice';
import { useDispatch, useSelector } from 'react-redux';

import DefaultApp from './app/DefaultApp';
import WebViewApp from './app/WebViewApp';
import CalendarApp from './app/CalendarApp';
import DateTimeApp from './app/DateTimeApp';
import WeatherApp from './app/WeatherApp';
import WorldWeatherApp from './app/WorldWeatherApp';
import LogoApp from './app/LogoApp';
import RssApp from './app/RssApp';
import TickerApp from './app/TickerApp';
import NaviApp from './app/NaviApp';
import TextApp from './app/TextApp';
import OneFloorApp from './app/OneFloorApp';
import WholeFloorApp from './app/WholeFloorApp';
import MediaApp from './app/MediaApp';
import AudioApp from './app/AudioApp';
import { endpoints as endpointsLayerContentsApi } from '../../rtk/layerContentsApi';
import { endpoints as endpointsOvelayContentsApi } from '../../rtk/overlayContentsApi';
import { useLayerContentsAddMutation } from '../../rtk/layerContentsApi';
import { useOverlayContentsAddMutation } from '../../rtk/overlayContentsApi';
import { useLayerContentsUpdateMutation } from '../../rtk/layerContentsApi';
import { useOverlayContentsUpdateMutation } from '../../rtk/overlayContentsApi';
import { useLayerContentsRemoveMutation } from '../../rtk/layerContentsApi';
import { useOverlayContentsRemoveMutation } from '../../rtk/overlayContentsApi';

import Loading from '../../newComponents/Loading';
import ChannelApp from './app/ChannelApp';

const LayerContentsApp = ({ selectedLayerId, selectedLayerType, selectedLayerContentsInfo }) => {
  const dispatch = useDispatch();

  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.frameId);

  const { currentData: layerContentList, isError: isLayerContentsListError } =
    endpointsLayerContentsApi.layerContentsList.useQueryState({ frameId: frameId });
  const { currentData: overlayContentsList, isError: isOverlayLayerContentsListError } =
    endpointsOvelayContentsApi.overlayContentsList.useQueryState({ playlistId });

  const [addLayerContents, { isLoading: isLayerContentsAddLoading }] = useLayerContentsAddMutation({
    fixedCacheKey: 'layer-app-create',
  });
  const [addOverlayContents, { isLoading: isOverLayContentsAddLoading }] = useOverlayContentsAddMutation({
    fixedCacheKey: 'overlay-app-create',
  });
  const [updateLayerContents, { isLoading: isLayerContentsUpdateLoading }] = useLayerContentsUpdateMutation({
    fixedCacheKey: 'layer-app-update',
  });
  const [updateOverlayContents, { isLoading: isOverLayContentsUpdateLoading }] = useOverlayContentsUpdateMutation({
    fixedCacheKey: 'overlay-app-update',
  });
  const [removeLayerContents, { isLoading: isLayerContentsRemoveLoading }] = useLayerContentsRemoveMutation({
    fixedCacheKey: 'layer-app-remove',
  });
  const [removeOverlayContents, { isLoading: isOverLayContentsRemoveLoading }] = useOverlayContentsRemoveMutation({
    fixedCacheKey: 'overlay-app-remove',
  });

  const [appLoading, setAppLoading] = useState(false);
  const [layerContentsInfo, setLayerContentsInfo] = useState(selectedLayerContentsInfo);

  const handleLayerContentsInfo = useCallback(
    (key, value) => {
      if (key === 'contentsType' && value !== layerContentsInfo.contentsType) {
        setLayerContentsInfo(layerContentsInfo => ({
          ...layerContentsInfo,
          [key]: value,
          contentsData: {},
          contentsFileList: [],
        }));
      } else {
        setLayerContentsInfo(layerContentsInfo => ({ ...layerContentsInfo, [key]: value }));
      }
    },
    [layerContentsInfo],
  );

  const handleCancelLayerContents = useCallback(() => {
    handleLayerContentsInfo('contentsType', 'DEFAULT');
    dispatch(editorAction.editingModeLayerContents(false));
  }, [handleLayerContentsInfo, dispatch]);

  useEffect(() => {
    if (
      selectedLayerContentsInfo.overlayId !== layerContentsInfo.overlayId ||
      selectedLayerContentsInfo.layerId !== layerContentsInfo.layerId ||
      selectedLayerContentsInfo.contentsId !== layerContentsInfo.contentsId
    ) {
      setLayerContentsInfo(selectedLayerContentsInfo);
    }
  }, [layerContentsInfo.layerId, selectedLayerContentsInfo, layerContentsInfo.contentsId, layerContentsInfo.overlayId]);

  useEffect(() => {
    if (
      isLayerContentsAddLoading ||
      isOverLayContentsAddLoading ||
      isLayerContentsUpdateLoading ||
      isOverLayContentsUpdateLoading ||
      isLayerContentsRemoveLoading ||
      isOverLayContentsRemoveLoading
    ) {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [
    appLoading,
    isLayerContentsAddLoading,
    isOverLayContentsAddLoading,
    isLayerContentsUpdateLoading,
    isOverLayContentsUpdateLoading,
    isLayerContentsRemoveLoading,
    isOverLayContentsRemoveLoading,
  ]);

  if ((!layerContentList && isLayerContentsListError) || (!overlayContentsList && isOverlayLayerContentsListError)) {
    return <Loading />;
  } else if (isLayerContentsListError || isOverlayLayerContentsListError) {
    return <></>;
  }

  switch (layerContentsInfo.contentsType) {
    case 'calendar':
      return (
        <Component>
          <CalendarApp />
        </Component>
      );
    case 'weather':
      return (
        <Component>
          <WeatherApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'worldWeather':
      return (
        <Component>
          <WorldWeatherApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'frameNo':
      return <Component>frameNo</Component>;
    case 'text':
      return (
        <Component>
          <TextApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'navi':
      return (
        <Component>
          <NaviApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'wholeFloor':
      return (
        <Component>
          <WholeFloorApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'oneFloor':
      return (
        <Component>
          <OneFloorApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'rss':
      return (
        <Component>
          <RssApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'ticker':
      return (
        <Component>
          <TickerApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'logo':
      return (
        <Component>
          <LogoApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'datetime':
      return (
        <Component>
          <DateTimeApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'webView':
      return (
        <Component>
          <WebViewApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    case 'media':
      return (
        <Component>
          <MediaApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
            appType={'media'}
          />
        </Component>
      );
    case 'audio':
      return (
        <Component>
          <AudioApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
            appType={'audio'}
          />
        </Component>
      );
    case 'channel':
      return (
        <Component>
          <ChannelApp
            selectLayerContentsInfo={layerContentsInfo}
            handleCancelLayerContents={handleCancelLayerContents}
            selectedLayerType={selectedLayerType}
            appLoading={appLoading}
          />
        </Component>
      );
    default:
      return (
        <Component>
          <DefaultApp handleLayerContentsInfo={handleLayerContentsInfo} />
        </Component>
      );
  }
};

const Component = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export default React.memo(LayerContentsApp);
