import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { formatBytes } from 'utils/file';
import { changeFromSecondToTime } from 'utils/time';

import { accountSelector } from '../../module/accountSlice';
import { useChannelListQuery, useChannelRemoveMutation } from 'rtk/channelApi';

import Button from 'newComponents/Button';
import CheckBox from 'newComponents/CheckBox';
import Loading from 'newComponents/Loading';
import Modal from 'newComponents/Modal';

import { Content, Table, TableBody, TableColumn, TableHead, TableRow, TableTitle } from './components/CommonComponent';

import { RiSearchLine as SearchIcon } from 'react-icons/ri';

const ChannelList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { viewPlaceId: placeId } = useSelector(accountSelector.accountInfo);
  const [checkList, setCheckList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [removeModal, setRemoveModal] = useState(false);

  const { currentData: channelList, isLoading } = useChannelListQuery({ placeId });
  const [channelRemove, { isLoading: isChannelRemoveLoading }] = useChannelRemoveMutation();

  useEffect(() => {
    if (!placeId) {
      return;
    }
  }, [placeId]);

  const handleDetailPage = useCallback(
    channelId => {
      navigate('/channel/detail', { state: { channelId } });
    },
    [navigate],
  );

  const handleRemoveChannel = useCallback(() => {
    if (checkList.length > 0) {
      channelRemove({ removeList: checkList, placeId }).then(res => {
        setCheckList([]);
        setRemoveModal(removeModal => false);
        toast.success(t('manager.channel.list.toast.removeSuccess'));
      });
    }
  }, [t, checkList, channelRemove, placeId]);

  const handleCheckChannel = useCallback(
    channelId => {
      if (channelId === 'all') {
        if (checkList.length === channelList.length) {
          setCheckList([]);
        } else {
          setCheckList(channelList.map(item => item.channelId));
        }
      } else {
        const isExist = checkList.some(item => item === channelId);
        if (isExist) {
          setCheckList(checkList.filter(item => item !== channelId));
        } else {
          setCheckList(checkList.concat(channelId));
        }
      }
    },
    [channelList, checkList],
  );

  const handleChangeSearch = useCallback(searchValue => {
    setSearchText(searchValue.trim());
  }, []);

  return (
    <Container isMobile={isMobile}>
      <Content>
        <Header>
          <InputDiv isMobile={isMobile}>
            <div className="inputWrapper">
              <span className="icon">
                <SearchIcon size={20} color="#999999" />
              </span>
              <SearchInput
                placeholder={t('manager.channel.list.tool.searchPlaceHolder')}
                width={'100%'}
                height={'100%'}
                onChange={e => handleChangeSearch(e.target.value)}
              />
            </div>
          </InputDiv>
          <ButtonDiv>
            <Button onClick={() => navigate('/channel/detail')}>{t('manager.channel.list.tool.add')}</Button>
            <RemobeStyledButton
              color={'red'}
              onClick={() => setRemoveModal(removeModal => true)}
              disabled={checkList.length <= 0}
            >
              {t('manager.channel.list.tool.remove')}
            </RemobeStyledButton>
          </ButtonDiv>
        </Header>
        <Table>
          <TableHead>
            <TableTitle>
              <TableColumn className="number">
                <CheckBox
                  checked={checkList.length === channelList?.length}
                  onChange={() => handleCheckChannel('all')}
                />
              </TableColumn>
              <TableColumn className="number">{t('manager.channel.list.column.no')}</TableColumn>
              <TableColumn className="title" isMobile={isMobile}>
                {t('manager.channel.list.column.name')}
              </TableColumn>
              <TableColumn className="title" isMobile={isMobile}>
                {t('manager.channel.list.column.desc')}
              </TableColumn>
              <TableColumn className="status">{t('manager.channel.list.column.playTime')}</TableColumn>
              <TableColumn className="status">{t('manager.channel.list.column.fileSize')}</TableColumn>
              <TableColumn className="reg-date">{t('manager.channel.list.column.date')}</TableColumn>
            </TableTitle>
          </TableHead>
          <TableBody className="designed-scroll">
            {isChannelRemoveLoading || isLoading ? (
              <Loading />
            ) : (
              <>
                {channelList?.length <= 0 ? (
                  <NoData>{t('manager.channel.list.noData')}</NoData>
                ) : (
                  channelList
                    ?.filter(item => item.channelNm?.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
                    .map((channel, index) => {
                      let { hour, minute, second } = changeFromSecondToTime(channel?.totChannelDuration || 0);
                      return (
                        <TableRow key={'notice-list-' + index} onClick={() => handleDetailPage(channel?.channelId)}>
                          <TableColumn
                            className="number"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <CheckBox
                              checked={checkList.some(item => item === channel?.channelId)}
                              onChange={e => {
                                handleCheckChannel(channel?.channelId);
                              }}
                            />
                          </TableColumn>
                          <TableColumn className="number">{index + 1}</TableColumn>
                          <TableColumn className="title" isMobile={isMobile}>
                            {channel.channelNm}
                          </TableColumn>
                          <TableColumn className="title" isMobile={isMobile}>
                            {channel.channelDesc}
                          </TableColumn>
                          <TableColumn className="status"> {`${hour}:${minute}:${second}`}</TableColumn>
                          <TableColumn className="status"> {formatBytes(channel?.totChannelFileSize || 0)}</TableColumn>
                          <TableColumn className="reg-date"> {dayjs(channel?.regDt).format('YYYY-MM-DD')}</TableColumn>
                        </TableRow>
                      );
                    })
                )}
              </>
            )}
          </TableBody>
        </Table>
      </Content>

      <Modal
        size={'small'}
        visible={removeModal}
        title={t('manager.channel.modal.remove.title')}
        isLine={true}
        confirmText={t('manager.channel.modal.remove.ok')}
        onCancel={() => setRemoveModal(false)}
        onClose={() => setRemoveModal(false)}
        onConfirm={handleRemoveChannel}
        cancelText={t('manager.channel.modal.remove.cancel')}
        children={<>{t('manager.channel.modal.remove.ment')}</>}
      />

      <ToastContainer
        style={{ width: 'auto', minWidth: '312px', zIndex: '10000' }}
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </Container>
  );
};

const RemobeStyledButton = styled(Button)`
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: rgb(149 70 70 / 48%);
    `}
`;

const SearchInput = styled.input`
  height: 40px;
  padding: 0 11px;
  min-width: 208px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 6px;
  outline: 0;
  font-weight: 500;
  font-size: 16px;
  color: #999999;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  flex: 2;

  .inputWrapper {
    width: ${({ isMobile }) => (isMobile ? '50%' : '30%')};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
  }
`;

const ButtonDiv = styled.div`
  gap: 1px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.isMobile ? '10px' : '30px 35px')};
  background-color: ${props => props.theme.bodyBg};

  .pageTitle {
    color: gray;
  }
`;

const NoData = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default ChannelList;
