import React, { useState, useEffect, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { FiUpload, FiImage, FiXSquare } from 'react-icons/fi';
import { AiFillSound as SoundIcon } from 'react-icons/ai';

import { editorAction, editorSelector } from '../../../module/editorSlice';
import { endpoints as endpointPlaceApi } from '../../../rtk/placeApi';
import { endpoints as endpointContentsApi } from '../../../rtk/contentsApi';

import { INIT_AUDIO_INFO } from '../constants/contentApp';

import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import AppButtonArea from './components/AppButtonArea';
import RemoveConfirmModal from './components/modal/RemoveConfirmModal';
import { AppContentWrap } from './components/ContentBox';
import PublicStorageModal from '../modal/PublicStorageModal';
import StorageModal from '../modal/StorageModal';

import SquareButton from '../../../components/SquareButton';
import SelectInput from '../../../newComponents/SelectInput';
import Loading from '../../../newComponents/Loading';
import Button from '../../../newComponents/Button';

import { getDuration } from '../../../utils/file';
import useUploadFileSizeCheck from '../../../utils/useUploadFileSizeCheck';

const AudioApp = ({
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
  selectLayerContentsInfo = INIT_AUDIO_INFO,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isPublicStorageModal, setIspublicStorageModal] = useState(false);
  const [isStorageModal, setIsStorageModal] = useState(false);
  const [isFolderSelected, setIsFolderSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLayerContentsConfirmModal, setIsRemoveLayerContentsConfirmModal] = useState(false);
  const placeId = useSelector(editorSelector.placeId);
  const editingMode = useSelector(editorSelector.editingMode);
  const { data: placeInfo } = endpointPlaceApi.placeDetail.useQueryState({ placeId });
  const { data: placeFileInfo, refetch: placeFileInfoRefetch } = endpointContentsApi.contentsFileDetail.useQuery({
    placeId,
  });

  const { fnUploadFileSizeCheck } = useUploadFileSizeCheck();
  const [uploadFileSize, setUploadFileSize] = useState(0);
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_AUDIO_INFO);

  const [activeFileIndex, setActiveFileIndex] = useState(0);

  const [addFileList, setAddFileList] = useState([]);
  const [waitUploadFileList, setWaitUploadFileList] = useState([]);
  const [contentsFileList, setContentsFileList] = useState([]);

  const [audioPlay, setAudioPlay] = useState(false);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const handleChangeMediaOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.mediaList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'mediaList', changeFormData);
    },
    [formData.contentsData.mediaList, handleChangeObject],
  );

  const closeModal = useCallback(type => {
    setIspublicStorageModal(false);
    setIsStorageModal(false);
    setIsFolderSelected(false);
    setIsRemoveLayerContentsConfirmModal(false);

    if (type === 'remove') {
      setWaitUploadFileList([]);
    }
  }, []);

  const handleChangeStorageAdd = useCallback(
    addMediaList => {
      handleChangeObject('contentsData', 'mediaList', addMediaList);
      setActiveFileIndex(0);
    },
    [handleChangeObject],
  );

  const onChangeAddfileList = useCallback(
    async folder => {
      setIsLoading(true);

      const uploadFiles = fnUploadFileSizeCheck(waitUploadFileList, '', uploadFileSize, placeInfo.storageMax);
      let changeMediaList = [];
      let changeAddFileList = [];
      let changeForm = { ...folder };

      let contentsFileInfo = {};
      for (var i = 0; i < uploadFiles.length; i++) {
        let file = uploadFiles[i];

        const fileId = dayjs().unix() + v4().substr(0, 8);
        let addFileInfo = {
          fileData: file,
          fileId: fileId,
          fileNm: file.name,
          fileSize: file.size,
          fileType: file.type,
          filePath: folder.folderId,
          fileOrder: changeMediaList.length + 1,
        };

        contentsFileInfo = {
          fileId: fileId,
          fileNm: file.name,
          filePath: URL.createObjectURL(file),
          fileSize: file.size,
          fileType: file.type,
          fileThumb: URL.createObjectURL(file),
        };

        let fileOption = {
          filePlaceId: placeId,
          fileFolderId: folder.folderId,
          filePreview: URL.createObjectURL(file),
          fileId: fileId,
          fileDragId: fileId,
          fileData: file,
          fileType: file.type,
          fileNm: file.name,
          fromPublic: 'Y',
        };

        const duration = await getDuration(file);
        fileOption.changeTime = String(duration);
        fileOption.sound = 'change';
        addFileInfo.fileDuration = String(duration);

        changeMediaList.push(fileOption);
        changeAddFileList.push(addFileInfo);
        setUploadFileSize(uploadFileSize => uploadFileSize + file.size);
      }

      changeForm.fileList = changeAddFileList;

      handleChangeObject('contentsData', 'mediaList', changeMediaList);
      setAddFileList({ ...addFileList, [folder.folderId]: changeForm });
      setContentsFileList([contentsFileInfo]);
      setActiveFileIndex(changeMediaList.length - 1);

      setIsLoading(false);
    },
    [
      addFileList,
      placeId,
      waitUploadFileList,
      fnUploadFileSizeCheck,
      uploadFileSize,
      placeInfo.storageMax,
      handleChangeObject,
    ],
  );

  const onClickDelete = useCallback(() => {
    if (formData.contentsData.mediaList[activeFileIndex].fileFolderId) {
      let fileFolderId = formData.contentsData.mediaList[activeFileIndex].fileFolderId;
      let removeFileId = formData.contentsData.mediaList[activeFileIndex].fileId;

      let changeAddFileForm = { ...addFileList[fileFolderId] };
      let changeAddfileList = [...addFileList[fileFolderId].fileList];
      changeAddfileList = changeAddfileList.filter(fileInfo => fileInfo.fileId !== removeFileId);
      changeAddFileForm.fileList = changeAddfileList;
      setAddFileList({ ...addFileList, [fileFolderId]: changeAddFileForm });
    }

    let changeFormData = [...formData.contentsData.mediaList];
    let changeContentFileList = [...contentsFileList];
    changeFormData.splice(activeFileIndex, 1);
    changeContentFileList.splice(activeFileIndex, 1);

    if (formData.contentsData.mediaList.length - 2 < activeFileIndex && activeFileIndex > 0) {
      setActiveFileIndex(activeFileIndex => activeFileIndex - 1);
    }

    if (changeFormData.length === 0) {
      if (formData.contentsId === '') {
        handleCancelLayerContents();
      } else {
        setIsRemoveLayerContentsConfirmModal(true);
      }
    } else {
      handleChangeObject('contentsData', 'mediaList', changeFormData);
      setContentsFileList(changeContentFileList);
    }
  }, [
    activeFileIndex,
    formData.contentsData.mediaList,
    formData.contentsId,
    handleChangeObject,
    addFileList,
    contentsFileList,
    handleCancelLayerContents,
  ]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections && fileRejections.length === 0) {
        setWaitUploadFileList(acceptedFiles);
        setIsFolderSelected(true);
        setIsStorageModal(true);
        placeFileInfoRefetch();
      } else {
        toast.error(t('manager.editor.layerArea.contents.audio.fileNotSupport'));
      }
    },
    [t, placeFileInfoRefetch],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'audio/*',
  });

  useEffect(() => {
    if (!editingMode) {
      if (selectLayerContentsInfo) {
        let layerContentsInfo = { ...selectLayerContentsInfo };

        if (!layerContentsInfo.contentsData.mediaList) {
          layerContentsInfo.contentsData.mediaList = [];
        }
        setLayerContentsInfo(layerContentsInfo);
      } else {
        setLayerContentsInfo(INIT_AUDIO_INFO);
      }
      dispatch(editorAction.editingModeLayerContents(false));
      setAddFileList([]);
      setActiveFileIndex(0);
    }
  }, [selectLayerContentsInfo, dispatch, editingMode]);

  useEffect(() => {
    if (formData.contentsFileList) {
      setContentsFileList(formData.contentsFileList);
    } else {
      setContentsFileList([]);
    }
  }, [formData.contentsFileList]);

  useEffect(() => {
    if (placeFileInfo) {
      setUploadFileSize(placeFileInfo.totalFileSize);
    }
  }, [placeFileInfo]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.audio.title')} />
      <AppContentWrap>
        {isLoading || appLoading ? (
          <Loading />
        ) : (
          <>
            <ButtonWrapper>
              <Button
                color={'gray700'}
                outline="true"
                onClick={() => {
                  setIspublicStorageModal(true);
                  placeFileInfoRefetch();
                }}
              >
                {t('manager.editor.layerArea.contents.audio.publicStorage')}
              </Button>
              <Button
                color={'gray700'}
                outline="true"
                onClick={() => {
                  setIsStorageModal(true);
                  placeFileInfoRefetch();
                }}
              >
                {t('manager.editor.layerArea.contents.audio.storage')}
              </Button>
            </ButtonWrapper>
            <ContentBox>
              {contentsFileList.length === 0 ? (
                <StyledTextDiv {...getRootProps({ isDragAccept, isDragReject })}>
                  <div className="icon">
                    <FiUpload size="17px" color="#999999" />
                  </div>
                  <input {...getInputProps()} />
                  <Trans i18nKey="manager.editor.layerArea.contents.audio.dropFile" />
                </StyledTextDiv>
              ) : (
                <FileUploadArea style={{ paddingTop: '0px' }}>
                  <>
                    <FilePreviewArea>
                      <AudioArea audioPlay={audioPlay}>
                        <SoundIcon
                          id="soundIcon"
                          style={{ width: '40%', height: '100%', marginTop: '2.5rem' }}
                          fill="gray"
                        />
                        <audio
                          onPlay={() => setAudioPlay(true)}
                          onPause={() => setAudioPlay(false)}
                          controls
                          style={{ width: '100%' }}
                          src={
                            contentsFileList[activeFileIndex]?.filePath?.includes('blob:')
                              ? contentsFileList[activeFileIndex]?.filePath
                              : process.env.REACT_APP_INTERACTION_CDN_URL + contentsFileList[activeFileIndex]?.filePath
                          }
                        />
                      </AudioArea>
                      <FileInfoArea>
                        <FiImage size="1.1875rem" style={{ marginRight: '0.625rem' }} />
                        <FileInfoText>{contentsFileList[activeFileIndex]?.fileNm || ''}</FileInfoText>
                        <FiXSquare size="1.125rem" style={{ cursor: 'pointer' }} onClick={() => onClickDelete()} />
                      </FileInfoArea>
                    </FilePreviewArea>

                    <PlusButtonArea>
                      <StyledSquareButton>
                        {t('manager.editor.layerArea.contents.audio.fileChange')}
                        <FileInput
                          type="file"
                          accept={'audio/*'}
                          onChange={e => {
                            setWaitUploadFileList(Array.prototype.slice.call(e.target.files));
                            setIsFolderSelected(true);
                            setIsStorageModal(true);
                            placeFileInfoRefetch();
                          }}
                        />
                      </StyledSquareButton>
                    </PlusButtonArea>
                    <Box>
                      <LeftArea>
                        <Title>{t('manager.editor.layerArea.contents.audio.playOption.title')}</Title>
                      </LeftArea>
                      <RightArea>
                        <SelectInput
                          selectedValue={formData.contentsData.mediaList[activeFileIndex]?.sound}
                          optionList={[
                            { value: 'change', title: t('manager.editor.layerArea.contents.audio.playOption.change') },
                            { value: 'always', title: t('manager.editor.layerArea.contents.audio.playOption.always') },
                          ]}
                          onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'sound', value)}
                        />
                      </RightArea>
                    </Box>
                  </>
                </FileUploadArea>
              )}
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        cancelApp={handleCancelLayerContents}
        addFileList={addFileList}
        selectedLayerType={selectedLayerType}
        noConform={contentsFileList.length === 0}
      />
      <PublicStorageModal
        isOpen={isPublicStorageModal}
        closeModal={closeModal}
        handleChangeStorageAdd={handleChangeStorageAdd}
        contentsFileList={contentsFileList}
        setContentsFileList={setContentsFileList}
        multiSelect={false}
        appType={'audio'}
      />
      <StorageModal
        isOpen={isStorageModal}
        closeModal={closeModal}
        handleChangeStorageAdd={handleChangeStorageAdd}
        placeId={placeId}
        isFolderSelected={isFolderSelected}
        onChangeAddfileList={onChangeAddfileList}
        contentsFileList={contentsFileList}
        setContentsFileList={setContentsFileList}
        multiSelect={false}
        appType={'audio'}
      />
      <RemoveConfirmModal
        contentsId={formData.contentsId}
        isOpen={isRemoveLayerContentsConfirmModal}
        closeModal={closeModal}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const getColor = props => {
  if (props.isDragAccept) {
    return '#2a91df';
  }
  if (props.isDragReject) {
    return '#f05b5b';
  }
  return '#cfcfcf';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;
const audioBoxAnimation = keyframes`
  from{
     fill: gray;
  }
  to{
    fill: rgb(85 85 85 );
  }
`;

const AudioArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${props =>
    props.audioPlay
      ? css`
          #soundIcon {
            animation: ${audioBoxAnimation} 2s infinite;
          }
        `
      : css`
          #soundIcon {
            animation: none;
          }
        `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin: 0 19px 19px 0;
`;

const ContentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 80%;
`;

const StyledTextDiv = styled.div`
  display: flex;
  min-height: 200px;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.3808px;
  text-align: center;
  color: #999999;
  justify-content: center;
  background-color: #f6f8f9;
  border: dashed 1px;
  border-color: ${props => getColor(props)};
  cursor: pointer;
  outline: none;

  & .icon {
    height: 17px;
    margin-bottom: 15px;
  }
`;

const FileUploadArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilePreviewArea = styled.div`
  position: relative;
  height: 238px;
  border: solid 1px #dddddd;
  margin-bottom: 10px;
`;

const FileInfoArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  font-size: 13px;
  color: #ffffff;
  background-color: #313131;
  justify-content: space-between;
  opacity: 0.7;
  padding: 11px 18px;
`;

const FileInfoText = styled.div`
  max-width: 290px;
  padding-top: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex: 1;
  height: 100%;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
`;
const RightArea = styled.div`
  flex: 3;
  align-items: center;
`;
const Title = styled.div`
  width: 100%;
  font-size: 14px;
`;

const PlusButtonArea = styled.div`
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledSquareButton = styled(SquareButton)`
  position: relative;
  display: flex;
  width: ${({ dragModal }) => (dragModal ? '170px' : '76px')};
  height: 40px;
  border-radius: 6px;
  border: solid 1px #cfcfcf;
  color: #999999;
  background-color: ${({ isLastItem }) => (isLastItem ? '#f1f1f1' : '#ffffff')};
  padding: 4px 6px;
  float: right;
  align-items: center;
  justify-content: center;
`;
const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export default React.memo(AudioApp);
