import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Company from './Company';
import SelectInput from '../../../../newComponents/SelectInput';

const Floor = ({
  index,
  floor,
  floorList,
  handleChangeFloorOption,
  frameOptionList,
  formValidate,
  handleChangeObject,
}) => {
  const { t } = useTranslation();
  const handleChangeCounter = useCallback(
    type => {
      let changeFloorList = [...floorList];
      let changeFormData = [...floor.companyList];
      if (type === 'plus') {
        changeFormData.push({
          frameLinkId: null,
          companyId: changeFormData.length + 1,
          companyName: '',
        });

        changeFloorList[index] = { ...floor, companyList: changeFormData };
        handleChangeObject('contentsData', 'floorList', changeFloorList);
      } else if (type === 'minus' && floor.companyList.length > 1) {
        changeFormData.pop();
        changeFloorList[index] = { ...floor, companyList: changeFormData };
        handleChangeObject('contentsData', 'floorList', changeFloorList);
      }
    },
    [floor, floorList, handleChangeObject, index],
  );

  const handleChangeCompanyOption = useCallback(
    (idx, key, value) => {
      let changeFloorList = [...floorList];
      let changeFormData = [...floor.companyList];
      changeFormData[idx][key] = value;

      changeFloorList[index] = { ...floor, companyList: changeFormData };
      handleChangeObject('contentsData', 'floorList', changeFloorList);
    },
    [floor, floorList, handleChangeObject, index],
  );

  const handleChangeCompanyObject = useCallback(
    (idx, company) => {
      let changeFloorList = [...floorList];
      let changeFormData = [...floor.companyList];
      changeFormData[idx] = company;

      changeFloorList[index] = { ...floor, companyList: changeFormData };
      handleChangeObject('contentsData', 'floorList', changeFloorList);
    },
    [floor, floorList, handleChangeObject, index],
  );

  return (
    <Container>
      <Box>
        <LeftArea>{floor.floorTitle}</LeftArea>
        <RightArea>
          <Input
            className="floorSubTitle"
            placeholder="* Sub Title."
            value={floor.floorSubTitle || ''}
            onChange={e => handleChangeFloorOption(index, 'floorSubTitle', e.target.value)}
            isValidate={formValidate.floorList[index] && formValidate.floorList[index].floorSubTitle}
          />
          <InputError>{formValidate.floorList[index] && formValidate.floorList[index].floorSubTitleError}</InputError>

          <CounterWrapper>
            <WhiteButton onClick={() => handleChangeCounter('minus')}>-</WhiteButton>
            <CustomInput value={floor.companyList.length || 0} readOnly></CustomInput>
            <WhiteButton onClick={() => handleChangeCounter('plus')}>+</WhiteButton>
            <FloorYNWrapper>
              <input
                type="checkbox"
                id={`floorYn${index}`}
                style={{ width: '14px', height: '14px' }}
                checked={floor.floorYn === 'Y' ? false : true}
                onChange={() => handleChangeFloorOption(index, 'floorYn', floor.floorYn === 'Y' ? 'N' : 'Y')}
              />

              <FloorYNTitle>{t('manager.editor.layerArea.component.floor.disable')}</FloorYNTitle>
            </FloorYNWrapper>
          </CounterWrapper>

          {floor.companyList &&
            floor.companyList.map((company, idx) => (
              <React.Fragment key={`company-app-${idx}`}>
                <Company
                  fIndex={index}
                  index={idx}
                  company={company}
                  companyList={floor.companyList}
                  frameOptionList={frameOptionList}
                  formValidate={formValidate}
                  handleChangeCompanyOption={handleChangeCompanyOption}
                  handleChangeCompanyObject={handleChangeCompanyObject}
                />

                <FrameSelectWrapper>
                  <FrameSelectTitle>{t('manager.editor.layerArea.component.floor.frameLink')}</FrameSelectTitle>
                  <SelectInput
                    selectedValue={company.frameLinkId ? company.frameLinkId : null}
                    optionList={frameOptionList}
                    onSelectChange={value => handleChangeCompanyOption(idx, 'frameLinkId', value)}
                    defaultTitle={null}
                  />
                </FrameSelectWrapper>
              </React.Fragment>
            ))}
        </RightArea>
      </Box>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Box = styled.div`
  display: flex;
`;

const LeftArea = styled.div`
  display: flex;
  width: 20px;
  min-width: 20px;
  font-size: 14px;
  padding-top: 8px;
`;

const RightArea = styled.div`
  flex: 1;
  align-items: center;
  padding-left: 20px;
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 6px 17px 6px 12px;
  border: 1px solid ${({ isError }) => (isError ? '#f05b5b' : '#dedede')};
  border-radius: 8px;
  font-size: 14px;
  color: #333333;
  border: 1px solid ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};

  &:focus {
    outline: none;
  }
`;

const InputError = styled.label`
  height: 20px;
  padding: 2px 4px;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const WhiteButton = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #dddddd;
  background: #ffffff;
  border-radius: 6px;
  color: #999999;
  font-size: 24px;

  ${({ disabled }) =>
    disabled &&
    css`
      background: rgba(221, 221, 221, 0);
    `};
`;

const CustomInput = styled(Input)`
  text-align: center;
  margin: 0 8px;
`;

const FloorYNWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FloorYNTitle = styled.div`
  margin-left: 4px;
  font-size: 12px;
`;

const FrameSelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FrameSelectTitle = styled.div`
  margin-right: 10px;
  font-size: 12px;
`;

export default React.memo(Floor);
