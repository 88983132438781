import React from 'react';
import { RouterProvider, Navigate, createBrowserRouter } from 'react-router-dom';

import HomeLayout from '../pages/layouts/Home';
import OpenLayout from '../pages/layouts/Open';
import AuthLayout from '../pages/layouts/Auth';
import ManagerLayout from '../pages/layouts/Manager';
import EditorLayout from '../pages/editor/EditorLayout';
import ChannelList from '../pages/channel/ChannelList';
// import PlayerLayout from '../pages/layouts/Player';
import ErrorLayout from '../pages/layouts/Error';

const Home = React.lazy(() => import('../pages/home/Home'));
const Privacy = React.lazy(() => import('../pages/home/Privacy'));
const Policy = React.lazy(() => import('../pages/home/Policy'));
const Ecommerce = React.lazy(() => import('../pages/home/Ecommerce'));
const ProductDetail = React.lazy(() => import('../pages/home/ProductDetail'));
const EligaOrderLanding = React.lazy(() => import('../pages/home/EligaOrderLanding'));

const OpenPhotoView = React.lazy(() => import('../pages/photo/View'));

const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const Verify = React.lazy(() => import('../pages/auth/Verify'));
const Forget = React.lazy(() => import('../pages/auth/Forget'));
const ChangePw = React.lazy(() => import('../pages/auth/ChangePw'));

const DashboardDefault = React.lazy(() => import('../pages/dashboard/Default'));
const Setting = React.lazy(() => import('../pages/system/Setting'));
// const Pricing = React.lazy(() => import('../pages/system/Pricing'));

const DeviceList = React.lazy(() => import('../pages/device/DeviceList'));
const DeviceDetail = React.lazy(() => import('../pages/device/DeviceDetail'));

const StorageList = React.lazy(() => import('../pages/storage/StorageList'));

const Report = React.lazy(() => import('../pages/report/Report'));
const DeviceLog = React.lazy(() => import('../pages/report/DeviceLog'));
const DeviceLogListView = React.lazy(() => import('../pages/report/DeviceLogListView'));
const PlaylistLog = React.lazy(() => import('../pages/report/PlaylistLog'));
const PlaylistLogListView = React.lazy(() => import('../pages/report/PlaylistLogListView'));
const ChannelLog = React.lazy(() => import('../pages/report/ChannelLog'));
const ChannelLogListView = React.lazy(() => import('../pages/report/ChannelLogListView'));
const MediaLog = React.lazy(() => import('../pages/report/MediaLog'));
const MediaLogListView = React.lazy(() => import('../pages/report/MediaLogListView'));

const PlaylistMain = React.lazy(() => import('../pages/playlist/Main'));

const NoticeList = React.lazy(() => import('../pages/board/NoticeList'));
const NoticeDetail = React.lazy(() => import('../pages/board/NoticeDetail'));
const ReleaseList = React.lazy(() => import('../pages/board/ReleaseList'));
const ReleaseDetail = React.lazy(() => import('../pages/board/ReleaseDetail'));
const QnaList = React.lazy(() => import('../pages/board/QnaList'));
const QnaDetail = React.lazy(() => import('../pages/board/QnaDetail'));
const QnaAdd = React.lazy(() => import('../pages/board/QnaAdd'));
const GuideList = React.lazy(() => import('../pages/board/GuideList'));
const GuideDetail = React.lazy(() => import('../pages/board/GuideDetail'));

const ChannelDetail = React.lazy(() => import('../pages/channel/ChannelDetail'));

const ScheduleList = React.lazy(() => import('../pages/schedule/ScheduleList'));
const ScheduleAdd = React.lazy(() => import('../pages/schedule/ScheduleAdd'));
const ScheduleEdit = React.lazy(() => import('../pages/schedule/ScheduleEdit'));

const rootRoutes = {
  path: '/',
  element: <HomeLayout />,
  children: [
    {
      index: true,
      element: <Home />,
    },
    {
      path: 'terms/privacy',
      element: <Privacy />,
    },
    {
      path: 'terms/policy',
      element: <Policy />,
    },
    {
      path: 'terms/ecommerce',
      element: <Ecommerce />,
    },
    {
      path: 'home/product',
      element: <ProductDetail />,
    },
    {
      path: 'eligaorder',
      element: <EligaOrderLanding />,
    },
  ],
};

const openRoutes = {
  path: '/open',
  element: <OpenLayout />,
  children: [
    {
      path: 'photo/view',
      element: <OpenPhotoView />,
    },
  ],
};

const authRoutes = {
  path: '/account',
  element: <AuthLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/account/login" />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'logout',
      element: <Logout />,
    },
    {
      path: 'signup',
      element: <SignUp />,
    },
    {
      path: 'verify',
      element: <Verify />,
    },
    {
      path: 'forget',
      element: <Forget />,
    },
    {
      path: 'change-password',
      element: <ChangePw />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const dashboardRoutes = {
  path: '/dashboard',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <DashboardDefault />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const deviceRoutes = {
  path: '/device',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/device/list" />,
    },
    {
      path: 'list',
      element: <DeviceList />,
    },
    {
      path: 'detail',
      element: <DeviceDetail />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const storageRoutes = {
  path: '/storage',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/storage/list" />,
    },
    {
      path: 'list',
      element: <StorageList />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const channelRoutes = {
  path: '/channel',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/channel/list" />,
    },
    {
      path: 'list',
      element: <ChannelList />,
    },
    {
      path: 'detail',
      element: <ChannelDetail />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const scheduleRoutes = {
  path: '/schedule',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/schedule/list" />,
    },
    {
      path: 'list',
      element: <ScheduleList />,
    },
    {
      path: 'add',
      element: <ScheduleAdd />,
    },
    {
      path: 'edit',
      element: <ScheduleEdit />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const reportRoutes = {
  path: '/report',
  element: <ManagerLayout />,
  children: [
    {
      element: <Report />,
      children: [
        {
          index: true,
          element: <Navigate to="/report/device_all" />,
        },
        {
          path: 'device',
          element: <DeviceLog />,
        },
        {
          path: 'device_all',
          element: <DeviceLogListView />,
        },
        {
          path: 'playlist',
          element: <PlaylistLog />,
        },
        {
          path: 'playlist_all',
          element: <PlaylistLogListView />,
        },
        {
          path: 'channel',
          element: <ChannelLog />,
        },
        {
          path: 'channel_all',
          element: <ChannelLogListView />,
        },
        {
          path: 'media',
          element: <MediaLog />,
        },
        {
          path: 'media_all',
          element: <MediaLogListView />,
        },
      ],
    },
  ],
  errorElement: <ErrorLayout />,
};

const systemRoutes = {
  path: '/system',
  element: <ManagerLayout />,
  children: [
    {
      path: 'setting',
      element: <Setting />,
    },
    {
      path: 'member',
      element: <Setting />,
    },
    {
      path: 'profile',
      element: <Setting />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const boardRoutes = {
  path: '/',
  element: <ManagerLayout />,
  children: [
    {
      path: 'notice/list',
      element: <NoticeList />,
    },
    {
      path: 'notice/detail',
      element: <NoticeDetail />,
    },
    {
      path: 'release/list',
      element: <ReleaseList />,
    },
    {
      path: 'release/detail',
      element: <ReleaseDetail />,
    },
    {
      path: 'qna/list',
      element: <QnaList />,
    },
    {
      path: 'qna/detail',
      element: <QnaDetail />,
    },
    {
      path: 'qna/add',
      element: <QnaAdd />,
    },
    {
      path: 'guide/list',
      element: <GuideList />,
    },
    {
      path: 'guide/detail',
      element: <GuideDetail />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const playlistRoutes = {
  path: '/playlist',
  element: <ManagerLayout />,
  children: [
    {
      index: true,
      element: <PlaylistMain />,
    },
  ],
  errorElement: <ErrorLayout />,
};

const editorRoutes = {
  path: '/editor',
  element: <EditorLayout />,
  errorElement: <ErrorLayout />,
};

// const playerRoutes = {
//   path: '/player',
//   element: <PlayerLayout />,
//   children: [
//     {
//       index: true,
//       element: null,
//     },
//   ],
// };

const noMatchRoutes = {
  path: '*',
  element: <Navigate to="/" />,
  errorElement: <ErrorLayout />,
};

const router = createBrowserRouter([
  rootRoutes,
  openRoutes,
  authRoutes,
  dashboardRoutes,
  reportRoutes,
  systemRoutes,
  boardRoutes,
  deviceRoutes,
  storageRoutes,
  channelRoutes,
  scheduleRoutes,
  playlistRoutes,
  editorRoutes,
  // playerRoutes,
  noMatchRoutes,
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
