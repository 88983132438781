import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';

import { INIT_WEBVIEW_INFO } from '../constants/contentApp';

const WebViewApp = ({
  selectLayerContentsInfo = INIT_WEBVIEW_INFO,
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
}) => {
  const { t } = useTranslation();
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_WEBVIEW_INFO);
  const [formValidate, setFormValidate] = useState({ url: true, urlError: '' });

  const [isUrlOpen, setIsUrlOpen] = useState(true);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const checkValidDate = useCallback(() => {
    let validate = {};
    let validFlag = false;

    if (formData.contentsData.url.trim() === '') {
      validFlag = true;
      validate.url = false;
      validate.urlError = t('manager.editor.layerArea.contents.webview.inputUrl');
      setIsUrlOpen(true);
    } else {
      validate.url = true;
      validate.urlError = '';
    }

    setFormValidate(validate);

    return validFlag;
  }, [t, formData.contentsData.url]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };
      if (!layerContentsInfo.contentsData.url) {
        layerContentsInfo.contentsData.url = '';
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_WEBVIEW_INFO);
    }
  }, [selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.webview.title')} />
      <AppContentWrap>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox
              title={t('manager.editor.layerArea.contents.webview.url')}
              isOpen={isUrlOpen}
              changeOpen={setIsUrlOpen}
            >
              <Input
                placeholder={t('manager.editor.layerArea.contents.webview.urlPlaceHolder')}
                value={formData.contentsData.url || ''}
                onChange={e => handleChangeObject('contentsData', 'url', e.target.value)}
                isValidate={formValidate.url}
              />
              <InputError>{formValidate.urlError}</InputError>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        checkValidDate={checkValidDate}
        cancelApp={handleCancelLayerContents}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

const Input = styled.input`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 6px 17px 6px 12px;
  border: 1px solid ${({ isError }) => (isError ? '#f05b5b' : '#dedede')};
  border-radius: 8px;
  font-size: 13px;
  color: #333333;
  border: 0.0625rem solid
    ${props => (props.isValidate === undefined || props.isValidate === true ? '#DDDDDD' : '#F05B5B')};

  &:focus {
    outline: none;
  }
`;

export default React.memo(WebViewApp);
