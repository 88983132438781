import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import styled from 'styled-components';
import Modal from '../../../newComponents/Modal';
import { useTempListQuery } from '../../../rtk/templateApi';
import classNames from 'classnames';
import Tab from '../../../newComponents/Tab';
import Loading from '../../../newComponents/Loading';

const TemplateModal = ({ closeModal, isOpen, playlistId, length, handleCreateFrame, accountId }) => {
  const { t } = useTranslation();
  const { data: templateList, isLoading } = useTempListQuery();
  const [templateHistoryList, setTemplateHistoryList] = useState([]);
  const [activeTemplate, setActiveTemplate] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const tempModal = useRef(null);
  const firstInit = useRef(false);
  const [loadStart, setLoadStart] = useState(false);

  const tabData = useRef([
    { title: t('manager.editor.modal.template.tab.all'), code: 'all' },
    { title: t('manager.editor.modal.template.tab.col'), code: 'col' },
    { title: t('manager.editor.modal.template.tab.row'), code: 'row' },
  ]);

  useEffect(() => {
    if (templateList?.length > 0 && !firstInit.current) {
      setActiveTemplate([templateList[0]]);
      firstInit.current = true;
    }

    if (isOpen) {
      const history = JSON.parse(localStorage.getItem('layerContents.history' + accountId)) || {};
      const historyList =
        history.templateHistoryList?.length > 0
          ? history.templateHistoryList.sort((a, b) => (a.templateType === 'row' ? 1 : -1))
          : [] || [];
      setTemplateHistoryList(templateHistoryList => historyList);
      if (templateList?.length > 0) {
        setActiveTemplate([templateList[0]]);
      }
      setTimeout(() => setLoadStart(true), 200);
    }
  }, [accountId, isOpen, templateList]);

  const handleSelectTemplate = useCallback(
    (e, template) => {
      if (e.ctrlKey) {
        if (activeTemplate.findIndex(obj => obj.templateId === template.templateId) === -1) {
          setActiveTemplate(activeTemplate => activeTemplate.concat(template));
        } else {
          setActiveTemplate(activeFile => activeTemplate.filter(active => active.templateId !== template.templateId));
        }
      } else {
        setActiveTemplate([template]);
      }
    },
    [activeTemplate],
  );

  return (
    <>
      <StyledModal
        hideButton={false}
        title={
          <Title>
            <span>{t('manager.editor.modal.template.title')}</span>
          </Title>
        }
        onClose={closeModal}
        onBackdropClick={closeModal}
        visible={isOpen}
        height={'90%'}
        isLine
        onConfirm={e => handleCreateFrame(e, 'template', activeTemplate)}
        confirmText={t('manager.editor.modal.template.ok')}
        cancelText={t('manager.editor.modal.template.cancel')}
        bodyRef={tempModal}
        isFooterFix={true}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <ModalContainer>
            <>
              {templateHistoryList?.length > 0 && (
                <HistoryContainer>
                  <HistoryTitle>{t('manager.editor.modal.template.recentUsedTemplate')}</HistoryTitle>
                  {loadStart &&
                    templateHistoryList.map((template, index) => (
                      <Template
                        handleSelectTemplate={handleSelectTemplate}
                        key={'template-' + index}
                        template={template}
                        activeType="notHistory"
                        addTemplate={e => handleCreateFrame(e, 'template', activeTemplate)}
                        activeTemplate={activeTemplate}
                      />
                    ))}
                </HistoryContainer>
              )}

              <Cateogry onClick={e => e.stopPropagation()}>
                <Tab tabIndex={tabIndex} tabList={tabData.current} tabChange={e => setTabIndex(e)} />
              </Cateogry>

              <ModalChildren>
                {loadStart &&
                  templateList
                    ?.filter((item, idx) =>
                      tabData.current[tabIndex]['code'] === 'all'
                        ? true
                        : item.templateType === tabData.current[tabIndex]['code'],
                    )
                    ?.map((template, index) => {
                      return (
                        <Template
                          handleSelectTemplate={handleSelectTemplate}
                          key={'template-' + index}
                          template={template}
                          activeType="notHistory"
                          addTemplate={e => handleCreateFrame(e, 'template', activeTemplate)}
                          activeTemplate={activeTemplate}
                        />
                      );
                    })}
              </ModalChildren>
            </>
          </ModalContainer>
        )}
      </StyledModal>
    </>
  );
};

const Template = React.memo(
  ({ template, handleSelectTemplate, setActiveTemplateInfo, activeType, addTemplate, activeTemplate }) => {
    const [imageReady, setImageReady] = useState(false);
    const imagePath = `${process.env.REACT_APP_INTERACTION_CDN_URL}${template.thumbnailPath}`;

    useEffect(() => {
      const img = new Image();
      img.src = imagePath;
      img.onload = () => {
        setImageReady(true);
      };
    }, [imagePath, template.thumbnailPath]);

    return (
      <Container
        type={template.templateType}
        active={activeTemplate.some(obj => obj.templateId === template.templateId)}
        onClick={e => handleSelectTemplate(e, template)}
        onDoubleClick={() => addTemplate(template)}
        className={classNames({
          item: 'item',
        })}
      >
        <Contents>
          {!imageReady ? <Loading /> : <img src={imagePath} alt="" />}
          <Name>{template.templateNm}</Name>
        </Contents>
      </Container>
    );
  },
);

const StyledModal = styled(Modal)`
  .body {
    padding: 0px;
  }
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3333333;
  font-size: 14px;
  font-weight: 500;

  & > .warn {
    font-size: 12px;
    color: #f05b5b;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  padding: 1em;
`;

const HistoryContainer = styled.fieldset`
  border-top: 1px solid rgb(106 139 255);
  border-bottom: 1px solid rgb(106 139 255);

  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  column-gap: 0rem;
  margin-bottom: 1em;

  .item {
    padding: 10px;
    page-break-inside: avoid;
    height: 12rem;
    padding: 10px;
  }
`;

const HistoryTitle = styled.legend`
  padding: 0px 5px;
  margin-left: 5px;
`;

const Container = styled.div`
  cursor: pointer;
  transition: all 2ms;
  outline: ${props => props.active && '2px solid rgb(200 200 200)'};
  box-shadow: ${props => props.active && '2px 3px 7px 5px #49494930'};
  color: ${props => props.active && ' #2a91df'};
`;

const Cateogry = styled.div`
  width: 100%;
  top: 0;
`;

const Contents = styled.div`
  background: #f6f6f6;
  width: 100%;
  height: calc(100% - 1.75rem);
  border-bottom: 1px solid #dddddd;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const ModalChildren = styled.div`
  width: 100%;
  column-count: 4;
  flex-wrap: wrap;
  column-gap: 0;
  position: relative;
  z-index: 0;
  padding: 0.5rem;

  .item {
    grid-row-end: span 150;
    color: #fff;
    padding: 10px;
    grid-row-end: span 200;
    page-break-inside: avoid;
  }
`;

const Name = styled.div`
  padding: 0.4375rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #666666;
`;

export default React.memo(TemplateModal);
