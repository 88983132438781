import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { placeSelector } from '../../module/placeSlice';
import { contentsAction, contentsSelector } from '../../module/contentsSlice';
import useCheckEditingMode from '../../components/useCheckEditingMode';

import {
  FiGrid as DashboardIcon,
  FiTablet as DeviceIcon,
  FiArchive as StorageIcon,
  FiTv as PlaylistIcon,
  FiCalendar as ScheduleIcon,
  FiLayers as ChannelIcon,
  FiSettings as SettingIcon,
} from 'react-icons/fi';

import { FaChartPie as ReportIcon } from 'react-icons/fa';

const MenuListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const placeInfo = useSelector(placeSelector.placeInfo);
  const placeFileInfo = useSelector(contentsSelector.storagePlaceFileInfo);

  const { editingCheck, LayerContentsWarnModal } = useCheckEditingMode();

  const [storageInfo, setStorageInfo] = useState({
    totalFileSize: 0,
    maxStorageSize: 0,
    storageUnit: '',
  });

  const onMenuClick = useCallback(
    url => {
      navigate(url);
    },
    [navigate],
  );

  const isMenuActive = useCallback(
    url => {
      return location.pathname.substring(0, url.length) === url;
    },
    [location],
  );

  useEffect(() => {
    dispatch(contentsAction.getPlaceFileInfo());
  }, [dispatch, placeInfo.placeId]);

  useEffect(() => {
    if (placeFileInfo.totalFileSize !== undefined) {
      setStorageInfo(storageInfo => ({
        ...storageInfo,
        totalFileSize:
          placeInfo.storageMax / 1000 < 1
            ? Math.round((placeFileInfo.totalFileSize / (1024 * 1024)) * 10) / 10
            : Math.round((placeFileInfo.totalFileSize / (1024 * 1024 * 1024)) * 1000) / 1000,
        maxStorageSize: placeInfo.storageMax / 1000 < 1 ? placeInfo.storageMax : placeInfo.storageMax / 1000,
        storageUnit: placeInfo.storageMax / 1000 < 1 ? `MB` : `GB`,
      }));
    }
  }, [placeFileInfo.totalFileSize, placeInfo.storageMax]);

  if (placeInfo.useYn === 'N') {
    return (
      <>
        <MenuList>
          <MenuItem onClick={e => editingCheck(() => onMenuClick('/dashboard'))} $active={isMenuActive('/dashboard')}>
            <DashboardIcon />
            <span>{t('manager.common.navBar.dashboard')}</span>
          </MenuItem>
        </MenuList>
        <Version>AWS v{process.env.REACT_APP_VERSION}</Version>
      </>
    );
  }
  return (
    <>
      <MenuList>
        <MenuItem onClick={e => editingCheck(() => onMenuClick('/dashboard'))} $active={isMenuActive('/dashboard')}>
          <DashboardIcon />
          <span>{t('manager.common.navBar.dashboard')}</span>
        </MenuItem>
        <MenuItem onClick={e => editingCheck(() => onMenuClick('/device/list'))} $active={isMenuActive('/device')}>
          <DeviceIcon />
          <span>{t('manager.common.navBar.device')}</span>
        </MenuItem>
        <MenuItem onClick={e => editingCheck(() => onMenuClick('/storage'))} $active={isMenuActive('/storage')}>
          <StorageIcon />
          <span>{t('manager.common.navBar.storage')}</span>
        </MenuItem>
        <MenuItem onClick={e => editingCheck(() => onMenuClick('/playlist'))} $active={isMenuActive('/playlist')}>
          <PlaylistIcon />
          <span>{t('manager.common.navBar.playlist')}</span>
        </MenuItem>
        {`${process.env.REACT_APP_ENV}` === 'development' && (
          <MenuItem
            onClick={e => editingCheck(() => onMenuClick('/schedule/list'))}
            $active={isMenuActive('/schedule')}
          >
            <ScheduleIcon />
            <span>{t('manager.common.navBar.schedule')}</span>
          </MenuItem>
        )}
        <MenuItem onClick={e => editingCheck(() => onMenuClick('/channel'))} $active={isMenuActive('/channel')}>
          <ChannelIcon />
          <span>{t('manager.common.navBar.channel')}</span>
        </MenuItem>
        <MenuItem onClick={e => editingCheck(() => onMenuClick('/report'))} $active={isMenuActive('/report')}>
          <ReportIcon />
          <span>{t('manager.common.navBar.report')}</span>
        </MenuItem>
        <MenuItem onClick={e => editingCheck(() => onMenuClick('/system/setting'))} $active={isMenuActive('/system')}>
          <SettingIcon />
          <span>{t('manager.common.navBar.setting')}</span>
        </MenuItem>
      </MenuList>
      <StorageInfo>
        <StorageUsage>
          <StorageUsageText>
            <span>
              {storageInfo.totalFileSize}
              {storageInfo.storageUnit}
              &nbsp;/&nbsp;
              {storageInfo.maxStorageSize}
              {storageInfo.storageUnit}
            </span>
          </StorageUsageText>
          <StorageFreeText>
            <span>
              {t('manager.common.navBar.storageRemain')}&nbsp;
              {Math.round((storageInfo.maxStorageSize - storageInfo.totalFileSize) * 1000) / 1000}
              {storageInfo.storageUnit}
            </span>
          </StorageFreeText>
        </StorageUsage>
        <StorageGauge
          value={storageInfo.maxStorageSize !== 0 && (storageInfo.totalFileSize / storageInfo.maxStorageSize) * 100}
          max="100"
        />
      </StorageInfo>
      <Version>AWS v{process.env.REACT_APP_VERSION}</Version>
      <LayerContentsWarnModal />
    </>
  );
};

const MenuList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const MenuItem = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  color: ${props => (props.$active ? '#60bbff' : '#ffffff')};
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #dddddd;
  }

  & > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

const StorageInfo = styled.div`
  height: 6.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
`;

const StorageUsage = styled.div`
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StorageUsageText = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #999999;

  & > :nth-child(1) {
    font-weight: bold;
  }
`;

const StorageFreeText = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: #999999;
`;

const StorageGauge = styled.progress`
  height: 4px;
  width: 100%;
  border: 0;

  &::-webkit-progress-bar {
    background-color: #c4c4c4;
  }

  &::-webkit-progress-value {
    background-color: #666666 !important;
  }
`;

const Version = styled.div`
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  border-top: 1px solid #666666;
`;

export default React.memo(MenuListContainer);
