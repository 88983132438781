import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import contentsError from '../../../../../assets/images/media/contents-error.svg';
import Modal from '../../../../../newComponents/Modal';

const ContentDragModal = ({
  isOpen,
  closeModal,
  mediaList,
  handleChangeObject,
  contentsFileList,
  setContentsFileList,
}) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const [contentsList, setContentsList] = useState([]);

  const contentDragConfirm = useCallback(() => {
    handleChangeObject('contentsData', 'mediaList', fileList);
    setContentsFileList(contentsList);
    closeModal();
  }, [fileList, handleChangeObject, closeModal, contentsList, setContentsFileList]);

  const onDragFrame = useCallback(
    result => {
      if (!result.destination) return;

      const startIdx = result.source.index;
      const endIdx = result.destination.index;

      let tempFileList = [...fileList];
      let tempContentsList = [...contentsList];
      const [fileRearange] = tempFileList.splice(startIdx, 1);
      const [contentsRearange] = tempContentsList.splice(startIdx, 1);
      tempFileList.splice(endIdx, 0, fileRearange);
      tempContentsList.splice(endIdx, 0, contentsRearange);

      tempFileList = tempFileList.map((tempFile, i) => ({ ...tempFile, fileOrder: i + 1 }));

      setFileList(tempFileList);
      setContentsList(tempContentsList);
    },
    [fileList, contentsList],
  );

  useEffect(() => {
    if (isOpen) {
      setFileList(mediaList);
      setContentsList(contentsFileList);
    }
  }, [isOpen, mediaList, contentsFileList]);

  return (
    <StyledModal
      hideButton={false}
      title={
        <Title>
          <span>{t('manager.editor.layerArea.modal.mediaSort.title')}</span>
        </Title>
      }
      confirmText={t('manager.editor.layerArea.modal.mediaSort.ok')}
      cancelText={t('manager.editor.layerArea.modal.mediaSort.cancel')}
      onConfirm={contentDragConfirm}
      onCancel={closeModal}
      onClose={closeModal}
      onBackdropClick={closeModal}
      height={'80%'}
      size={'large'}
      isLine={true}
      visible={isOpen}
    >
      <ModalContainer>
        <DragDropContext onDragEnd={onDragFrame}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <FileArea {...provided.droppableProps} ref={provided.innerRef}>
                {contentsList.map((contents, index) => (
                  <Draggable key={`file-${index}`} draggableId={`file-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <FileCardArea ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <FileNumber>{index + 1}</FileNumber>
                        {contents.fileThumb === undefined || contents.fileThumb === null ? (
                          <FilePreview
                            src={
                              contents.filePath.includes('blob:')
                                ? contents.filePath
                                : process.env.REACT_APP_INTERACTION_CDN_URL + contents.filePath
                            }
                            alt="preview"
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = contentsError;
                            }}
                          />
                        ) : (
                          <FilePreview
                            src={
                              contents.fileThumb.includes('blob:')
                                ? contents.fileThumb
                                : process.env.REACT_APP_INTERACTION_CDN_URL + contents.fileThumb
                            }
                            alt="preview"
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = contentsError;
                            }}
                          />
                        )}
                        <FileInfoArea>
                          <FileInfoText>{contents.fileNm}</FileInfoText>
                          <FileInfoText>{parseInt(contents.fileSize / 1000).toLocaleString()}KB</FileInfoText>
                        </FileInfoArea>
                      </FileCardArea>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </FileArea>
            )}
          </Droppable>
        </DragDropContext>
      </ModalContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .body {
    flex: 1;
    width: 100%;
    padding: 0px;
  }
`;

const ModalContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FileArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FileCardArea = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  border: solid 1px #999999;
  border-radius: 4px;
  cursor: pointer;

  &.active-row {
    border: 3px solid #2a91df;
  }
`;

const FileNumber = styled.div`
  height: 100%;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilePreview = styled.img`
  width: 80px;
  height: 80px;
  border: 1px solid #888888;
  object-fit: cover;
`;

const FileInfoArea = styled.div`
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FileInfoText = styled.div`
  height: 20px;
  display: flex;
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3333333;
  font-size: 14px;
  font-weight: 500;
`;

export default React.memo(ContentDragModal);
