import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { editorAction, editorSelector } from '../../module/editorSlice';

import {
  endpoints as endpointsOvelayApi,
  useOverlayRemoveListMutation,
  useOverlayReOrderMutation,
  useOverlayUpdateListMutation,
} from '../../rtk/overlayApi';
import { endpoints as endpointsOvelayContentsApi } from '../../rtk/overlayContentsApi';

import useCheckEditingMode from './app/components/modal/useCheckEditingMode';
import useRemoveConfirmModal from './app/components/modal/useRemoveConfirmModal';

import { getLayerContentsIcon } from '../../components/AppIcon';

import { FiMove as MoveIcon } from 'react-icons/fi';
import { ReactComponent as RemoveIcon } from '../../assets/images/common/icon-trash.svg';

const OvelayCardList = ({ historyRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { editingCheck, LayerContentsWarnModal } = useCheckEditingMode();
  const { removeCheck, LayerContentsWarnModal: removeConfirmModal } = useRemoveConfirmModal();

  const playlistId = useSelector(editorSelector.playlistId);
  const selectedLayerList = useSelector(editorSelector.selectedLayerList);
  const { lockMode } = useSelector(editorSelector.canvasInfo);

  const { currentData: overlayList, isError: isOverlayListError } = endpointsOvelayApi.overlayList.useQueryState({
    playlistId,
  });
  const { currentData: overlayContentsList } = endpointsOvelayContentsApi.overlayContentsList.useQueryState({
    playlistId,
  });

  const [reOrderOvelay] = useOverlayReOrderMutation();
  const [overlayRemoveListMutation] = useOverlayRemoveListMutation();
  const [overlayUpdateListMutation] = useOverlayUpdateListMutation();

  const handleClickOvelay = useCallback(
    (e, overlay) => {
      const prevSelectedLayerList = selectedLayerList;
      const nextSelectedLayerList = [{ id: overlay.overlayId, type: 'OVERLAY', ...overlay }];

      const compareArray = function (arr1, arr2) {
        var i = arr1.length;
        if (i !== arr2.length) return false;

        for (const obj of arr1) {
          const id = obj.id;

          if (!arr2.find(obj2 => obj2.id === id)) {
            return false;
          }
        }
        return true;
      };

      const isSameArray = compareArray(prevSelectedLayerList, nextSelectedLayerList);

      if (!isSameArray) {
        if (!selectedLayerList?.some(selectedLayer => selectedLayer.id === overlay.overlayId)) {
          editingCheck(() => {
            historyRef.current.addHistory({
              type: 'SELECT-LAYER',
              props: { prevSelectedLayerList: selectedLayerList, nextSelectedLayerList },
            });
            dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList }));
          });
        } else {
          historyRef.current.addHistory({
            type: 'SELECT-LAYER',
            props: { prevSelectedLayerList: selectedLayerList, nextSelectedLayerList },
          });
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: nextSelectedLayerList }));
        }
      }
    },
    [dispatch, editingCheck, historyRef, selectedLayerList],
  );

  const overlayRemove = useCallback(
    overlay => {
      overlayRemoveListMutation({ removeList: [{ overlayId: overlay.overlayId }] }).then(() => {
        let nextSelectedLayerList = selectedLayerList;

        const index = selectedLayerList.findIndex(selectedLayer => selectedLayer.id === overlay.overlayId);
        if (index > -1) {
          nextSelectedLayerList = [];
        }
        historyRef.current.addHistory({
          type: 'REMOVE-LAYER',
          props: { infos: [overlay], prevSelectedLayerList: selectedLayerList, nextSelectedLayerList },
        });
      });
    },
    [historyRef, overlayRemoveListMutation, selectedLayerList],
  );

  const handleNotifyLockMode = useCallback(() => {
    let highlightTimer = null;

    toast.error(t('manager.editor.toast.unlockForBegin'));

    const lockIconBtnEl = document.getElementsByClassName('lock-icon-btn')[0];

    lockIconBtnEl.classList.add('highlight');

    highlightTimer = setTimeout(() => {
      lockIconBtnEl.classList.remove('highlight');
      clearTimeout(highlightTimer);
    }, 3000);
  }, [t]);

  const handleRemoveOvelay = useCallback(
    overlay => {
      if (lockMode) {
        handleNotifyLockMode();
      } else {
        if (overlayContentsList?.some(overlayContents => overlayContents.overlayId === overlay.overlayId)) {
          removeCheck(() => {
            overlayRemove(overlay);
          });
        } else {
          overlayRemove(overlay);
        }
      }
    },
    [overlayContentsList, lockMode, overlayRemove, removeCheck, handleNotifyLockMode],
  );

  const handleReorderOvelay = useCallback(
    result => {
      if (lockMode) {
        handleNotifyLockMode();
        return;
      }
      if (!result.destination) return;
      let overlayLen = overlayList.length - 1;
      let startIndex = Math.abs(result.source.index - overlayLen);
      let endIndex = Math.abs(result.destination.index - overlayLen);

      let newOvelayList = [...overlayList];
      const [reorderOvelayList] = newOvelayList.splice(startIndex, 1);
      newOvelayList.splice(endIndex, 0, reorderOvelayList);

      const updateList = newOvelayList.reduce((target, newOvelay, index) => {
        if (newOvelay.overlayOrder !== index) {
          target.push({
            overlayId: newOvelay.overlayId,
            overlayOrder: index,
          });
        }
        return target;
      }, []);

      if (updateList.length > 0) {
        reOrderOvelay({ updateList });
      }
    },
    [reOrderOvelay, overlayList, lockMode, handleNotifyLockMode],
  );

  return (
    <Wrap>
      {overlayList && !isOverlayListError ? (
        <>
          {overlayList.length > 0 ? (
            <DragDropContext onDragEnd={handleReorderOvelay}>
              <Droppable droppableId="overlayList">
                {provided => (
                  <ListContainer {...provided.droppableProps} ref={provided.innerRef}>
                    {overlayList
                      .slice(0)
                      .reverse()
                      .map((overlay, index) => (
                        <Draggable key={overlay.overlayId} draggableId={overlay.overlayId} index={index}>
                          {provided => (
                            <Card
                              ref={provided.innerRef}
                              onClick={e => handleClickOvelay(e, overlay)}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <Ovelay
                                index={index}
                                overlayInfo={overlay}
                                contentsType={
                                  overlayContentsList?.find(contents => contents.overlayId === overlay.overlayId)
                                    ?.contentsType || undefined
                                }
                                handleRemoveOvelay={handleRemoveOvelay}
                                overlayUpdateListMutation={overlayUpdateListMutation}
                                isSelect={selectedLayerList?.some(
                                  selectedLayer => selectedLayer.id === overlay.overlayId,
                                )}
                                lockMode={lockMode}
                              />
                            </Card>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </ListContainer>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <NoOverlay>{t('manager.editor.layerArea.overlay.notExist')}</NoOverlay>
          )}
          {LayerContentsWarnModal()}
          {removeConfirmModal()}
        </>
      ) : (
        <></>
      )}
    </Wrap>
  );
};

const Ovelay = React.memo(
  ({ index, overlayInfo, contentsType, handleRemoveOvelay, overlayUpdateListMutation, isSelect, lockMode }) => {
    const { t } = useTranslation();
    const nameRef = useRef(null);

    const [value, setValue] = useState(overlayInfo.overlayNm || '');

    useEffect(() => {
      setValue(value => overlayInfo.overlayNm || '');
    }, [overlayInfo.overlayNm]);

    useEffect(() => {
      if (!isSelect) {
        nameRef.current.blur();
      }
    }, [isSelect]);

    return (
      <>
        <Order>{index + 1}</Order>
        <Box isSelect={isSelect}>
          {overlayInfo.baseYn === 'Y' && (
            <BaseOvelay isSelect={isSelect}>{t('manager.editor.layerArea.overlay.baseYn')}</BaseOvelay>
          )}
          <Icon>{getLayerContentsIcon(contentsType)}</Icon>
          <OvelayNm
            ref={nameRef}
            value={value}
            disabled={lockMode || !isSelect}
            placeholder={t('manager.editor.layerArea.overlay.namePlaceHolder')}
            onKeyUp={e => e.keyCode === 13 && e.target.blur()}
            onChange={e => setValue(e.target.value)}
            onFocus={e => e.target.select()}
            onBlur={e =>
              overlayUpdateListMutation({
                updateList: [{ overlayId: overlayInfo.overlayId, updateInfo: { overlayNm: e.target.value } }],
              })
            }
          />
          <RemoveIcon
            onClick={e => {
              e.stopPropagation();
              handleRemoveOvelay(overlayInfo);
            }}
          />
          <MoveIcon className="move" color="#555" cursor="grap" />
        </Box>
      </>
    );
  },
);

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  padding: 10px 15px 20px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  flex: 1;
`;

const BaseOvelay = styled.div`
  width: 30px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  background-color: white;
  border-radius: 0px 0px 74px 0px / 8px 148px 78px 0px;
  box-shadow: 1px 0px 3px #85858578;

  color: ${({ isSelect }) => (isSelect ? '#41A1EA' : '#333')};
`;

const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  height: 100%;
  font-size: 12px;
  color: #333;
  text-align: center;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  min-height: 50px;
  gap: 10px;
  cursor: default !important;
  .move {
    cursor: grab !important;
  }
`;

const Box = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  height: 100%;
  border: 1px solid ${({ isSelect }) => (isSelect ? '#41A1EA' : '#dddddd')};
  border-radius: 8px;
  padding: 10px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const OvelayNm = styled.input`
  flex: 1;
  border: none;
  height: 100%;
  border-radius: 6px;

  &:focus {
    outline-color: #dddddd;
  }

  &::placeholder {
    font-size: 11px;
    color: #999;
  }
`;

const NoOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #666666;
`;

export default React.memo(OvelayCardList);
