import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { editorAction, editorSelector } from 'module/editorSlice';

import { BsCursor as CursorIcon } from 'react-icons/bs';
import {
  BiLayerPlus as LayerAddIcon,
  BiSolidLayerPlus as OverlayAddIcon,
  BiUndo as UndoIcon,
  BiRedo as RedoIcon,
} from 'react-icons/bi';
import { ReactComponent as FrameLockIcon } from 'assets/images/layer/icon-frame-lock.svg';
import { ReactComponent as FrameUnlockIcon } from 'assets/images/layer/icon-frame-unlock.svg';
import { ReactComponent as DisableMoveIcon } from 'assets/images/layer/icon-disable-move.svg';

const LayerTool = ({ infiniteViewerRef, historyRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canvasInfo = useSelector(editorSelector.canvasInfo);
  const undoHistoryLength = useSelector(editorSelector.undoHistoryLength);
  const redoHistoryLength = useSelector(editorSelector.redoHistoryLength);

  const handleToggleLayerToolState = key => {
    dispatch(editorAction.updateCanvasInfo({ [key]: !canvasInfo[key] }));
  };

  const handleClickCursorType = key => {
    dispatch(editorAction.updateCanvasInfo({ cursorType: key }));
  };

  const handleUndo = () => {
    historyRef.current.undoHistory();
  };

  const handleRedo = () => {
    historyRef.current.redoHistory();
  };

  return (
    <Component>
      <IconButton
        isActive={canvasInfo.lockMode}
        onClick={() => handleToggleLayerToolState('lockMode')}
        data-tooltip-id="layerToolEdit"
        data-tooltip-html={canvasInfo.lockMode ? t('manager.editor.tool.unlock') : t('manager.editor.tool.lock')}
        className="lock-icon-btn"
      >
        {canvasInfo.lockMode ? <FrameLockIcon /> : <FrameUnlockIcon />}
      </IconButton>
      <IconButton
        isActive={canvasInfo.cursorType === 'LAYER-ADD'}
        onClick={() => handleClickCursorType('LAYER-ADD')}
        data-tooltip-id="layerToolEdit"
        data-tooltip-html={t('manager.editor.tool.layerAdd')}
      >
        <LayerAddIcon color="#fff" size={24} />
      </IconButton>
      <IconButton
        isActive={canvasInfo.cursorType === 'OVERLAY-ADD'}
        onClick={() => handleClickCursorType('OVERLAY-ADD')}
        data-tooltip-id="layerToolEdit"
        data-tooltip-html={t('manager.editor.tool.overlayAdd')}
      >
        <OverlayAddIcon color="#fff" size={24} />
      </IconButton>
      <IconButton
        onClick={() => handleUndo()}
        data-tooltip-id="layerToolEdit"
        data-tooltip-html={t('manager.editor.tool.undo')}
      >
        {undoHistoryLength > 0 ? <UndoIcon color="#fff" size={24} /> : <UndoIcon color="#888" size={24} />}
      </IconButton>
      <IconButton
        onClick={() => handleRedo()}
        data-tooltip-id="layerToolEdit"
        data-tooltip-html={t('manager.editor.tool.redo')}
      >
        {redoHistoryLength > 0 ? <RedoIcon color="#fff" size={24} /> : <RedoIcon color="#888" size={24} />}
      </IconButton>
      <IconButton
        isActive={canvasInfo.cursorType === 'SELECT'}
        onClick={() => handleClickCursorType('SELECT')}
        data-tooltip-id="layerToolEdit"
        data-tooltip-html={t('manager.editor.tool.layerSelect')}
      >
        <CursorIcon color="#fff" size={24} />
      </IconButton>
      <IconButton
        isActive={canvasInfo.cursorType === 'DRAG'}
        style={{ paddingRight: '3px' }}
        onClick={() => handleClickCursorType('DRAG')}
        data-tooltip-id="layerToolEdit"
        data-tooltip-html={t('manager.editor.tool.areaMove')}
      >
        <DisableMoveIcon />
      </IconButton>
      <Tooltip id="layerToolEdit" place="right" type="dark" effect="solid" multiline={true} />
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 40px;

  background-color: inherit;
`;

const IconButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  padding: 0 5px;
  height: 100%;

  background-color: ${({ isActive }) => (isActive ? '#41a1ea' : 'inherit')};

  & .lock-icon,
  .unlock-icon,
  .disable-move-icon,
  .plus-icon,
  .zoom-in-icon,
  .zoom-out-icon {
    fill: #fff;
  }

  .fit-icon {
    stroke: #fff;
  }

  &.highlight {
    animation: highlightEffect 1s infinite;
  }

  @keyframes highlightEffect {
    from {
      background-color: #fafafa;
    }
    50% {
      background-color: #e74c3c;
    }
    to {
      background-color: #fafafa;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export default React.memo(LayerTool);
