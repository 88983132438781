import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { previewToken } from '../../lib/playlistApi';
import { accountSelector } from '../../module/accountSlice';
import { editorSelector } from '../../module/editorSlice';

import { FiPlay as PlayIcon } from 'react-icons/fi';

const Preview = () => {
  const { t } = useTranslation();

  const placeId = useSelector(editorSelector.placeId);
  const playlistId = useSelector(editorSelector.playlistId);
  const { accountId } = useSelector(accountSelector.accountInfo);

  const openViewer = () => {
    previewToken(accountId, placeId, 'preview', playlistId).then(token => {
      if (window.location.href.includes('eligaspace.com')) {
        window.open('https://player.eligaspace.com/agent?token=' + token);
      } else if (window.location.href.includes('eliga.co.kr')) {
        window.open('https://player.eligaspace.com/agent?token=' + token);
      } else if (window.location.href.includes('eligaws.com')) {
        window.open('https://player.eligaws.com/agent?token=' + token);
      } else {
        window.open('http://localhost:3002/agent?token=' + token);
      }
    });
  };

  return (
    <Container
      onClick={() => openViewer()}
      data-tooltip-id="editorTopBar"
      data-tooltip-content={t('manager.editor.tool.preview')}
    >
      <PlayIcon color="#fff" size={13} />
      <Tooltip id="editorTopBar" place="left" type="dark" effect="solid" multiline={true} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
  flex-basis: 35px;

  &:hover {
    background: #222;
  }
`;

export default React.memo(Preview);
