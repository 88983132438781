import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import LayerProperty from './LayerProperty';
import LayerContentsApp from './LayerContentsApp';
import SettingTab from './SettingTab';

import { INIT_SELECTED_LAYERCONTENTS_INFO } from './constants/contentApp';

import { editorSelector } from '../../module/editorSlice';
import useCheckEditingMode from './app/components/modal/useCheckEditingMode';

import { endpoints as endpointsLayerApi } from '../../rtk/layerApi';
import { endpoints as endpointsOverlayApi } from '../../rtk/overlayApi';

import { endpoints as endpointsLayerContentsApi } from '../../rtk/layerContentsApi';
import { endpoints as endpointsOvelayContentsApi } from '../../rtk/overlayContentsApi';

const LayerSetting = ({ moveableRef, historyRef }) => {
  const { t } = useTranslation();

  const selectedLayerList = useSelector(editorSelector.selectedLayerList);
  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.frameId);

  const { editingCheck, LayerContentsWarnModal } = useCheckEditingMode();

  const { currentData: layerList } = endpointsLayerApi.layerList.useQueryState({ frameId });
  const { currentData: overlayList } = endpointsOverlayApi.overlayList.useQueryState({ playlistId });

  const { currentData: layerContentList } = endpointsLayerContentsApi.layerContentsList.useQueryState({
    frameId: frameId,
  });
  const { currentData: overlayContentsList } = endpointsOvelayContentsApi.overlayContentsList.useQueryState({
    playlistId,
  });

  const [selectedLayerInfo, setSelectedLayerInfo] = useState();
  const [tabIndex, setTabIndex] = useState(0);

  const tabClick = useCallback(
    index => {
      if (index !== 1) {
        editingCheck(() => setTabIndex(index));
      } else {
        setTabIndex(index);
      }
    },
    [editingCheck, setTabIndex],
  );

  const [selectedLayerContentsInfo, setSelectedLayerContentsInfo] = useState(INIT_SELECTED_LAYERCONTENTS_INFO);

  useEffect(() => {
    if (layerList && overlayList) {
      if (selectedLayerList?.length === 1) {
        const { id, type, x, y, width, height } = selectedLayerList[0];

        if (type === 'LAYER') {
          const layer = layerList.find(layer => layer.layerId === id);
          if (layer) {
            setSelectedLayerInfo({ id, type, ...layer, x, y, width, height });
          }
        }

        if (type === 'OVERLAY') {
          const overlay = overlayList.find(layer => layer.overlayId === id);
          if (overlay) {
            setSelectedLayerInfo({ id, type, ...overlay, x, y, width, height });
          }
        }
      } else {
        setSelectedLayerInfo();
      }
    } else {
      setSelectedLayerInfo();
    }
  }, [selectedLayerList, layerList, overlayList]);

  useEffect(() => {
    if (selectedLayerInfo) {
      let selectedLayerFlag = false;
      if (selectedLayerInfo.id) {
        let selectLayerContents = undefined;
        if (selectedLayerInfo.type === 'OVERLAY') {
          selectLayerContents = overlayContentsList.find(
            overlayContent => overlayContent.overlayId === selectedLayerInfo.id,
          );
        } else {
          selectLayerContents = layerContentList?.find(layerContent => layerContent.layerId === selectedLayerInfo.id);
        }

        if (selectLayerContents) {
          setSelectedLayerContentsInfo({ ...selectLayerContents });
          selectedLayerFlag = true;
        }
      }

      if (!selectedLayerFlag) {
        let initLayerContents = { ...INIT_SELECTED_LAYERCONTENTS_INFO, playlistId: playlistId };
        if (selectedLayerInfo.type === 'OVERLAY') {
          initLayerContents.overlayId = selectedLayerInfo.id;
        } else {
          initLayerContents.layerId = selectedLayerInfo.id;
          initLayerContents.frameId = frameId;
        }

        setSelectedLayerContentsInfo(initLayerContents);
      }
    }
  }, [frameId, layerContentList, overlayContentsList, playlistId, selectedLayerInfo]);

  const tabList = useRef([
    { id: 'propertyLayer', title: t('manager.editor.layerArea.property.title') },
    {
      id: 'layerContentsApp',
      title: t('manager.editor.layerArea.contents.title'),
    },
  ]);

  return (
    <Component>
      {selectedLayerInfo && (
        <>
          <SettingTab tabIndex={tabIndex} tabList={tabList.current} tabChange={tabClick} />
          <Body>
            {
              {
                0: (
                  <LayerProperty
                    moveableRef={moveableRef}
                    historyRef={historyRef}
                    selectedLayerInfo={selectedLayerInfo}
                  />
                ),
                1: (
                  <LayerContentsApp
                    selectedLayerId={selectedLayerInfo.id}
                    selectedLayerType={selectedLayerInfo.type}
                    selectedLayerContentsInfo={selectedLayerContentsInfo}
                  />
                ),
              }[tabIndex]
            }
          </Body>
        </>
      )}
      {LayerContentsWarnModal()}
    </Component>
  );
};

const Component = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export default React.memo(LayerSetting);
