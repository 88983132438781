import { useEffect, useCallback, useState } from 'react';

import { editorAction, editorSelector } from '../../../../module/editorSlice';
import { useDispatch, useSelector } from 'react-redux';

import { produce } from 'immer';

const useForm = ({ initialValues }) => {
  const dispatch = useDispatch();
  const editingMode = useSelector(editorSelector.editingMode);

  const [formData, setFormData] = useState(initialValues);

  const initValuesJsonString = JSON.stringify(initialValues);

  useEffect(() => {
    const parseInitValues = JSON.parse(initValuesJsonString);
    setFormData(formData => parseInitValues);
  }, [initValuesJsonString]);

  const editingModeUpdate = useCallback(() => {
    if (!editingMode) {
      dispatch(editorAction.editingModeLayerContents(true));
    }
  }, [dispatch, editingMode]);

  const handleChange = useCallback(
    (key, value) => {
      setFormData(formData => ({ ...formData, [key]: value }));
      editingModeUpdate();
    },
    [editingModeUpdate],
  );

  const handleChangeObject = useCallback(
    (objectKey, subKey, value) => {
      let changeObject = { ...formData[objectKey] };
      changeObject[subKey] = value;

      setFormData(formData => ({
        ...formData,
        [objectKey]: changeObject,
      }));
      editingModeUpdate();
    },
    [formData, editingModeUpdate],
  );

  const handleChangeList = useCallback(
    (mainKey, index, subKey, value) => {
      setFormData(formData => ({
        ...formData,
        [mainKey]: formData[mainKey].map((item, idx) =>
          idx === index
            ? {
                ...item,
                [subKey]: value,
              }
            : item,
        ),
      }));
      editingModeUpdate();
    },
    [editingModeUpdate],
  );

  const handleAddList = useCallback(
    (mainKey, value) => {
      setFormData(formData =>
        produce(formData, draft => {
          draft[mainKey].push(value);
        }),
      );
      editingModeUpdate();
    },
    [editingModeUpdate],
  );

  const handleRemoveList = useCallback(
    (key, index) => {
      setFormData(formData => ({ ...formData, [key]: formData[key].filter((data, idx) => idx !== index) }));
      editingModeUpdate();
    },
    [editingModeUpdate],
  );

  return {
    formData,
    handleChangeObject,
    handleAddList,
    handleChange,
    handleChangeList,
    handleRemoveList,
  };
};

export default useForm;
