import React, { useRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useChannelListQuery } from 'rtk/channelApi';

import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import AppButtonArea from './components/AppButtonArea';
import SelectInput from '../../../newComponents/SelectInput';
import Loading from '../../../newComponents/Loading';
import { INIT_CHANNEL_INFO } from '../constants/contentApp';
import RemoveConfirmModal from './components/modal/RemoveConfirmModal';
import { editorAction, editorSelector } from '../../../module/editorSlice';
import { AppContentWrap } from './components/ContentBox';
import SquareButton from 'components/SquareButton';
import { changeFromSecondToTime } from 'utils/time';

import contentsError from '../../../assets/images/media/contents-error.svg';
import { FiImage, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const ChannelApp = ({
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
  selectLayerContentsInfo = INIT_CHANNEL_INFO,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const placeId = useSelector(editorSelector.placeId);
  const editingMode = useSelector(editorSelector.editingMode);
  const { data: channelList, isSuccess: isChannelListSuccess } = useChannelListQuery({ placeId });
  const channelContentsList = useSelector(editorSelector.channelContentsList);

  const [addFileList, setAddFileList] = useState([]);
  const [isRemoveLayerContentsConfirmModal] = useState(false);
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_CHANNEL_INFO);
  const [activeFileIndex, setActiveFileIndex] = useState(0);
  const [contentsFileList, setContentsFileList] = useState([]);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });
  const optionRef = useRef([]);

  const handleChangeChannelOption = useCallback(
    (key, value) => {
      handleChangeObject('contentsData', 'channelId', value);
    },
    [handleChangeObject],
  );

  useEffect(() => {
    if (isChannelListSuccess) {
      optionRef.current = channelList.map(item => {
        return { value: item.channelId, title: item.channelNm };
      });
    }
  }, [channelList, isChannelListSuccess]);

  useEffect(() => {
    if (!editingMode) {
      if (selectLayerContentsInfo) {
        let layerContentsInfo = { ...selectLayerContentsInfo };

        if (!layerContentsInfo?.contentsData?.channelId) {
          layerContentsInfo.contentsData.channelId = channelList?.[0]?.channelId;
        }
        setLayerContentsInfo(layerContentsInfo);
      } else {
        setLayerContentsInfo(INIT_CHANNEL_INFO);
      }
      dispatch(editorAction.editingModeLayerContents(false));
      setAddFileList([]);
      setActiveFileIndex(0);
    }
  }, [selectLayerContentsInfo, dispatch, editingMode, channelList]);

  useEffect(() => {
    if (!channelContentsList) return;

    if (Object.keys(channelContentsList).length > 0 && formData.contentsData.channelId) {
      if (channelContentsList[formData.contentsData.channelId]) {
        setContentsFileList(channelContentsList[formData.contentsData.channelId]);
      } else {
        setContentsFileList([]);
      }
    } else {
      setContentsFileList([]);
    }
  }, [channelContentsList, formData.contentsData.channelId, layerContentsInfo.contentsData.channelId]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.channel.title')} />
      <AppContentWrap>
        {appLoading ? (
          <Loading />
        ) : (
          <ContentBox>
            <Box>
              <LeftArea>
                <Title>{t('manager.editor.layerArea.contents.channel.select')}</Title>
              </LeftArea>
              <RightArea>
                <SelectInput
                  selectedValue={formData.contentsData.channelId}
                  optionList={optionRef.current}
                  onSelectChange={value => handleChangeChannelOption('contentsData', value)}
                />
              </RightArea>
            </Box>
            <Box>
              <RightArea>
                <FilePreviewArea>
                  {contentsFileList[activeFileIndex]?.fileData?.filePath === null ? (
                    <img
                      style={{ position: 'relative', width: '100%', height: '236px', objectFit: 'contain' }}
                      src={contentsError}
                      alt="preview"
                    ></img>
                  ) : contentsFileList[activeFileIndex]?.fileData?.fileType.includes('image') ? (
                    <img
                      style={{ position: 'relative', width: '100%', height: '236px', objectFit: 'contain' }}
                      src={
                        contentsFileList[activeFileIndex]?.fileData?.filePath.includes('blob:')
                          ? contentsFileList[activeFileIndex]?.fileData.filePath
                          : process.env.REACT_APP_INTERACTION_CDN_URL +
                            contentsFileList[activeFileIndex]?.fileData.filePath
                      }
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = contentsError;
                      }}
                      alt="preview"
                    ></img>
                  ) : (
                    <video
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '236px',
                        objectFit: 'contain',
                        outline: 'none',
                      }}
                      preload="auto"
                      controls
                      muted
                      src={
                        process.env.REACT_APP_INTERACTION_CDN_URL + contentsFileList[activeFileIndex]?.fileData.filePath
                      }
                    ></video>
                  )}
                  <FileInfoArea>
                    <FiImage size="19px" style={{ marginRight: '10px' }} />
                    <FileInfoText>{contentsFileList[activeFileIndex]?.fileData.fileNm || ''}</FileInfoText>
                  </FileInfoArea>
                </FilePreviewArea>
                <div style={{ display: 'flex' }}>
                  <StyledSquareButton
                    isLastItem={activeFileIndex === 0 ? true : false}
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      if (activeFileIndex !== 0) {
                        setActiveFileIndex(activeFileIndex - 1);
                      }
                    }}
                  >
                    <FiChevronLeft size="20px" color={'#999999'} />
                  </StyledSquareButton>
                  <StyledSquareButton
                    isLastItem={activeFileIndex >= contentsFileList?.length - 1 ? true : false}
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      if (activeFileIndex < contentsFileList?.length - 1) {
                        setActiveFileIndex(activeFileIndex + 1);
                      }
                    }}
                  >
                    <FiChevronRight size="20px" color={'#999999'} />
                  </StyledSquareButton>
                </div>

                <ContentsTable>
                  <thead>
                    <tr>
                      <th>{t('manager.editor.layerArea.contents.channel.no')}</th>
                      <th>{t('manager.editor.layerArea.contents.channel.contents')}</th>
                      <th>{t('manager.editor.layerArea.contents.channel.time')}</th>
                    </tr>
                  </thead>
                  {contentsFileList &&
                    contentsFileList?.map((item, index) => {
                      let { hour, minute, second } = changeFromSecondToTime(item.contentsDuration);
                      return (
                        <Tr
                          key={`contents-${index}`}
                          isActive={contentsFileList[activeFileIndex]?.contentsId === item.contentsId}
                        >
                          <td>{item.contentsOrder}</td>
                          <td>{item.contentsNm}</td>
                          <td>{`${hour}:${minute}:${second}`}</td>
                        </Tr>
                      );
                    })}
                </ContentsTable>
              </RightArea>
            </Box>
          </ContentBox>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        addFileList={addFileList}
        cancelApp={handleCancelLayerContents}
        selectedLayerType={selectedLayerType}
      />
      <RemoveConfirmModal
        contentsId={formData.contentsId}
        isOpen={isRemoveLayerContentsConfirmModal}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const Tr = styled.tr`
  background: ${({ isActive }) => (isActive ? 'rgb(187 187 187 / 40%)' : 'none')};
`;

const ContentsTable = styled.table`
  width: 100%;
  margin-top: 10px;
  padding: 0px 3px;
  text-align: center;
  tr th,
  tr td {
    padding: 6px 6px;
  }

  th {
    background-color: rgb(123 123 123);
    color: white;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 80%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
`;

const RightArea = styled.div`
  flex: 3;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const FilePreviewArea = styled.div`
  position: relative;
  height: 238px;
  border: solid 1px #dddddd;
  margin-bottom: 10px;
`;

const StyledSquareButton = styled(SquareButton)`
  display: flex;
  width: ${({ dragModal }) => (dragModal ? '170px' : '3.75rem')};
  height: 40px;
  border-radius: 6px;
  border: solid 1px #cfcfcf;
  color: #999999;
  background-color: ${({ isLastItem }) => (isLastItem ? '#f1f1f1' : '#ffffff')};
  padding: 4px 6px;
  float: right;
  align-items: center;
  justify-content: center;
`;

const FileInfoArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  font-size: 13px;
  color: #ffffff;
  background-color: #313131;
  justify-content: space-between;
  opacity: 0.7;
  padding: 11px 18px;
`;

const FileInfoText = styled.div`
  max-width: 290px;
  padding-top: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex: 1;
  height: 100%;
`;

const Title = styled.div`
  width: 100%;
  font-size: 14px;
`;

export default React.memo(ChannelApp);
