import React, { useCallback, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import Loading from '../../../newComponents/Loading';
import { MdClose as RemoveIcon } from 'react-icons/md';
import { useState } from 'react';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useRef } from 'react';
import {
  endpoints as endpointsTouchFrameApi,
  useTouchFrameAddMutation,
  useTouchFrameRemoveMutation,
  useTouchFrameReOrderMutation,
  useTouchFrameUpdateMutation,
} from '../../../rtk/touchFrameApi';
import { useTouchLayerAddMutation } from '../../../rtk/touchLayerApi';
import { useDispatch, useSelector } from 'react-redux';
import { editorAction, editorSelector } from '../../../module/editorSlice';

import dayjs from 'dayjs';
import { v4 } from 'uuid';
import throttle from 'lodash/throttle';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import ContextMenuWrapper from '../../../newComponents/ContextMenuWrapper';
import ConfirmModal from '../../../newComponents/ConfirmModal';

import { MdOutlineLibraryAdd as AddFrameIcon } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { useMemo } from 'react';

const TouchModalFrameList = ({ setIsDrag }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const playlistId = useSelector(editorSelector.playlistId);
  const touchThumbNail = useSelector(editorSelector.touchThumbnail);

  const { currentData: touchFrameList } = endpointsTouchFrameApi.touchFrameList.useQueryState({ playlistId });
  const [touchFrameAdd, { isLoading: isFrameAddLoading }] = useTouchFrameAddMutation();
  const [touchFrameReorder] = useTouchFrameReOrderMutation();
  const [addTouchLayer, { isLoading: isLayerAddLoading }] = useTouchLayerAddMutation();
  const [touchFrameRemove] = useTouchFrameRemoveMutation();

  const [isEdit, setIsEdit] = useState(false);
  const [currentFrameId, setCurrentFrame] = useState();
  const [isContextVisible, setIsContextVisible] = useState(true);

  const contextMenuRef = useRef(null);
  const firstInit = useRef(true);
  const TouchFrameScroll = useRef(null);

  const initModalInfo = useRef({
    removeModalInfo: {
      isOpen: false,
      offset: {
        left: '',
        top: '',
      },
    },
  });
  const [removeModalInfo, setRemoveModalInfo] = useState(initModalInfo.current.removeModalInfo);

  const handleClickFrame = useCallback(
    frameId => {
      setCurrentFrame(frameId);
      dispatch(editorAction.setState({ key: 'touchFrameId', value: frameId }));
      if (isEdit === frameId || isEdit.length > 0) {
        setIsEdit('');
      }
    },
    [dispatch, isEdit],
  );

  useEffect(() => {
    if (firstInit.current && touchFrameList?.length > 0) {
      handleClickFrame(touchFrameList?.[0].frameId);
      firstInit.current = false;
    }
  }, [handleClickFrame, touchFrameList]);

  const addTouchFrameThrottle = useMemo(
    () =>
      throttle(({ initFrame, initLayer }) => {
        touchFrameAdd(initFrame).then(() => {
          addTouchLayer(initLayer).then(async () => {
            await TouchFrameScroll.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          });
        });
        handleClickFrame(initFrame.frameId);
      }, 300),
    [touchFrameAdd, handleClickFrame, addTouchLayer],
  );

  const handleCreateTouchFrame = useCallback(
    e => {
      const frameId = dayjs().unix() + v4().substr(0, 8);
      const initFrame = {
        frameId: frameId,
        frameNm: t('manager.editor.modal.touch.frame.touchName') + (touchFrameList?.length + 1),
        frameOrder: touchFrameList?.length,
        playlistId: playlistId,
        angle: 0,
      };

      const initLayer = {
        playlistId,
        frameId,
      };

      addTouchFrameThrottle({ initFrame, initLayer });
    },
    [t, addTouchFrameThrottle, playlistId, touchFrameList?.length],
  );

  const handleDeleteFrame = useCallback((e, frameId) => {
    const { innerHeight: height } = window;

    let top = e.pageY + 8;
    let left = e.pageX + 20;

    const isOveflow = top + 200;

    if (isOveflow > height) {
      top = height - 200;
    }
    setRemoveModalInfo(removeModalInfo => ({
      ...removeModalInfo,
      isOpen: true,
      offset: {
        left: left,
        top: top,
      },
    }));
  }, []);

  const removeTouchFrame = useCallback(
    frameId => {
      touchFrameRemove({ frameId: currentFrameId }).then(res => {
        const frameResponse = res.data.frameList;
        if (frameResponse?.length > 0) {
          handleClickFrame(frameResponse?.[0].frameId);
        } else if (frameResponse.length === 0) {
          handleClickFrame('');
        }
      });
      handleClickFrame(frameId);

      setRemoveModalInfo(removeModalInfo => ({
        ...removeModalInfo,
        isOpen: false,
      }));
    },
    [currentFrameId, handleClickFrame, touchFrameRemove],
  );

  const closeTouchFrame = useCallback(
    frameId => {
      if (removeModalInfo.isOpen) {
        setRemoveModalInfo(removeModalInfo => ({
          ...removeModalInfo,
          isOpen: false,
        }));
      }
    },
    [removeModalInfo.isOpen],
  );

  const handleFrameReOrder = useCallback(
    (st_Idx, ed_Idx) => {
      let newFrameListData = [...touchFrameList];

      let startIdx = st_Idx;
      let endIdx = ed_Idx;

      const [rearange] = newFrameListData.splice(startIdx, 1);
      newFrameListData.splice(endIdx, 0, rearange);

      const updateList = newFrameListData.reduce((target, newFrame, index) => {
        if (newFrame.frameOrder !== index) {
          target.push({
            frameId: newFrame.frameId,
            frameOrder: index,
          });
        }
        return target;
      }, []);

      if (updateList.length > 0) {
        touchFrameReorder({ updateList });
      }
    },
    [touchFrameList, touchFrameReorder],
  );

  const onDragTouchFrame = useCallback(
    result => {
      if (!result.destination) return;
      const startIdx = result.source.index;
      const endIdx = result.destination.index;
      handleFrameReOrder(startIdx, endIdx);

      setIsDrag(false);
    },
    [handleFrameReOrder, setIsDrag],
  );

  const onContextMenu = useCallback(
    (e, frame) => {
      if (removeModalInfo.isOpen) {
        setRemoveModalInfo(removeModalInfo => ({
          ...removeModalInfo,
          isOpen: false,
        }));
      }

      if (typeof frame !== 'undefined') {
        handleClickFrame(frame);
        setIsContextVisible(isContextVisible => true);
      } else {
        e.preventDefault();
      }
    },
    [handleClickFrame, removeModalInfo.isOpen],
  );

  const handleContextMenu = useCallback(
    type => {
      let startIdx = touchFrameList.find(frame => frame.frameId === currentFrameId).frameOrder;
      let endIdx = type === 'front' ? 0 : touchFrameList.length;
      handleFrameReOrder(startIdx, endIdx);
      TouchFrameScroll.current.scrollIntoView({ behavior: 'smooth', block: type === 'front' ? 'start' : 'end' });
    },
    [currentFrameId, handleFrameReOrder, touchFrameList],
  );

  return (
    <>
      <FrameManageContainer>
        <IconButton
          onClick={e => handleCreateTouchFrame(e, 'add')}
          data-tooltip-content={t('manager.editor.modal.touch.frame.add')}
          data-tooltip-id="frameTool"
        >
          <AddFrameIcon size={24} />
        </IconButton>
      </FrameManageContainer>
      <Container
        onClick={e => contextMenuRef.current.handleHide(e)}
        onContextMenu={e => {
          e.preventDefault();
        }}
      >
        <ContextMenuTrigger id="touchFrameContextMenu" holdToDisplay={1000}>
          <ParentContainer ref={TouchFrameScroll}>
            <DragDropContext
              onDragEnd={e => {
                onDragTouchFrame(e);
                setIsDrag(false);
              }}
              onDragStart={e => setIsDrag(true)}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <WrapItem {...provided.droppableProps} ref={provided.innerRef}>
                    {touchFrameList?.map((item, index) => (
                      <Draggable key={item.frameId} draggableId={item.frameId} index={index}>
                        {(provided, snapshot) => {
                          return (
                            <>
                              <Item
                                onContextMenu={e => onContextMenu(e, item.frameId)}
                                onClick={e => handleClickFrame(item.frameId)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TouchFrame
                                  className={classNames({
                                    active: currentFrameId === item.frameId,
                                  })}
                                  frame={item}
                                  isLoading={false}
                                  handleDeleteFrame={handleDeleteFrame}
                                  setIsEdit={setIsEdit}
                                  isEdit={isEdit === item.frameId}
                                  removeModalInfo={removeModalInfo}
                                  thumbnail={item.frameId === touchThumbNail.thumbId ? touchThumbNail : null}
                                />
                              </Item>
                            </>
                          );
                        }}
                      </Draggable>
                    ))}

                    {(isFrameAddLoading || isLayerAddLoading) && (
                      <Item>
                        <TouchFrame isLoading={true} frame={[]} />
                      </Item>
                    )}

                    {provided.placeholder}
                  </WrapItem>
                )}
              </Droppable>
            </DragDropContext>
          </ParentContainer>
        </ContextMenuTrigger>

        <ContextMenuWrapper isContextVisible={isContextVisible}>
          <ContextMenu ref={contextMenuRef} id="touchFrameContextMenu" hideOnLeave={false}>
            <MenuItem divider />
            <MenuItem onClick={e => handleDeleteFrame(e, currentFrameId)}>
              {t('manager.editor.modal.touch.frame.remove')}
            </MenuItem>
            <MenuItem onClick={() => setIsEdit(currentFrameId)}>
              {t('manager.editor.modal.touch.frame.rename')}
            </MenuItem>
            <MenuItem onClick={() => handleContextMenu('front')}>
              {t('manager.editor.modal.touch.frame.frontest')}
            </MenuItem>
            <MenuItem onClick={() => handleContextMenu('back')}>
              {t('manager.editor.modal.touch.frame.backest')}
            </MenuItem>
            <MenuItem divider />
          </ContextMenu>
        </ContextMenuWrapper>

        <ConfirmModal
          isOpen={removeModalInfo.isOpen}
          type="warn"
          size="small"
          left={removeModalInfo.offset.left}
          top={removeModalInfo.offset.top}
          titleTxt={t('manager.editor.modal.touch.modal.frameRemove.title')}
          body={t('manager.editor.modal.touch.modal.frameRemove.ment')}
          confirmTxt={t('manager.editor.modal.touch.modal.frameRemove.ok')}
          closeTxt={t('manager.editor.modal.touch.modal.frameRemove.cancel')}
          handleConfirm={removeTouchFrame}
          handleClose={closeTouchFrame}
        />
      </Container>
    </>
  );
};

const TouchFrame = memo(
  ({ thumbnail, className, frame, isLoading, handleDeleteFrame, setIsEdit, isEdit, removeModalInfo }) => {
    const [touchFrameUpdate] = useTouchFrameUpdateMutation();
    const [frameName, setFrameName] = useState(frame.frameNm);
    const InputRef = useRef(null);

    useEffect(() => {
      if (isEdit) InputRef.current.focus();
    }, [isEdit]);

    const handleUpdateFrameName = useCallback(
      frameNm => {
        setIsEdit('');
        touchFrameUpdate({ frameId: frame.frameId, frameInfo: { frameNm } });
      },
      [frame.frameId, touchFrameUpdate, setIsEdit],
    );

    return (
      <FrameContainer className={className}>
        <Cancel onClick={e => handleDeleteFrame(e, frame.frameId)}>
          <RemoveIcon />
        </Cancel>
        <Wrap>
          <ThumbNail>
            {isLoading && (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            )}
            <img
              style={{ width: '100%', overflow: 'hidden' }}
              onError={e => (e.target.style.display = 'none')}
              onLoad={e => (e.target.style.display = 'block')}
              src={thumbnail?.url || `${process.env.REACT_APP_INTERACTION_CDN_URL}${frame.thumbPath}`}
              alt=""
            />
          </ThumbNail>
          <FrameInfo>
            {isEdit ? (
              <TextInput
                ref={InputRef}
                type="text"
                value={frameName || ' '}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onFocus={e => e.target.select()}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleUpdateFrameName(e.target.value);
                  }
                }}
                onBlur={e => {
                  if (e.target.value) {
                    handleUpdateFrameName(e.target.value);
                  }
                }}
                onChange={e => setFrameName(e.target.value)}
              />
            ) : (
              <FrameTitle onClick={() => setIsEdit(frame.frameId)}>{frameName}</FrameTitle>
            )}
          </FrameInfo>
        </Wrap>
        <Tooltip id="frameTool" place="top" type="dark" effect="solid" multiline={true} />
      </FrameContainer>
    );
  },
);

const FrameTitle = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  text-align: left;
  position: relative;

  &:hover {
    transition: 0.4s all;
    background: rgb(173 231 255 / 20%);
  }
`;

const TextInput = styled.input`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  font-size: 1em;

  border-bottom: 1px solid rgb(36 145 223 / 26%);
`;

const ParentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2491df;
    border-radius: 3px;
  }
`;

const Cancel = styled.button`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 3px;
  position: absolute;
  right: 0px;
  top: 0px;
  color: #2491df;
  margin: auto;
  box-shadow: 2px -2px 3px 3px #c4c4c452;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: red;
  }
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const WrapItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: normal;
  align-items: center;
  flex-direction: column;
`;

const FrameContainer = styled.div`
  width: 100%;
  aspect-ratio: 4/3;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px 2px #9a9a9a40;
  cursor: pointer;
  overflow: hidden;
  background: white;

  /* display */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  .order {
    display: none;
  }

  &:hover {
    outline: 1px solid #2491df;

    .order {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 2s;
    }
  }
  &.active {
    background-color: #e3f0fa;
    outline: 1px solid #2491df;

    & div {
      color: #2a91df;
    }

    & input {
      background-color: #e3f0fa;
      color: #2a91df;
    }

    & svg {
      .lock {
        fill: #2a91df;
      }
    }
  }
`;
const Wrap = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ThumbNail = styled.div`
  width: 100%;
  height: 80%;
  border-radius: inherit;
  background-color: rgb(128 128 128 / 41%);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconButton = styled.div`
  width: 40px;
  color: black;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.1s, opacity 0.1s;
  &:hover {
    color: #2491df;
  }
`;

const FrameInfo = styled.div`
  width: 100%;
  height: 20%;

  z-index: 3;

  /* display */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
`;

const FrameManageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2px;
  position: sticky;
  border-radius: -6px -3px 4px 6px #bababa85;
  top: 0;
  z-index: 4;
  box-shadow: -4px -1px 7px 0px #d6d6d6;
`;

export default React.memo(TouchModalFrameList);
