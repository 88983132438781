import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import SelectInput from '../../../newComponents/SelectInput';
import RadioCheckBox from '../../../newComponents/RadioCheckbox';

import Loading from '../../../newComponents/Loading';
import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';

import { INIT_DATETIME_INFO } from '../constants/contentApp';

const DateTimeApp = ({
  selectLayerContentsInfo = INIT_DATETIME_INFO,
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
}) => {
  const { t } = useTranslation();
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_DATETIME_INFO);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const [isDateOpen, setIsDateOpen] = useState(true);
  const [isTimeOpen, setIsTimeOpen] = useState(true);
  const [isThemeOpen, setIsThemeOpen] = useState(true);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };

      if (!layerContentsInfo.contentsData.date) {
        layerContentsInfo.contentsData.date = 'mdw';
      }
      if (!layerContentsInfo.contentsData.time) {
        layerContentsInfo.contentsData.time = 'hmap';
      }
      if (!layerContentsInfo.contentsData.theme) {
        layerContentsInfo.contentsData.theme = 'horizon';
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_DATETIME_INFO);
    }
  }, [selectLayerContentsInfo]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.datetime.title')} />
      <AppContentWrap>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox
              title={t('manager.editor.layerArea.contents.datetime.dateForm.title')}
              line
              isOpen={isDateOpen}
              changeOpen={setIsDateOpen}
            >
              <SelectInput
                selectedValue={formData.contentsData.date}
                optionList={[
                  { value: 'mdw', title: t('manager.editor.layerArea.contents.datetime.dateForm.mdw') },
                  { value: 'ymdw', title: t('manager.editor.layerArea.contents.datetime.dateForm.ymdw') },
                  { value: 'md', title: t('manager.editor.layerArea.contents.datetime.dateForm.md') },
                  { value: 'ymd', title: t('manager.editor.layerArea.contents.datetime.dateForm.ymd') },
                ]}
                onSelectChange={value => handleChangeObject('contentsData', 'date', value)}
              />
            </ContentBox>
            <ContentBox
              title={t('manager.editor.layerArea.contents.datetime.timeForm.title')}
              line
              isOpen={isTimeOpen}
              changeOpen={setIsTimeOpen}
            >
              <SelectInput
                selectedValue={formData.contentsData.time}
                optionList={[
                  { value: 'hmap', title: t('manager.editor.layerArea.contents.datetime.timeForm.hmap') },
                  { value: 'hmsap', title: t('manager.editor.layerArea.contents.datetime.timeForm.hmsap') },
                  { value: 'hm', title: t('manager.editor.layerArea.contents.datetime.timeForm.hm') },
                  { value: 'hms', title: t('manager.editor.layerArea.contents.datetime.timeForm.hms') },
                ]}
                onSelectChange={value => handleChangeObject('contentsData', 'time', value)}
              />
            </ContentBox>
            <ContentBox
              title={t('manager.editor.layerArea.contents.datetime.themeForm.title')}
              isOpen={isThemeOpen}
              changeOpen={setIsThemeOpen}
            >
              <RadioCheckBoxWrapper>
                {[
                  { horizon: t('manager.editor.layerArea.contents.datetime.themeForm.horizon') },
                  { dateOnly: t('manager.editor.layerArea.contents.datetime.themeForm.dateOnly') },
                  { timeOnly: t('manager.editor.layerArea.contents.datetime.themeForm.timeOnly') },
                  { dateTop: t('manager.editor.layerArea.contents.datetime.themeForm.dateTop') },
                  { timeTop: t('manager.editor.layerArea.contents.datetime.themeForm.timeTop') },
                ].map(theme => (
                  <RadioCheckBoxArea key={Object.keys(theme)}>
                    <RadioCheckBox
                      id={Object.keys(theme) + 'Id'}
                      name={Object.keys(theme)}
                      checked={formData.contentsData.theme === Object.keys(theme)[0]}
                      onChange={() => handleChangeObject('contentsData', 'theme', Object.keys(theme)[0])}
                      label={Object.values(theme)}
                    />
                  </RadioCheckBoxArea>
                ))}
              </RadioCheckBoxWrapper>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea formData={formData} cancelApp={handleCancelLayerContents} selectedLayerType={selectedLayerType} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const RadioCheckBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RadioCheckBoxArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
`;

export default React.memo(DateTimeApp);
