import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { editorSelector } from '../../../module/editorSlice';
import { endpoints as endpointsGroupApi } from '../../../rtk/groupApi';

import { INIT_WEATHER_INFO } from '../constants/contentApp';
import Loading from '../../../newComponents/Loading';
import RadioCheckBox from '../../../newComponents/RadioCheckbox';
import ContentBox, { AppContentWrap } from './components/ContentBox';
import AppTitle from './components/AppTitle';
import useForm from './components/useForm';
import Weather from './components/Weather';
import AppButtonArea from './components/AppButtonArea';

const WeatherApp = ({
  selectLayerContentsInfo = INIT_WEATHER_INFO,
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
}) => {
  const { t } = useTranslation();
  const placeId = useSelector(editorSelector.placeId);
  const { data: groupList } = endpointsGroupApi.groupList.useQueryState({ placeId: placeId, groupPid: placeId });

  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_WEATHER_INFO);
  const [formValidate, setFormValidate] = useState({ locationList: [] });
  const [groupOptionList, setGroupOptionList] = useState([]);
  const [optionListChangeFlag, setOptionListChangeFlag] = useState(false);

  const [isDetailOpen, setIsDetailOpen] = useState(true);
  const [isLocationOpen, setIsLocationOpen] = useState(true);

  useEffect(() => {
    if (groupList) {
      setGroupOptionList(groupList.map(group => ({ title: group.groupNm, value: group.groupId })));
    }
  }, [groupList]);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const appContentWrapRef = useRef(null);
  const weatherListRef = useRef([]);

  const prevScrollListLength = useRef(formData.contentsData.locationList.length);

  const handleChangeWeatherOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.locationList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'locationList', changeFormData);
    },
    [formData.contentsData.locationList, handleChangeObject],
  );

  const handleChangeWeatherOptionRemove = useCallback(
    index => {
      let changeFormData = [...formData.contentsData.locationList].filter((data, idx) => idx !== index);
      handleChangeObject('contentsData', 'locationList', changeFormData);
      setOptionListChangeFlag(true);
    },
    [formData.contentsData.locationList, handleChangeObject],
  );

  const handleChangeWeatherOptionAdd = useCallback(() => {
    let changeFormData = [...formData.contentsData.locationList];
    changeFormData.push({
      targetGroupId: '',
      location: t('manager.editor.layerArea.contents.weather.location.all'),
    });
    handleChangeObject('contentsData', 'locationList', changeFormData);
    setOptionListChangeFlag(true);
  }, [t, formData.contentsData.locationList, handleChangeObject]);

  const checkValidDate = useCallback(() => {
    let validate = {
      locationList: [],
    };
    let validFlag = false;

    formData.contentsData.locationList.forEach((location, index) => {
      let locationValidate = {};

      if (location.targetGroupId === '') {
        validFlag = true;
        locationValidate.targetGroupId = false;
        locationValidate.targetGroupIdError = t('manager.editor.layerArea.contents.weather.selectTargetGroup');
        setIsLocationOpen(true);
      } else {
        locationValidate.targetGroupId = true;
        locationValidate.targetGroupIdError = '';
      }

      if (location.location === '') {
        validFlag = true;
        locationValidate.location = false;
        locationValidate.locationError = t('manager.editor.layerArea.contents.weather.selectLocation');
        setIsLocationOpen(true);
      } else {
        locationValidate.location = true;
        locationValidate.locationError = '';
      }

      validate.locationList.push(locationValidate);
    });

    setFormValidate(validate);

    if (validFlag) {
      const locationList = validate.locationList;
      for (let index = 0; index < locationList.length; index++) {
        if (!locationList[index].targetGroupId || !locationList[index].location) {
          setTimeout(() => {
            appContentWrapRef.current.scrollTo({
              top: weatherListRef.current[index].scrollIntoView({ block: 'center' }),
            });
          }, 100);
          break;
        }
      }
    }

    return validFlag;
  }, [t, formData.contentsData.locationList]);

  useEffect(() => {
    if (selectLayerContentsInfo) {
      let layerContentsInfo = { ...selectLayerContentsInfo };
      if (!layerContentsInfo.contentsData.locationList) {
        layerContentsInfo.contentsData.locationList = [
          { targetGroupId: 'DEFAULT', location: t('manager.editor.layerArea.contents.weather.location.all') },
        ];
      }
      if (!layerContentsInfo.contentsData.detail) {
        layerContentsInfo.contentsData.detail = 'degree';
      }
      setLayerContentsInfo(layerContentsInfo);
    } else {
      setLayerContentsInfo(INIT_WEATHER_INFO);
    }
  }, [t, selectLayerContentsInfo]);

  useEffect(() => {
    if (optionListChangeFlag) {
      if (prevScrollListLength.current < formData.contentsData.locationList.length) {
        appContentWrapRef.current.scrollTo(0, appContentWrapRef.current.scrollHeight);
      }
      prevScrollListLength.current = formData.contentsData.locationList.length;
      setOptionListChangeFlag(false);
    }
  }, [formData.contentsData.locationList.length, optionListChangeFlag]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.weather.title')} />
      <AppContentWrap ref={appContentWrapRef}>
        {appLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox
              title={t('manager.editor.layerArea.contents.weather.setting.title')}
              isOpen={isDetailOpen}
              changeOpen={setIsDetailOpen}
              line
            >
              <RadioCheckBoxArea>
                <RadioCheckBoxWrapper>
                  <RadioCheckBox
                    name="detailCheck"
                    id="detailId-0"
                    checked={formData.contentsData.detail === 'degree'}
                    onChange={() => handleChangeObject('contentsData', 'detail', 'degree')}
                    label={t('manager.editor.layerArea.contents.weather.setting.degree')}
                  />
                </RadioCheckBoxWrapper>
                <RadioCheckBoxWrapper>
                  <RadioCheckBox
                    name="detailCheck"
                    id="detailId-1"
                    checked={formData.contentsData.detail === 'degreeAndDust'}
                    onChange={() => handleChangeObject('contentsData', 'detail', 'degreeAndDust')}
                    label={t('manager.editor.layerArea.contents.weather.setting.degreeAndDust')}
                  />
                </RadioCheckBoxWrapper>
              </RadioCheckBoxArea>
            </ContentBox>
            <ContentBox
              title={t('manager.editor.layerArea.contents.weather.location.title')}
              isOpen={isLocationOpen}
              changeOpen={setIsLocationOpen}
            >
              {formData.contentsData.locationList &&
                formData.contentsData.locationList.map((group, index) => (
                  <WeatherWrapper key={`wetherWrapper-app-${index}`} ref={ref => (weatherListRef.current[index] = ref)}>
                    <Weather
                      key={`wether-app-${index}`}
                      index={index}
                      group={group}
                      groupOptionList={groupOptionList}
                      handleChangeWeatherOption={handleChangeWeatherOption}
                      handleChangeWeatherOptionRemove={handleChangeWeatherOptionRemove}
                      formValidate={formValidate}
                    />
                  </WeatherWrapper>
                ))}
              <AddButton onClick={() => handleChangeWeatherOptionAdd()}>
                {t('manager.editor.layerArea.contents.weather.add')}
              </AddButton>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        checkValidDate={checkValidDate}
        cancelApp={handleCancelLayerContents}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const RadioCheckBoxArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  font-size: 14px;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;

const RadioCheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 100%;
  height: 40px;
  border: 1px solid #2a91df;
  background: #ffffff;
  border-radius: 6px;
  color: #2a91df;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background: #f1f1f1;
  }
`;

const WeatherWrapper = styled.div``;

export default React.memo(WeatherApp);
