import * as apiLib from './api';

export const token = async ({ playToken }) => {
  try {
    const data = {
      token: playToken,
    };

    const result = await apiLib.fetchInteractionApi('/player/token', data);
    if (result.resultFlag) {
      return result.playInfo;
    } else {
      throw Object.assign(new Error('playlist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const playlist = async ({ deviceToken, playlistId }) => {
  try {
    const data = {
      deviceToken,
      playlistId,
    };

    const result = await apiLib.fetchInteractionApi('/player/playlist', data);
    if (result.resultFlag) {
      return {
        contentWidth: Number(result.playlistInfo.horizonResolution),
        contentHeight: Number(result.playlistInfo.verticalResolution),
        playlistInfo: result.playlistInfo,
        frameList: result.frameList,
        layerList: result.layerList,
        layerContentsList: result.layerContentsList,
        overlayList: result.overlayList,
      };
    } else {
      throw Object.assign(new Error('playlist error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getWeather = async ({ deviceToken, weatherId }) => {
  try {
    const data = {
      deviceToken,
      weatherId,
    };

    const result = await apiLib.fetchInteractionApi('/player/weather', data);

    if (result.resultFlag) {
      if (result.weatherInfo) {
        return result.weatherInfo;
      } else {
        return result.weatherInfoList;
      }
    } else {
      throw Object.assign(new Error('weather error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getWorldWeather = async ({ deviceToken, weatherId }) => {
  try {
    const data = {
      deviceToken,
      weatherId,
    };

    const result = await apiLib.fetchInteractionApi('/player/world-weather', data);

    if (result.resultFlag) {
      return result.weatherInfo;
    } else {
      throw Object.assign(new Error('weather error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const rss = async ({ deviceToken, rssId }) => {
  try {
    const data = {
      deviceToken,
      rssId,
    };

    const result = await apiLib.fetchInteractionApi('/player/rss', data);
    if (result.resultFlag) {
      return result.rssInfo;
    } else {
      throw Object.assign(new Error('rss error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
