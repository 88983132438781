import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useWorldWeatherListQuery } from 'rtk/appApi';

import Loading from '../../../newComponents/Loading';
import SelectInput from '../../../newComponents/SelectInput';

import { INIT_WORLD_WEATHER_INFO } from '../constants/contentApp';
import AppTitle from './components/AppTitle';
import { AppContentWrap } from './components/ContentBox';
import ContentBox from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';
import useForm from './components/useForm';

const WorldWeatherApp = ({
  selectLayerContentsInfo = INIT_WORLD_WEATHER_INFO,
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
}) => {
  const { t } = useTranslation();

  const appContentWrapRef = useRef(null);

  const [isDetailOpen, setIsDetailOpen] = useState(true);
  const [isLocationOpen, setIsLocationOpen] = useState(true);

  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_WORLD_WEATHER_INFO);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const { data: worldWeatherList, isLoading: worldWeatherListLoading } = useWorldWeatherListQuery(
    { country: formData.contentsData.country },
    { skip: false },
  );

  useEffect(() => {
    if (selectLayerContentsInfo) {
      if (selectLayerContentsInfo.contentsId === '') {
        const newLayerContentsInfo = {
          ...selectLayerContentsInfo,
          contentsData: { ...INIT_WORLD_WEATHER_INFO.contentsData },
        };
        setLayerContentsInfo(newLayerContentsInfo);
      } else {
        setLayerContentsInfo(selectLayerContentsInfo);
      }
    } else {
      setLayerContentsInfo(INIT_WORLD_WEATHER_INFO);
    }
  }, [selectLayerContentsInfo]);

  const checkValidDate = useCallback(() => {
    let validFlag = false;

    if (formData.contentsData.city === '') {
      validFlag = true;
    }

    if (worldWeatherList.findIndex(item => item.weatherId === formData.contentsData.city) === -1) {
      handleChangeObject('contentsData', 'city', '');
      validFlag = true;
    }

    return validFlag;
  }, [formData, worldWeatherList, handleChangeObject]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.worldWeather.title')} />
      <AppContentWrap ref={appContentWrapRef}>
        {appLoading || worldWeatherListLoading ? (
          <Loading />
        ) : (
          <>
            <ContentBox
              title={t('manager.editor.layerArea.contents.worldWeather.setting.title')}
              isOpen={isDetailOpen}
              changeOpen={setIsDetailOpen}
              line={true}
            >
              <Row>
                <Box>
                  <LeftArea>{t('manager.editor.layerArea.contents.worldWeather.setting.theme.title')}</LeftArea>
                  <RightArea>
                    <SelectInput
                      selectedValue={formData.contentsData.theme}
                      optionList={[
                        {
                          title: t('manager.editor.layerArea.contents.worldWeather.setting.theme.temp'),
                          value: 'TEMP',
                        },
                        {
                          title: t('manager.editor.layerArea.contents.worldWeather.setting.theme.cloud_temp'),
                          value: 'CLOUD_TEMP',
                        },
                        {
                          title: t('manager.editor.layerArea.contents.worldWeather.setting.theme.cloud_temp_air'),
                          value: 'CLOUD_TEMP_AIR',
                        },
                      ]}
                      onSelectChange={value => handleChangeObject('contentsData', 'theme', value)}
                    />
                  </RightArea>
                </Box>
              </Row>
              <Row>
                <Box>
                  <LeftArea>{t('manager.editor.layerArea.contents.worldWeather.setting.unit.title')}</LeftArea>
                  <RightArea>
                    <SelectInput
                      selectedValue={formData.contentsData.unit}
                      optionList={[
                        { title: t('manager.editor.layerArea.contents.worldWeather.setting.unit.cs'), value: 'CS' },
                        { title: t('manager.editor.layerArea.contents.worldWeather.setting.unit.fs'), value: 'FS' },
                      ]}
                      onSelectChange={value => handleChangeObject('contentsData', 'unit', value)}
                    />
                  </RightArea>
                </Box>
              </Row>
            </ContentBox>
            <ContentBox
              title={t('manager.editor.layerArea.contents.worldWeather.location.title')}
              isOpen={isLocationOpen}
              changeOpen={setIsLocationOpen}
            >
              <Row>
                <Box>
                  <LeftArea>{t('manager.editor.layerArea.contents.worldWeather.location.country.title')}</LeftArea>
                  <RightArea>
                    <SelectInput
                      selectedValue={formData.contentsData.country}
                      optionList={[
                        { title: t('manager.editor.layerArea.contents.worldWeather.location.country.kr'), value: 'KR' },
                        { title: t('manager.editor.layerArea.contents.worldWeather.location.country.us'), value: 'US' },
                        { title: t('manager.editor.layerArea.contents.worldWeather.location.country.jp'), value: 'JP' },
                      ]}
                      onSelectChange={value => handleChangeObject('contentsData', 'country', value)}
                    />
                  </RightArea>
                </Box>
              </Row>
              <Row>
                <Box>
                  <LeftArea>{t('manager.editor.layerArea.contents.worldWeather.location.city')}</LeftArea>
                  <RightArea>
                    <SelectInput
                      selectedValue={formData.contentsData.city}
                      optionList={
                        worldWeatherList
                          ? worldWeatherList
                              .map(item => ({
                                title:
                                  { ko: item.nameKr, en: item.nameEn, jp: item.nameJp }[t('langCd')] || item.nameEn,
                                value: item.weatherId,
                              }))
                              .sort((lhs, rhs) => lhs.title.localeCompare(rhs.title))
                          : []
                      }
                      onSelectChange={value => handleChangeObject('contentsData', 'city', value)}
                      isValidate={formData.contentsData.city !== ''}
                    />
                  </RightArea>
                </Box>
              </Row>
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        checkValidDate={checkValidDate}
        cancelApp={handleCancelLayerContents}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 16px;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
  font-size: 14px;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
`;

const RightArea = styled.div`
  flex: 3;
  align-items: center;

  display: flex;
  justify-content: center;
`;

export default React.memo(WorldWeatherApp);
