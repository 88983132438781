import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { accountSelector } from '../../../module/accountSlice';
import { editorAction, editorSelector } from '../../../module/editorSlice';
import { endpoints as endpointsAppApi } from '../../../rtk/appApi';

import { CURRENT_APP_TITLE, CURRENT_APP_CATEGORY } from '../constants/contentApp';
import LayerContentsAppListWapper from '../LayerContentsAppListWapper';

const DefaultApp = ({ handleLayerContentsInfo }) => {
  const dispatch = useDispatch();

  const editingMode = useSelector(editorSelector.editingMode);

  const { accountId } = useSelector(accountSelector.accountInfo);
  const { data: appList } = endpointsAppApi.appList.useQueryState('');

  const [appHistoryList, setAppHistoryList] = useState([]);

  useEffect(() => {
    if (editingMode) {
      dispatch(editorAction.editingModeLayerContents(false));
    }
  }, [editingMode, dispatch]);

  useEffect(() => {
    const history = JSON.parse(localStorage.getItem('layerContents.history' + accountId)) || {};
    let newHistoryList = [];
    if (history.appHistoryList) {
      const appHistroyList = history.appHistoryList;
      for (const appHistory of appHistroyList) {
        if (appHistory.appId) {
          newHistoryList.push(appHistory);
        }
      }
    }
    setAppHistoryList(appHistoryList => newHistoryList);
  }, [accountId]);

  return (
    <Component>
      {appHistoryList.length > 0 && (
        <LayerContentsAppListWapper
          title={CURRENT_APP_TITLE}
          appList={appHistoryList}
          handleClickApp={handleLayerContentsInfo}
        />
      )}
      <LayerContentsAppListWapper
        title={CURRENT_APP_CATEGORY}
        appList={appList}
        handleClickApp={handleLayerContentsInfo}
      />
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden overlay;
  padding-top: 10px;
  padding: 8px;
  &::-webkit-scrollbar {
    width: 0.375rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 0.1563rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
`;

export default React.memo(DefaultApp);
