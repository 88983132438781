import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { editorAction, editorSelector } from '../../module/editorSlice';

import { useLayerAddListMutation } from '../../rtk/layerApi';
import { endpoints as endpointsLayerContentsApi, useLayerContentsCopyListMutation } from '../../rtk/layerContentsApi';
import { useOverlayAddListMutation } from '../../rtk/overlayApi';
import {
  endpoints as endpointsOverlayContentsApi,
  useOverlayContentsCopyListMutation,
} from '../../rtk/overlayContentsApi';

const ClipboardManager = forwardRef(({ historyRef }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedLayerList = useSelector(editorSelector.selectedLayerList);
  const playlistId = useSelector(editorSelector.playlistId);
  const frameId = useSelector(editorSelector.frameId);

  const { currentData: layerContentsList } = endpointsLayerContentsApi.layerContentsList.useQueryState({ frameId });
  const { currentData: overlayContentsList } = endpointsOverlayContentsApi.overlayContentsList.useQueryState({
    playlistId,
  });

  const [layerAddListMutation] = useLayerAddListMutation();
  const [copyListLayerContents] = useLayerContentsCopyListMutation();

  const [overlayAddListMutation] = useOverlayAddListMutation();
  const [copyListOverlayContents] = useOverlayContentsCopyListMutation();

  const copiedLayerList = useRef([]);
  const copiedLayerContentsList = useRef([]);
  const copiedOverlayContentsList = useRef([]);
  const copiedCnt = useRef(0);

  // 레이어 복사
  const copyLayer = () => {
    if (selectedLayerList.length > 0) {
      copiedLayerList.current = selectedLayerList;
      copiedLayerContentsList.current = layerContentsList;
      copiedOverlayContentsList.current = overlayContentsList;
    } else {
      copiedLayerList.current = [];
      copiedLayerContentsList.current = [];
      copiedOverlayContentsList.current = [];
    }
    copiedCnt.current = 0;
  };

  // 레이어 붙여넣기
  const pasteLayer = () => {
    if (copiedLayerList.current.length > 0) {
      let addLayerList = [];
      let addOverlayList = [];
      let addLayerContentsList = [];
      let addOverlayContentsList = [];

      const _copiedCnt = copiedCnt.current === 0 ? '' : `(${copiedCnt.current})`;
      copiedCnt.current = copiedCnt.current + 1;
      for (let layerInfo of copiedLayerList.current) {
        const id = layerInfo.id;

        const layerId = dayjs().unix() + v4().substr(0, 8);
        const contentsId = dayjs().unix() + v4().substr(0, 8);

        if (layerInfo.type === 'OVERLAY') {
          if (layerInfo.overlayOrder) {
            const { overlayOrder, ...changeLayerInfo } = layerInfo;
            layerInfo = changeLayerInfo;
          }

          addOverlayList.push({
            ...layerInfo,
            id: layerId,
            overlayId: layerId,
            overlayNm: `${layerInfo.overlayNm}-${t('manager.editor.clipboard.copied')}${_copiedCnt}`,
          });
          const overlayContents = copiedOverlayContentsList.current?.find(
            overlayContents => overlayContents.overlayId === id,
          );
          if (overlayContents) {
            addOverlayContentsList.push({ ...overlayContents, overlayId: layerId, contentsId });
          }
        } else {
          if (layerInfo.layerOrder) {
            const { layerOrder, ...changeLayerInfo } = layerInfo;
            layerInfo = changeLayerInfo;
          }
          addLayerList.push({
            ...layerInfo,
            id: layerId,
            layerId,
            baseYn: 'N',
            layerNm: `${layerInfo.layerNm}-${t('manager.editor.clipboard.copied')}${_copiedCnt}`,
            frameId,
          });
          const layerContents = copiedLayerContentsList.current?.find(layerContents => layerContents.layerId === id);
          if (layerContents) {
            addLayerContentsList.push({ ...layerContents, layerId, contentsId, frameId });
          }
        }
      }

      addLayerList = addLayerList.sort((lhs, rhs) => lhs.layerOrder - rhs.layerOrder);
      addOverlayList = addOverlayList.sort((lhs, rhs) => lhs.overlayOrder - rhs.overlayOrder);

      if (addLayerList.length > 0) {
        layerAddListMutation({ addList: addLayerList }).then(() => {
          if (addOverlayList.length <= 0) {
            const selectedLayerList = [...addLayerList, ...addOverlayList];

            historyRef.current.addHistory({
              type: 'ADD-LAYER',
              props: { infos: selectedLayerList, prevSelectedLayerList: selectedLayerList },
            });
            dispatch(editorAction.setState({ key: 'selectedLayerList', value: selectedLayerList }));
          }
        });

        if (addLayerContentsList.length > 0) {
          copyListLayerContents({ copyLayerContentsList: addLayerContentsList });
        }
      }

      if (addOverlayList.length > 0) {
        overlayAddListMutation({ addList: addOverlayList }).then(() => {
          const selectedLayerList = [...addLayerList, ...addOverlayList];

          historyRef.current.addHistory({
            type: 'ADD-LAYER',
            props: { infos: selectedLayerList, prevSelectedLayerList: selectedLayerList },
          });
          dispatch(editorAction.setState({ key: 'selectedLayerList', value: selectedLayerList }));
        });

        if (addOverlayContentsList.length > 0) {
          copyListOverlayContents({ copyOverlayContentsList: addOverlayContentsList });
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    copyLayer,
    pasteLayer,
  }));

  return <></>;
});

export default ClipboardManager;
