import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { getDuration, getImageThumb, getVideoThumb } from '../../../utils/file';

import useUploadFileSizeCheck from 'utils/useUploadFileSizeCheck';

import { editorAction, editorSelector } from '../../../module/editorSlice';
import { endpoints as endpointPlaceApi } from '../../../rtk/placeApi';
import { endpoints as endpointContentsApi } from '../../../rtk/contentsApi';

import useForm from './components/useForm';
import AppTitle from './components/AppTitle';
// import ContentBox from './components/ContentBox';
import AppButtonArea from './components/AppButtonArea';

import SquareButton from '../../../components/SquareButton';
import SelectInput from '../../../newComponents/SelectInput';

import Loading from '../../../newComponents/Loading';
import Button from '../../../newComponents/Button';
import { INIT_MEDIA_INFO } from '../constants/contentApp';
import PublicStorageModal from '../modal/PublicStorageModal';
import StorageModal from '../modal/StorageModal';
import ContentDragModal from './components/modal/ContentDragModal';
import RemoveConfirmModal from './components/modal/RemoveConfirmModal';
import { AppContentWrap } from './components/ContentBox';

import contentsError from '../../../assets/images/media/contents-error.svg';

import { FiUpload, FiImage, FiXSquare, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { HiPlus } from 'react-icons/hi';
import { BiImages as ImagesIcon } from 'react-icons/bi';

const MediaApp = ({
  handleCancelLayerContents,
  selectedLayerType,
  appLoading,
  selectLayerContentsInfo = INIT_MEDIA_INFO,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isPublicStorageModal, setIspublicStorageModal] = useState(false);
  const [isStorageModal, setIsStorageModal] = useState(false);
  const [isContentsDragModal, setIsContentsDragModal] = useState(false);
  const [isFolderSelected, setIsFolderSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLayerContentsConfirmModal, setIsRemoveLayerContentsConfirmModal] = useState(false);
  const placeId = useSelector(editorSelector.placeId);
  const editingMode = useSelector(editorSelector.editingMode);
  const { data: placeInfo } = endpointPlaceApi.placeDetail.useQueryState({ placeId });
  const { data: placeFileInfo, refetch: placeFileInfoRefetch } = endpointContentsApi.contentsFileDetail.useQuery({
    placeId,
  });

  const { fnUploadFileSizeCheck } = useUploadFileSizeCheck();
  const [uploadFileSize, setUploadFileSize] = useState(0);
  const [layerContentsInfo, setLayerContentsInfo] = useState(INIT_MEDIA_INFO);

  const [activeFileIndex, setActiveFileIndex] = useState(0);

  const [addFileList, setAddFileList] = useState([]);
  const [waitUploadFileList, setWaitUploadFileList] = useState([]);
  const [contentsFileList, setContentsFileList] = useState([]);

  const { formData, handleChangeObject } = useForm({ initialValues: layerContentsInfo });

  const handleChangeMediaOption = useCallback(
    (index, key, value) => {
      let changeFormData = [...formData.contentsData.mediaList];
      changeFormData[index][key] = value;

      handleChangeObject('contentsData', 'mediaList', changeFormData);
    },
    [formData.contentsData.mediaList, handleChangeObject],
  );

  const closeModal = useCallback(type => {
    setIspublicStorageModal(false);
    setIsStorageModal(false);
    setIsFolderSelected(false);
    setIsContentsDragModal(false);
    setIsRemoveLayerContentsConfirmModal(false);

    if (type === 'remove') {
      setWaitUploadFileList([]);
    }
  }, []);

  const handleChangeStorageAdd = useCallback(
    addMediaList => {
      let changeFormData = [...formData.contentsData.mediaList];
      changeFormData = changeFormData.concat(addMediaList);
      handleChangeObject('contentsData', 'mediaList', changeFormData);
      setActiveFileIndex(changeFormData.length - 1);
    },
    [formData.contentsData.mediaList, handleChangeObject],
  );

  const onChangeAddfileList = useCallback(
    async folder => {
      setIsLoading(true);

      const uploadFiles = fnUploadFileSizeCheck(waitUploadFileList, '', uploadFileSize, placeInfo.storageMax);
      let changeMediaList = [...formData.contentsData.mediaList];
      let changeAddFileList = [];
      let changeForm = { ...folder };

      if (addFileList[folder.folderId]) {
        changeAddFileList = [...addFileList[folder.folderId].fileList];
      }

      for (var i = 0; i < uploadFiles.length; i++) {
        let file = uploadFiles[i];

        const fileId = dayjs().unix() + v4().substr(0, 8);
        let addFileInfo = {
          fileData: file,
          fileId: fileId,
          fileNm: file.name,
          fileSize: file.size,
          fileType: file.type,
          filePath: folder.folderId,
          fileOrder: changeMediaList.length + 1,
        };

        let contentsFileInfo = {
          fileId: fileId,
          fileNm: file.name,
          filePath: URL.createObjectURL(file),
          fileSize: file.size,
          fileType: file.type,
          fileThumb: URL.createObjectURL(file),
        };

        let fileOption = {
          filePlaceId: placeId,
          fileFolderId: folder.folderId,
          filePreview: URL.createObjectURL(file),
          fileId: fileId,
          fileDragId: fileId,
          fileData: file,
          animation: 'none',
          fitOption: 'contain',
          fileType: file.type,
          fileNm: file.name,
          fromPublic: 'Y',
        };

        const typeIdx = file.type.indexOf('/');
        if (file.type.substring(0, typeIdx) === 'video') {
          const duration = await getDuration(file);
          const videoThumb = await getVideoThumb(file);
          fileOption.changeTime = String(duration);
          fileOption.fileThumb = videoThumb;
          fileOption.fileThumbPreview = URL.createObjectURL(fileOption.fileThumb);
          contentsFileInfo.fileThumb = URL.createObjectURL(fileOption.fileThumb);
          addFileInfo.fileDuration = String(duration);
        } else {
          const imgThumb = await getImageThumb(file);
          fileOption.changeTime = '3';
          addFileInfo.fileDuration = '0';
          if (file.size > imgThumb.size) {
            fileOption.fileThumb = imgThumb;
            fileOption.fileThumbPreview = URL.createObjectURL(fileOption.fileThumb);
            contentsFileInfo.fileThumb = URL.createObjectURL(fileOption.fileThumb);
          }
        }

        changeMediaList.push(fileOption);
        changeAddFileList.push(addFileInfo);
        contentsFileList.push(contentsFileInfo);
        setUploadFileSize(uploadFileSize => uploadFileSize + file.size);
      }

      changeForm.fileList = changeAddFileList;

      handleChangeObject('contentsData', 'mediaList', changeMediaList);
      setAddFileList({ ...addFileList, [folder.folderId]: changeForm });
      setContentsFileList(contentsFileList);
      setActiveFileIndex(changeMediaList.length - 1);

      setIsLoading(false);
    },
    [
      addFileList,
      placeId,
      waitUploadFileList,
      formData.contentsData.mediaList,
      contentsFileList,
      fnUploadFileSizeCheck,
      uploadFileSize,
      placeInfo.storageMax,
      handleChangeObject,
    ],
  );

  const onClickDelete = useCallback(() => {
    if (formData.contentsData.mediaList[activeFileIndex].fileFolderId) {
      let fileFolderId = formData.contentsData.mediaList[activeFileIndex].fileFolderId;
      let removeFileId = formData.contentsData.mediaList[activeFileIndex].fileId;

      let changeAddFileForm = { ...addFileList[fileFolderId] };
      let changeAddfileList = [...addFileList[fileFolderId].fileList];
      changeAddfileList = changeAddfileList.filter(fileInfo => fileInfo.fileId !== removeFileId);
      changeAddFileForm.fileList = changeAddfileList;
      setAddFileList({ ...addFileList, [fileFolderId]: changeAddFileForm });
    }

    let changeFormData = [...formData.contentsData.mediaList];
    let changeContentFileList = [...contentsFileList];
    changeFormData.splice(activeFileIndex, 1);
    changeContentFileList.splice(activeFileIndex, 1);

    if (formData.contentsData.mediaList.length - 2 < activeFileIndex && activeFileIndex > 0) {
      setActiveFileIndex(activeFileIndex => activeFileIndex - 1);
    }

    if (changeFormData.length === 0) {
      if (formData.contentsId === '') {
        handleCancelLayerContents();
      } else {
        setIsRemoveLayerContentsConfirmModal(true);
      }
    } else {
      handleChangeObject('contentsData', 'mediaList', changeFormData);
      setContentsFileList(changeContentFileList);
    }
  }, [
    activeFileIndex,
    formData.contentsData.mediaList,
    formData.contentsId,
    handleChangeObject,
    addFileList,
    contentsFileList,
    handleCancelLayerContents,
  ]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections && fileRejections.length === 0) {
        setWaitUploadFileList(acceptedFiles);
        setIsFolderSelected(true);
        setIsStorageModal(true);
        placeFileInfoRefetch();
      } else {
        toast.error(t('manager.editor.layerArea.contents.media.fileNotSupport'));
      }
    },
    [t, placeFileInfoRefetch],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/gif, video/mp4',
  });

  useEffect(() => {
    if (!editingMode) {
      if (selectLayerContentsInfo) {
        let layerContentsInfo = { ...selectLayerContentsInfo };

        if (!layerContentsInfo.contentsData.mediaList) {
          layerContentsInfo.contentsData.mediaList = [];
        }
        setLayerContentsInfo(layerContentsInfo);
      } else {
        setLayerContentsInfo(INIT_MEDIA_INFO);
      }
      dispatch(editorAction.editingModeLayerContents(false));
      setAddFileList([]);
      setActiveFileIndex(0);
    }
  }, [selectLayerContentsInfo, dispatch, editingMode]);

  useEffect(() => {
    if (formData.contentsFileList) {
      setContentsFileList(formData.contentsFileList);
    } else {
      setContentsFileList([]);
    }
  }, [formData.contentsFileList]);

  useEffect(() => {
    if (placeFileInfo) {
      setUploadFileSize(placeFileInfo.totalFileSize);
    }
  }, [placeFileInfo]);

  return (
    <Container>
      <AppTitle text={t('manager.editor.layerArea.contents.media.title')} />
      <AppContentWrap>
        {isLoading || appLoading ? (
          <Loading />
        ) : (
          <>
            <ButtonWrapper>
              <Button
                color={'gray700'}
                outline="true"
                onClick={() => {
                  setIspublicStorageModal(true);
                  placeFileInfoRefetch();
                }}
              >
                {t('manager.editor.layerArea.contents.media.publicStorage')}
              </Button>
              <Button
                color={'gray700'}
                outline="true"
                onClick={() => {
                  setIsStorageModal(true);
                  placeFileInfoRefetch();
                }}
              >
                {t('manager.editor.layerArea.contents.media.storage')}
              </Button>
            </ButtonWrapper>
            <ContentBox>
              {contentsFileList.length === 0 ? (
                <StyledTextDiv {...getRootProps({ isDragAccept, isDragReject })}>
                  <div className="icon">
                    <FiUpload size="17px" color="#999999" />
                  </div>
                  <input {...getInputProps()} />
                  <Trans i18nKey="manager.editor.layerArea.contents.media.dropFile" />
                </StyledTextDiv>
              ) : (
                <FileUploadArea style={{ paddingTop: '0px' }}>
                  <FilePreviewArea>
                    {contentsFileList[activeFileIndex]?.filePath === null ? (
                      <img
                        style={{ position: 'relative', width: '100%', height: '236px', objectFit: 'contain' }}
                        src={contentsError}
                        alt="preview"
                      ></img>
                    ) : contentsFileList[activeFileIndex]?.fileType.includes('image') ? (
                      <img
                        style={{ position: 'relative', width: '100%', height: '236px', objectFit: 'contain' }}
                        src={
                          contentsFileList[activeFileIndex]?.filePath.includes('blob:')
                            ? contentsFileList[activeFileIndex]?.filePath
                            : process.env.REACT_APP_INTERACTION_CDN_URL + contentsFileList[activeFileIndex]?.filePath
                        }
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = contentsError;
                        }}
                        alt="preview"
                      ></img>
                    ) : (
                      <video
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '236px',
                          objectFit: 'contain',
                          outline: 'none',
                        }}
                        preload="auto"
                        controls
                        muted
                        src={
                          contentsFileList[activeFileIndex]?.filePath?.includes('blob:')
                            ? contentsFileList[activeFileIndex]?.filePath
                            : process.env.REACT_APP_INTERACTION_CDN_URL + contentsFileList[activeFileIndex]?.filePath
                        }
                      ></video>
                    )}
                    <FileInfoArea>
                      <FiImage size="19px" style={{ marginRight: '10px' }} />
                      <FileInfoText>{contentsFileList[activeFileIndex]?.fileNm || ''}</FileInfoText>
                      <FiXSquare size="18px" style={{ cursor: 'pointer' }} onClick={() => onClickDelete()} />
                    </FileInfoArea>
                  </FilePreviewArea>
                  <PlusButtonArea>
                    <div style={{ display: 'flex' }}>
                      <StyledSquareButton
                        isLastItem={activeFileIndex === 0 ? true : false}
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          if (activeFileIndex !== 0) {
                            setActiveFileIndex(activeFileIndex - 1);
                          }
                        }}
                      >
                        <FiChevronLeft size="20px" color={'#999999'} />
                      </StyledSquareButton>
                      <StyledSquareButton
                        isLastItem={activeFileIndex >= formData.contentsData.mediaList.length - 1 ? true : false}
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          if (activeFileIndex < formData.contentsData.mediaList.length - 1) {
                            setActiveFileIndex(activeFileIndex + 1);
                          }
                        }}
                      >
                        <FiChevronRight size="20px" color={'#999999'} />
                      </StyledSquareButton>
                    </div>
                    <StyledSquareButton>
                      <HiPlus size="20px" color="#999999" />
                      <FileInput
                        type="file"
                        accept={'image/jpeg, image/png, image/gif, video/mp4'}
                        onChange={e => {
                          setWaitUploadFileList(Array.prototype.slice.call(e.target.files));
                          setIsFolderSelected(true);
                          setIsStorageModal(true);
                          placeFileInfoRefetch();
                        }}
                        multiple
                      />
                    </StyledSquareButton>
                  </PlusButtonArea>

                  <PlusButtonArea>
                    <div style={{ display: 'flex' }}>
                      <StyledSquareButton dragModal onClick={() => setIsContentsDragModal(true)}>
                        <ImagesIcon size="20px" color="#999999" style={{ marginRight: '0.625rem' }} />
                        {t('manager.editor.layerArea.contents.media.orderChange')}
                      </StyledSquareButton>
                    </div>
                  </PlusButtonArea>
                  <Box>
                    <LeftArea>
                      <Title>{t('manager.editor.layerArea.contents.media.fitOption.title')}</Title>
                    </LeftArea>
                    <RightArea>
                      <SelectInput
                        selectedValue={formData.contentsData.mediaList[activeFileIndex]?.fitOption}
                        optionList={[
                          { value: 'cover', title: t('manager.editor.layerArea.contents.media.fitOption.cover') },
                          { value: 'fill', title: t('manager.editor.layerArea.contents.media.fitOption.fill') },
                          { value: 'contain', title: t('manager.editor.layerArea.contents.media.fitOption.contain') },
                          { value: 'none', title: t('manager.editor.layerArea.contents.media.fitOption.none') },
                        ]}
                        onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'fitOption', value)}
                      />
                    </RightArea>
                  </Box>
                  <Box>
                    <LeftArea>
                      <Title>{t('manager.editor.layerArea.contents.media.aniOption.title')}</Title>
                    </LeftArea>
                    <RightArea>
                      <SelectInput
                        selectedValue={formData.contentsData.mediaList[activeFileIndex]?.animation}
                        optionList={[
                          { value: 'none', title: t('manager.editor.layerArea.contents.media.aniOption.none') },
                          {
                            value: 'changeOpacity',
                            title: t('manager.editor.layerArea.contents.media.aniOption.changeOpacity'),
                          },
                          {
                            value: 'leftToRight',
                            title: t('manager.editor.layerArea.contents.media.aniOption.leftToRight'),
                          },
                          {
                            value: 'rightToLeft',
                            title: t('manager.editor.layerArea.contents.media.aniOption.rightToLeft'),
                          },
                          {
                            value: 'bottomToTop',
                            title: t('manager.editor.layerArea.contents.media.aniOption.bottomToTop'),
                          },
                          {
                            value: 'topToBottom',
                            title: t('manager.editor.layerArea.contents.media.aniOption.topToBottom'),
                          },
                        ]}
                        onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'animation', value)}
                      />
                    </RightArea>
                  </Box>
                  {contentsFileList[activeFileIndex]?.fileType.includes('image') && (
                    <Box>
                      <LeftArea>
                        <Title>{t('manager.editor.layerArea.contents.media.timeOption.title')}</Title>
                      </LeftArea>
                      <RightArea>
                        <SelectInput
                          selectedValue={formData.contentsData.mediaList[activeFileIndex]?.changeTime}
                          optionList={[
                            { value: '0', title: t('manager.editor.layerArea.contents.media.timeOption.none') },
                            { value: '3', title: t('manager.editor.layerArea.contents.media.timeOption.3sec') },
                            { value: '5', title: t('manager.editor.layerArea.contents.media.timeOption.5sec') },
                            { value: '7', title: t('manager.editor.layerArea.contents.media.timeOption.7sec') },
                            { value: '10', title: t('manager.editor.layerArea.contents.media.timeOption.10sec') },
                            { value: '15', title: t('manager.editor.layerArea.contents.media.timeOption.15sec') },
                            { value: '20', title: t('manager.editor.layerArea.contents.media.timeOption.20sec') },
                            { value: '25', title: t('manager.editor.layerArea.contents.media.timeOption.25sec') },
                            { value: '30', title: t('manager.editor.layerArea.contents.media.timeOption.30sec') },
                          ]}
                          onSelectChange={value => handleChangeMediaOption(activeFileIndex, 'changeTime', value)}
                        />
                      </RightArea>
                    </Box>
                  )}
                </FileUploadArea>
              )}
            </ContentBox>
          </>
        )}
      </AppContentWrap>
      <AppButtonArea
        formData={formData}
        cancelApp={handleCancelLayerContents}
        addFileList={addFileList}
        selectedLayerType={selectedLayerType}
        noConform={contentsFileList.length === 0}
      />
      <PublicStorageModal
        isOpen={isPublicStorageModal}
        closeModal={closeModal}
        handleChangeStorageAdd={handleChangeStorageAdd}
        contentsFileList={contentsFileList}
        setContentsFileList={setContentsFileList}
        appType={'media'}
        multiSelect={true}
      />
      <StorageModal
        isOpen={isStorageModal}
        closeModal={closeModal}
        handleChangeStorageAdd={handleChangeStorageAdd}
        placeId={placeId}
        isFolderSelected={isFolderSelected}
        onChangeAddfileList={onChangeAddfileList}
        contentsFileList={contentsFileList}
        setContentsFileList={setContentsFileList}
        appType={'media'}
        multiSelect={true}
      />
      <ContentDragModal
        isOpen={isContentsDragModal}
        closeModal={closeModal}
        contentsFileList={contentsFileList}
        mediaList={formData.contentsData.mediaList}
        handleChangeObject={handleChangeObject}
        setContentsFileList={setContentsFileList}
      />
      <RemoveConfirmModal
        contentsId={formData.contentsId}
        isOpen={isRemoveLayerContentsConfirmModal}
        closeModal={closeModal}
        selectedLayerType={selectedLayerType}
      />
    </Container>
  );
};

const getColor = props => {
  if (props.isDragAccept) {
    return '#2a91df';
  }
  if (props.isDragReject) {
    return '#f05b5b';
  }
  return '#cfcfcf';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin: 0 19px 19px 0;
`;

const ContentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 80%;
`;

const StyledTextDiv = styled.div`
  display: flex;
  min-height: 200px;
  flex-direction: column;
  flex: 1;
  border-radius: 6px;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: -0.3808px;
  text-align: center;
  color: #999999;
  justify-content: center;
  background-color: #f6f8f9;
  border: dashed 1px;
  border-color: ${props => getColor(props)};
  cursor: pointer;
  outline: none;

  & .icon {
    height: 17px;
    margin-bottom: 15px;
  }
`;

const FileUploadArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilePreviewArea = styled.div`
  position: relative;
  height: 238px;
  border: solid 1px #dddddd;
  margin-bottom: 10px;
`;

const FileInfoArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 40px;
  font-size: 13px;
  color: #ffffff;
  background-color: #313131;
  justify-content: space-between;
  opacity: 0.7;
  padding: 11px 18px;
`;

const FileInfoText = styled.div`
  max-width: 290px;
  padding-top: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex: 1;
  height: 100%;
`;

const PlusButtonArea = styled.div`
  display: flex;
  position: relative;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledSquareButton = styled(SquareButton)`
  display: flex;
  width: ${({ dragModal }) => (dragModal ? '170px' : '60px')};
  height: 40px;
  border-radius: 6px;
  border: solid 1px #cfcfcf;
  color: #999999;
  background-color: ${({ isLastItem }) => (isLastItem ? '#f1f1f1' : '#ffffff')};
  padding: 4px 6px;
  float: right;
  align-items: center;
  justify-content: center;
`;

const FileInput = styled.input`
  position: absolute;
  width: 60px;
  height: 40px;
  right: 0;
  top: 0;
  opacity: 0;
  overflow: hidden;
  /* z-index: -1; */
  cursor: pointer;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
`;

const RightArea = styled.div`
  flex: 3;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;
  font-size: 14px;
`;

export default React.memo(MediaApp);
