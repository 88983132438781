import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import TextIcon from '../assets/images/appIcon/icon-text.svg';
import NaviIcon from '../assets/images/appIcon/icon-navi.svg';
import WholeFloorIcon from '../assets/images/appIcon/icon-wholeFloor.svg';
import OneFloorIcon from '../assets/images/appIcon/icon-oneFloor.svg';
import LogoIcon from '../assets/images/appIcon/icon-logo.svg';
import DateTimeIcon from '../assets/images/appIcon/icon-datetime.svg';
import WeatherIcon from '../assets/images/appIcon/icon-weather.svg';
import WorldWeatherIcon from '../assets/images/appIcon/icon-worldWeather.svg';
import RssIcon from '../assets/images/appIcon/icon-rss.svg';
import TickerIcon from '../assets/images/appIcon/icon-ticker.svg';
import WebViewIcon from '../assets/images/appIcon/icon-webView.svg';
import NoIcon from '../assets/images/appIcon/icon-no-contents.svg';
import MediaIcon from '../assets/images/appIcon/icon-media.svg';
import AudioIcon from '../assets/images/appIcon/icon-audio.svg';
import ChannelIcon from '../assets/images/appIcon/icon-channel.svg';
// import CalendarIcon from '../../../assets/images/appIcon/icon-outline-calendar.svg';
// import FrameNoIcon from '../../../assets/images/appIcon/icon-frameNo.svg';

const AppIcon = ({ name, type, onClick, selectType }) => {
  const { t } = useTranslation();

  return (
    <AppIconBtn onClick={() => onClick(type)} selectType={selectType}>
      <AppIconImg>{getLayerContentsIcon(type)}</AppIconImg>
      <AppIconText>
        {{
          media: t('manager.editor.layerArea.contents.media.name'),
          audio: t('manager.editor.layerArea.contents.audio.name'),
          channel: t('manager.editor.layerArea.contents.channel.name'),
          datetime: t('manager.editor.layerArea.contents.datetime.name'),
          logo: t('manager.editor.layerArea.contents.logo.name'),
          oneFloor: t('manager.editor.layerArea.contents.oneFloor.name'),
          wholeFloor: t('manager.editor.layerArea.contents.wholeFloor.name'),
          weather: t('manager.editor.layerArea.contents.weather.name'),
          worldWeather: t('manager.editor.layerArea.contents.worldWeather.name'),
          text: t('manager.editor.layerArea.contents.text.name'),
          rss: t('manager.editor.layerArea.contents.rss.name'),
          ticker: t('manager.editor.layerArea.contents.ticker.name'),
          navi: t('manager.editor.layerArea.contents.navi.name'),
          webView: t('manager.editor.layerArea.contents.webview.name'),
        }[type] || ''}
      </AppIconText>
    </AppIconBtn>
  );
};

const IconList = [
  MediaIcon,
  TextIcon,
  NaviIcon,
  WholeFloorIcon,
  OneFloorIcon,
  LogoIcon,
  DateTimeIcon,
  WeatherIcon,
  WorldWeatherIcon,
  RssIcon,
  TickerIcon,
  WebViewIcon,
  AudioIcon,
  ChannelIcon,
];

export const getLayerContentsIcon = type => {
  const img = type ? IconList.find(icon => icon.indexOf(type) > 0) || NoIcon : NoIcon;
  return <img src={img} alt="" />;
};

const AppIconBtn = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 108px;
  height: 70px;
  border-radius: 6px;
  border: solid 1px rgba(255, 255, 255, 0);
  background-color: #ffffff;
  box-sizing: border-box;
  &:hover {
    border: solid 1px #cfcfcf;
    background-color: #f6f6f6;
  }
  ${({ selectType }) =>
    selectType &&
    css`
      border: solid 1px #cfcfcf;
      background-color: #f6f6f6;
    `}
`;

const AppIconImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  & > img {
    object-fit: fill;
  }
`;

const AppIconText = styled.div`
  font-size: 14px;
  color: #666666;
`;

export default AppIcon;
