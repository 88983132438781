import React from 'react';
import styled from 'styled-components';

import AppIcon from '../../components/AppIcon';

const LayerContentsAppListWapper = ({ title, appList, handleClickApp }) => {
  return (
    <Component>
      <AppIconArea style={{ wordBreak: 'keep-all' }}>
        <Title>{title}</Title>
        {appList.map((app, index) => (
          <AppIcon
            key={'app-production-' + title + index}
            name={app.appNm}
            type={app.appType}
            onClick={type => handleClickApp('contentsType', app.appType)}
          />
        ))}
      </AppIconArea>
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  font-size: 0.87rem;
  margin-top: 0.5em;

  // flex-wrap: wrap;
  // min-height: 70px;
  flex-direction: column;
  & > span {
    width: 100%;
    color: #999999;
    text-align: center;
  }
`;
const Title = styled.legend`
  // display: flex;
  // flex: 1;
  padding: 0px 5px 5px;
  margin-left: 5px;
`;

const AppIconArea = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  min-height: 70px;
  border-top: 1px solid rgb(221 221 221);

  & > span {
    width: 100%;
    color: #999999;
  }
`;

export default React.memo(LayerContentsAppListWapper);
