import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Modal from '../../../newComponents/Modal';
import BackgroundLoading from '../../../newComponents/BackgroundLoading';

import { ReactComponent as WarnIcon } from '../../../assets/images/common/icon-alert-triangle-red.svg';

import { usePlaylistRemoveMutation } from '../../../rtk/playlistApi';

const PlaylistRemoveModal = ({ isOpen, closeModal, playlistIdList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [removePlaylist, { isLoading }] = usePlaylistRemoveMutation();

  const handleRemove = useCallback(() => {
    removePlaylist({ playlistIdList }).then(({ data }) => {
      if (data.resultFlag) {
        navigate('/playlist');
      } else {
        toast.error(t('manager.editor.modal.playlistRemove.fail'));
        closeModal();
      }
    });
  }, [t, navigate, closeModal, removePlaylist, playlistIdList]);

  return (
    <>
      <Modal
        title={
          <Title>
            <WarnIcon />
            {t('manager.editor.modal.playlistRemove.title')}
          </Title>
        }
        onConfirm={handleRemove}
        confirmColor="red"
        confirmText={t('manager.editor.modal.playlistRemove.ok')}
        cancelText={t('manager.editor.modal.playlistRemove.cancel')}
        onCancel={closeModal}
        onBackdropClick={closeModal}
        onClose={closeModal}
        visible={isOpen}
        size="large"
        isLine={true}
      >
        <Notice>
          {playlistIdList.length > 1 && (
            <Trans i18nKey="manager.editor.modal.playlistRemove.selectMent" values={{ count: playlistIdList.length }} />
          )}
          <Trans i18nKey="manager.editor.modal.playlistRemove.ment" />
        </Notice>
      </Modal>
      {isLoading && <BackgroundLoading />}
    </>
  );
};

const Title = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  color: #f05b5b;
  font-size: 14px;
  font-weight: 500;
`;

const Notice = styled.div`
  font-size: 14px;
  color: #333333;
`;

export default React.memo(PlaylistRemoveModal);
