export const CURRENT_APP_TITLE = '최근 사용한 Apps';
export const CURRENT_APP_CATEGORY = '생산성';
export const APP_HEADER_HEIHGT = 100;
export const MODAL_CREATE_TYPE = 'CREATE';
export const MODAL_REMOVE_TYPE = 'REMOVE';
export const MODAL_UPDATE_TYPE = 'UPDATE';
export const FOLDER_PATH = 'app-file';

export const ROOT_PUBLICFOLDER_INFO = {
  useYn: 'Y',
  folderPid: '_ROOT_',
  folderId: '_ROOT_',
  folderNm: '파일 보관함',
};

export const ROOT_FOLDER_INFO = {
  placeId: '',
  folderPid: '',
  folderId: '',
  folderNm: '파일 보관함',
};

export const INIT_SELECTED_LAYERCONTENTS_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: '',
  contentsData: {},
  contentsFileList: [],
};

export const INIT_WEATHER_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'weather',
  contentsData: { locationList: [{ targetGroupId: 'DEFAULT', location: '서울특별시' }], detail: 'degree' },
  contentsFileList: [],
};

export const INIT_WORLD_WEATHER_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'worldWeather',
  contentsData: { theme: 'TEMP', unit: 'CS', country: 'KR', city: '' },
  contentsFileList: [],
};

export const INIT_WEBVIEW_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'webView',
  contentsData: { url: '' },
  contentsFileList: [],
};

export const INIT_DATETIME_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'datetime',
  contentsData: { date: 'mdw', time: 'hmap', theme: 'horizon' },
  contentsFileList: [],
};

export const INIT_LOGO_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'logo',
  contentsData: { logoList: [{ targetGroupId: 'DEFAULT', fitOption: 'cover' }] },
  contentsFileList: [],
};

export const INIT_RSS_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'rss',
  contentsData: {
    rssId: 'mbn-all',
    rssTitle: 'mbn',
    rssSubTitle: 'mbn-all',
    color: { r: '102', g: '102', b: '102', a: '100' },
    fontSize: '10',
    boldYn: 'N',
    italicYn: 'N',
    underlineYn: 'N',
    lineThroughYn: 'N',
    textShadowYn: 'N',
    transitionTime: '3',
    animation: 'bottomToTop',
  },
  contentsFileList: [],
};

export const INIT_TICKER_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'ticker',
  contentsData: {
    captionText: '',
    color: { r: '102', g: '102', b: '102', a: '100' },
    fontSize: '10',
    boldYn: 'N',
    italicYn: 'N',
    underlineYn: 'N',
    lineThroughYn: 'N',
    textShadowYn: 'N',
    transitionTime: '3',
    animation: 'bottomToTop',
  },
  contentsFileList: [],
};

export const INIT_NAVI_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'navi',
  contentsData: { type: 'col', naviList: [{ id: 'home', text: '대표 프레임(HOME)' }], buttonRadius: 'left' },
  contentsFileList: [],
};

export const INIT_TEXT_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'text',
  contentsData: {
    text: '',
    color: { r: '102', g: '102', b: '102', a: '100' },
    lineHeight: '1.0',
    fontSize: '10',
    boldYn: 'N',
    italicYn: 'N',
    underlineYn: 'N',
    lineThroughYn: 'N',
    textShadowYn: 'N',
    scrollYn: 'N',
    textAlign: { vertical: 'flex-start', horizontal: 'left' },
  },
  contentsFileList: [],
};

export const INIT_ONEFLOOR_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'oneFloor',
  contentsData: {
    oneFloorTitle: '',
    companyList: [
      {
        companyName: '',
        companyYear: '',
        bossName: '',
        telephone: '',
        businessArea: '',
        frameLinkId: null,
      },
    ],
  },
  contentsFileList: [],
};

export const INIT_WHOLEFLOOR_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'wholeFloor',
  contentsData: {
    wholeFloorTitle: '',
    floorList: [
      {
        floorId: 1,
        floorTitle: '1F',
        floorSubTitle: '',
        floorYn: 'Y',
        companyList: [
          {
            companyId: 1,
            companyName: '',
            frameLinkId: null,
          },
        ],
      },
    ],
  },
  contentsFileList: [],
};

export const INIT_MEDIA_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'media',
  contentsData: {
    mediaList: [],
  },
  contentsFileList: [],
};

export const INIT_AUDIO_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'audio',
  contentsData: {
    mediaList: [],
  },
  contentsFileList: [],
};

export const INIT_CHANNEL_INFO = {
  playlistId: '',
  frameId: '',
  layerId: '',
  overlayId: '',
  contentsId: '',
  contentsType: 'channel',
  contentsData: {
    channelId: '',
  },
};
