import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import Modal from '../../../../../newComponents/Modal';

import { FiAlertTriangle as AlertIcon } from 'react-icons/fi';

const useRemoveConfirmModal = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [afterFn, setAfterFn] = useState();

  const removeCheck = useCallback(afterAction => {
    setShowModal(true);
    setAfterFn(afterFn => afterAction);
  }, []);

  const onClickOk = useCallback(() => {
    setShowModal(false);
    afterFn();
  }, [afterFn]);

  const onClickCancel = useCallback(() => {
    setShowModal(false);
    setAfterFn();
    return false;
  }, []);

  const LayerContentsWarnModal = () => {
    return (
      <Modal
        title={<Title>{t('manager.editor.layerArea.modal.forceRemove.title')}</Title>}
        isLine={true}
        confirmText={t('manager.editor.layerArea.modal.forceRemove.ok')}
        cancelText={t('manager.editor.layerArea.modal.forceRemove.cancel')}
        onConfirm={onClickOk}
        onCancel={onClickCancel}
        onClose={onClickCancel}
        onBackdropClick={onClickCancel}
        visible={showModal}
        size="large"
      >
        <ModalBody>
          <div style={{ color: '#f05b5b', marginBottom: '15px' }}>
            <AlertIcon size={15} color="#f05b5b" style={{ marginRight: '10px' }} />
            {t('manager.editor.layerArea.modal.forceRemove.alert')}
          </div>
          <Trans i18nKey={'manager.editor.layerArea.modal.forceRemove.ment'} />
        </ModalBody>
      </Modal>
    );
  };

  return {
    removeCheck,
    LayerContentsWarnModal,
    isRemoveChecking: showModal,
  };
};
const Title = styled.span`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  color: #f05b5b;
  font-size: 14px;
  font-weight: 500;
`;

const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 0 25.6px;
  font-size: 15px;
  line-height: 17px;
  & .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .mt {
    margin-top: 10px;
  }
`;

export default useRemoveConfirmModal;
