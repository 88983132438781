import { interactionBaseApi } from './interactionBaseApi';

export const appApi = interactionBaseApi.injectEndpoints({
  endpoints: build => ({
    appList: build.query({
      query: data => ({ url: '/app/app-list-get', data }),
      transformResponse: response => {
        const appList = response.appList;
        const URL = window.location.href;

        if (URL.indexOf('localhost') > 0 || URL.indexOf('eligaws') > 0) {
          return appList;
        } else {
          return appList.filter(app => app.devYn === 'N');
        }
      },
      providesTags: [{ type: 'APP', id: 'LIST' }],
    }),
    weatherList: build.query({
      query: data => ({ url: '/app/world-weather', data }),
      transformResponse: response => {
        const weatherList = response.weatherList;
        return weatherList;
      },
      providesTags: [{ type: 'APP', id: 'WEATHER' }],
    }),
    worldWeatherList: build.query({
      query: data => ({ url: '/app/world-weather-list', data }),
      transformResponse: response => {
        const weatherList = response.weatherList;
        return weatherList;
      },
      providesTags: [{ type: 'APP', id: 'WEATHER' }],
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useAppListQuery, useWorldWeatherListQuery } = appApi;
